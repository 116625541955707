import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const PortalUser = {};

PortalUser.Get = async (user_id) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getPortalUser, { user_id : user_id }));
		const item = ref.data.getPortalUser;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

PortalUser.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanPortalUser));
		let items = ref.data.scanPortalUser.items;
		while (ref.data.scanPortalUser.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanPortalUser, { nextToken: ref.data.scanPortalUser.nextToken }));
			items = items.concat(ref.data.scanPortalUser.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

PortalUser.executeLambda = async (params) =>  {
	
	try{
	
		const ref = await API.graphql(graphqlOperation(GraphQL.executeLambdaPortalUser,{ data: params }));
		const result = ref.data.executeLambdaPortalUser.result ? ref.data.executeLambdaPortalUser.result : false;
		const error  = ref.data.executeLambdaPortalUser.error;
		//console.log(error);
		
		if(result === false){
			throw new Error(error);
		}
		return ({
			result : result,
			error: error,
		});
	}
	catch(err) {
		//console.log(err);
		return({
			result : false,
			error: err.message ? err.message : JSON.stringify(err),
		});
	}
}

PortalUser.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updatePortalUser, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

PortalUser.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createPortalUser, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

PortalUser.Delete = async (user_id) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deletePortalUser, { user_id: user_id }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default PortalUser;


