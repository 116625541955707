
const AwsExports = {
    Auth: {
        identityPoolId: 'ap-northeast-1:81b66b80-8fca-49cb-8653-2a7ad283d0b8',
        userPoolId: 'ap-northeast-1_xXFQhRWer',
        userPoolWebClientId: '6qijs6ha789lk3mmedui2bnocg',
        region: 'ap-northeast-1',
    },
    API: {
        //'aws_appsync_graphqlEndpoint': 'https://zy3qbrm33jelnd3gq2cv3qseea.appsync-api.ap-northeast-1.amazonaws.com/graphql',
        'aws_appsync_graphqlEndpoint': 'https://api-console.onocomm.jp/graphql',
        'aws_appsync_region': 'ap-northeast-1',
        'aws_appsync_authenticationType': 'AWS_IAM',
    },
};

export default AwsExports;
