import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const SSL = {};

SSL.Get = async (ssl_name) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getSSL, { ssl_name : ssl_name }));
		const item = ref.data.getSSL;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

SSL.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanSSL));
		let items = ref.data.scanSSL.items;
		while (ref.data.scanSSL.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanSSL, { nextToken: ref.data.scanSSL.nextToken }));
			items = items.concat(ref.data.scanSSL.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

SSL.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateSSL, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

SSL.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createSSL, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

SSL.Delete = async (ssl_name) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteSSL, { ssl_name: ssl_name }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

SSL.DetailsGetAll = async (ssl_name) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanSSLDetails, { ssl_name: ssl_name }));
		let items = ref.data.scanSSLDetails.items;
		while (ref.data.scanSSLDetails.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanSSLDetails, { ssl_name: ssl_name, nextToken: ref.data.scanSSLDetails.nextToken }));
			items = items.concat(ref.data.scanSSLDetails.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

SSL.DetailsCreate = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createSSLDetails, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

SSL.DetailsUpdate = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateSSLDetails, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

SSL.DetailsDelete = async (ssl_name, date_add) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteSSLDetails, { ssl_name: ssl_name, date_add: date_add }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

export default SSL;


