import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const NS53 = {};

NS53.Get = async (ns53_no) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getNS53, { ns53_no : ns53_no }));
		const item = ref.data.getNS53;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

NS53.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanNS53));
		let items = ref.data.scanNS53.items;
		while (ref.data.scanNS53.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanNS53, { nextToken: ref.data.scanNS53.nextToken }));
			items = items.concat(ref.data.scanNS53.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

NS53.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateNS53, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


NS53.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createNS53, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

NS53.Delete = async (ns53_no) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteNS53, { ns53_no: ns53_no }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

NS53.DomainGetAll = async (ns53_no) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryNS53Domain, { ns53_no: ns53_no }));
		let items = ref.data.queryNS53Domain.items;
		while (ref.data.queryNS53Domain.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryNS53Domain, { ns53_no: ns53_no, nextToken: ref.data.queryNS53Domain.nextToken }));
			items = items.concat(ref.data.queryNS53Domain.items);
		}
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

NS53.DomainCreate = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createNS53Domain, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

NS53.DomainDelete = async (name) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteNS53Domain, { name: name }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

NS53.RecrodGet = async (name, type) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getNS53Record, { name : name, type : type }));
		const item = ref.data.getNS53Record;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

NS53.RecordGetAll = async (domain_name) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryNS53Record, { domain_name: domain_name }));
		let items = ref.data.queryNS53Record.items;
		while (ref.data.queryNS53Record.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryNS53Record, { domain_name: domain_name, nextToken: ref.data.queryNS53Record.nextToken }));
			items = items.concat(ref.data.queryNS53Record.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

NS53.RecordCreate = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createNS53Record, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

NS53.RecordUpdate = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateNS53Record, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

NS53.RecordDelete = async (name, type) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteNS53Record, { name: name, type: type }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

export default NS53;


