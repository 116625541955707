import Amplify from 'aws-amplify';
import AwsExports from './include/AwsExports';
import Customer from './API/Customer';
import Domain from './API/Domain';
import SSL from './API/SSL';
import Quotation from './API/Quotation';
import Invoice from './API/Invoice';
import Bill from './API/Bill';
import BillOnce from './API/BillOnce';
import BillMemo from './API/BillMemo';
import NS53 from './API/NS53';
import AWS from './API/AWS';
import AWSSupport from './API/AWSSupport';
import Server from './API/Server';
import MailLog from './API/MailLog';
import Mail from './API/Mail';
import Order from './API/Order';
import PortalUser from './API/PortalUser';
import Storage from './API/Storage';

Amplify.configure(AwsExports);


const API = {
	Customer   : Customer,
	Domain     : Domain,
	SSL        : SSL,
	Quotation  : Quotation,
	Invoice    : Invoice,
	Bill       : Bill,
	BillOnce   : BillOnce,
	BillMemo   : BillMemo,
	NS53       : NS53,
	AWS        : AWS,
	AWSSupport : AWSSupport,
	Server     : Server,
	MailLog	   : MailLog,
	Mail       : Mail,
	Order      : Order,
	PortalUser : PortalUser,
	Storage	   : Storage,
};


export default API;
