import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Storage = {};

Storage.Get = async (storage_id) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getStorage, { storage_id : storage_id }));
		const item = ref.data.getStorage;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Storage.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanStorage));
		let items = ref.data.scanStorage.items;
		while (ref.data.scanStorage.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanStorage, { nextToken: ref.data.scanStorage.nextToken }));
			items = items.concat(ref.data.scanStorage.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Storage.FilesGetAll = async (storage_id) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryStorageFiles, { storage_id: storage_id }));
		let items = ref.data.queryStorageFiles.items;
		while (ref.data.queryStorageFiles.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryStorageFiles, { storage_id: storage_id, nextToken: ref.data.queryStorageFiles.nextToken }));
			items = items.concat(ref.data.queryStorageFiles.items);
		}
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Storage.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateStorage, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		console.log(err);
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


Storage.Create = async (params) =>  {

	console.log(params);
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createStorage, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		console.log(err);
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Storage.Delete = async (storage_id) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteStorage, { storage_id: storage_id }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		console.log(err);
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Storage.executeLambda = async (params) =>  {
	
	try{
	
		const ref = await API.graphql(graphqlOperation(GraphQL.executeLambdaStorage,{ data: params }));
		const result = ref.data.executeLambdaStorage.result ? ref.data.executeLambdaStorage.result : false;
		const error  = ref.data.executeLambdaStorage.error;
		//console.log(error);
		
		if(result === false){
			throw new Error(error);
		}
		return ({
			result : result,
			error: error,
		});
	}
	catch(err) {
		//console.log(err);
		return({
			result : false,
			error: err.message ? err.message : JSON.stringify(err),
		});
	}
}

export default Storage;


