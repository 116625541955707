import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate, dateShapingMonth, isNumber } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect, FormButton } from '../../Objects/Form';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';
import { v4 as uuidv4 } from 'uuid';


const Default = () => {

	const history = useHistory();
	const { server_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [customer, setCustomer] = useState([]);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};

			if(server_no){
				//データ取得
				newData = (await API.Server.Get(server_no)).item;
			}
			else{
				newData.server_no=uuidv4();
				newData.customer_no='';
				newData.customer_name='';
				newData.contract_date=Math.floor((new Date()).getTime() / 1000);
				newData.instance_price_notax=0;
				newData.disk_num=20;
				newData.disk_unit=20;
				newData.disk_price_notax=(newData.disk_num*newData.disk_unit);
				newData.bakcup_num=8;
				newData.bakcup_unit=20;
				newData.bakcup_price_notax=(newData.bakcup_num*newData.bakcup_unit);
				newData.transfer_num=500;
				newData.transfer_unit=20;
				newData.transfer_price_notax=(newData.transfer_num*newData.transfer_unit);
				newData.support_type='ベーシック';
				newData.support_price_notax=10000;
				newData.ssh_key='onocomm-server.ppk';
				newData.ssh_user='ec2-user';
				newData.os='Amazon Linux2';
				newData.bill_start= covertTime('2007-01');
				newData.bill_cycle='掛売';
				newData.status='有効';
				newData.contract_months=1;
				newData.op=[{
					uuid:uuidv4(),
					sort:1,
					details:'',
					piece:0,
					piece_type:'件',
					tax_per: 0.1,
					price_notax:0,
					sub_price_notax:0,
					tax:0,
				}];
			}

			//ソート
			newData.op.sort(function(a,b){
				if(a.sort < b.sort) return -1;
				if(a.sort > b.sort) return 1;
				return 0;
			});
			
			setData(newData);
			setCustomer((await API.Customer.GetAll()).items);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			if(server_no){
				//更新
				const ref = await API.Server.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				//新規
				const ref = await API.Server.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(server_no){
				//削除
				const ref = await API.Server.Delete(server_no);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};
	
	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setShowCustomer([false]);
		setRefresh([true]);
	};
	
	const handleAddDetailsClick = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.op.push({
			uuid:uuidv4(),
			sort:newData.op.length+1,
			details:'',
			piece:0,
			piece_type:'件',
			price_notax:0,
			sub_price_notax:0,
		});
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleDropDetailsClick = (event) => {
		const name = event.target.name;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.op){
			if(object.uuid !== name){
				newDetails.push(object);
			}
		}
		newData.op = newDetails;
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleSortClick = (event) => {
		const uuid = event.target.name;
		const value = event.target.value;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.op){
			if(object.uuid === uuid){
				if(value === '↑'){
					object.sort = object.sort - 2;
				}
				else{
					object.sort = object.sort + 2;
				}
			}
			newDetails.push(object);
		}
		newData.op = newDetails;
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.op){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.op = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		const updateData = dataUpdate(newData);
		
		//更新
		setData(updateData);
		setRefresh([true]);
	};
	
	const dataUpdate = (data) => {
		const newCalc = dataCalc(data);
		const newSort = dataSort(newCalc);
		return(newSort);
	};
	
	const dataCalc = (data) => {
		//金額の計算
		let op_price_notax = 0;
		const newDetails = [];
		for(const object of data.op){
			
			//計算（計算対象が数値でなければ0とする）
			const piece = isNumber(object.piece) ? Number(object.piece) : 0;
			const price_notax = isNumber(object.price_notax) ? Number(object.price_notax) : 0;
			
			//計算（計算対象が0なら結果も0とする）
			object.sub_price_notax = (piece || price_notax) ? Number(piece * price_notax) : 0;
			
			//合計値
			op_price_notax = Number(op_price_notax + object.sub_price_notax);
			
			newDetails.push(object);
		}
		data.op = newDetails;
		data.op_price_notax = op_price_notax;
		return(data);
	};
	
	const dataSort = (data) => {
		//ソート
		data.op.sort(function(a,b){
			if(a.sort < b.sort) return -1;
			if(a.sort > b.sort) return 1;
			return 0;
		});
		
		//ソート番号振り直し
		let i = 1;
		for(const object of data.op){
			object.sort=i++;
		}
		return(data);
	};
	
	const handleTotalPriceUpdate = () => {
		const newData = dataCalc(data);
		newData.disk_price_notax = newData.payment_type === 'サポートのみ' ? 0 : newData.disk_price_notax; 
		newData.bakcup_price_notax = newData.payment_type === 'サポートのみ' ? 0 : newData.bakcup_price_notax; 
		newData.bakcup_num = newData.payment_type === 'サポートのみ' ? 0 : newData.bakcup_num; 
		newData.transfer_price_notax = newData.payment_type === 'サポートのみ' ? 0 : newData.transfer_price_notax; 
		newData.transfer_num = newData.payment_type === 'サポートのみ' ? 0 : newData.transfer_num; 
		newData.total_price_notax = Number(isNumber(newData.disk_price_notax) ? newData.disk_price_notax : 0) + 
		                            Number(isNumber(newData.bakcup_price_notax) ? newData.bakcup_price_notax : 0) + 
		                            Number(isNumber(newData.transfer_price_notax) ? newData.transfer_price_notax : 0) + 
		                            Number(isNumber(newData.support_price_notax) ? newData.support_price_notax : 0) + 
		                            Number(isNumber(newData.op_price_notax) ? newData.op_price_notax : 0);
		setData(newData);
		setRefresh([true]);
	};
	
	const handleSubPriceUpdate1 = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.disk_price_notax = Number(isNumber(newData.disk_num) ? newData.disk_num : 0) * Number(isNumber(newData.disk_unit) ? newData.disk_unit : 0);
		newData.disk_price_notax = newData.payment_type === 'サポートのみ' ? 0 : newData.disk_price_notax; 
		setData(newData);
		setRefresh([true]);
	};

	const handleSubPriceUpdate2 = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.bakcup_price_notax = Number(isNumber(newData.bakcup_num) ? newData.bakcup_num : 0) * Number(isNumber(newData.bakcup_unit) ? newData.bakcup_unit : 0);
		newData.bakcup_price_notax = newData.payment_type === 'サポートのみ' ? 0 : newData.bakcup_price_notax; 
		newData.bakcup_num = newData.payment_type === 'サポートのみ' ? 0 : newData.bakcup_num; 
		setData(newData);
		setRefresh([true]);
	};

	const handleSubPriceUpdate3 = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.transfer_price_notax = Number(isNumber(newData.transfer_num) ? newData.transfer_num : 0) * Number(isNumber(newData.transfer_unit) ? newData.transfer_unit : 0);
		newData.transfer_price_notax = newData.payment_type === 'サポートのみ' ? 0 : newData.transfer_price_notax; 
		newData.transfer_num = newData.payment_type === 'サポートのみ' ? 0 : newData.transfer_num; 
		setData(newData);
		setRefresh([true]);
	};

	const handleFQDNUpdate = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.fqdn = newData.server_name ? (newData.server_name + '.onocomm-server.onocomm.jp') : '';
		setData(newData);
		setRefresh([true]);
	};

	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/server/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/server/' + data.server_no;
		}
		else{
			setReload(reload+1);
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'契約ID'} name={'server_no'} value={data.server_no} readOnly={true} onChange={handleOnChange} />
					<hr/>
					<FormButton label={'対象顧客'} name={'customer_no'} value={data.customer_no ? (data.customer_no + '　' + data.customer_name) : '未選択'} onClick={handleCustomerShow} required={true} />
					<FormText label={'契約日'} name={'contract_date'} value={dateShapingDate(data.contract_date)} required={true} type={'date'} onChange={handleOnChange} />
					<FormText label={'ホスト名'} name={'server_name'} value={data.server_name} required={true} onChange={handleOnChange} />
					<FormSelect label={'タイプ'} name={'server_type'} value={data.server_type} data={ [ 'EC2', 'RDS', 'ロードバランサー', 'RES' ] } required={true} onChange={handleOnChange} />
					<FormSelect label={'契約区分'} name={'payment_type'} value={data.payment_type} data={ [ '月額課金', 'サポートのみ' ] } required={true} onChange={handleOnChange} />
					<FormText label={'AWSアカウント番号'} name={'account_no'} value={data.account_no} onChange={handleOnChange} />
					<hr/>
					<FormText label={'インスタンスID'} name={'instance_id'} value={data.instance_id} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false} />
					<FormText label={'インスタンスタイプ'} name={'instance_type'} value={data.instance_type} onChange={handleOnChange} />
					<FormText label={'インスタンス費用'} name={'instance_price_notax'} value={data.instance_price_notax} type={'number'} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false || data.payment_type === 'サポートのみ' ? true : false} />
					<hr/>
					<FormText label={'ディスク容量'} name={'disk_num'} value={data.disk_num} type={'number'} onChange={handleOnChange} />
					<FormText label={'ディスク単価'} name={'disk_unit'} value={data.disk_unit} type={'number'} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false || data.payment_type === 'サポートのみ' ? true : false} />
					<FormText label={'ディスク費用'} name={'disk_price_notax'} value={data.disk_price_notax} type={'number'} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false || data.payment_type === 'サポートのみ' ? true : false} />
					<FormButton label={''} name={''} value={'小計計算'} onClick={handleSubPriceUpdate1} />
					<hr/>
					<FormText label={'バックアップ容量'} name={'bakcup_num'} value={data.bakcup_num} type={'number'} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false || data.payment_type === 'サポートのみ' ? true : false} />
					<FormText label={'バックアップ単価'} name={'bakcup_unit'} value={data.bakcup_unit} type={'number'} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false || data.payment_type === 'サポートのみ' ? true : false} />
					<FormText label={'バックアップ費用'} name={'bakcup_price_notax'} value={data.bakcup_price_notax} type={'number'} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false || data.payment_type === 'サポートのみ' ? true : false} />
					<FormButton label={''} name={''} value={'小計計算'} onClick={handleSubPriceUpdate2} />
					<hr/>
					<FormText label={'トラフィック容量'} name={'transfer_num'} value={data.transfer_num} type={'number'} onChange={handleOnChange} readOnly={data.payment_type === 'サポートのみ' ? true : false} />
					<FormText label={'トラフィック単価'} name={'transfer_unit'} value={data.transfer_unit} type={'number'} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false || data.payment_type === 'サポートのみ' ? true : false} />
					<FormText label={'トラフィック費用'} name={'transfer_price_notax'} value={data.transfer_price_notax} type={'number'} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false || data.payment_type === 'サポートのみ' ? true : false} />
					<FormButton label={''} name={''} value={'小計計算'} onClick={handleSubPriceUpdate3} />
					<hr/>
					<FormText label={'サポートタイプ'} name={'support_type'} value={data.support_type} onChange={handleOnChange} />
					<FormText label={'サポート費用'} name={'support_price_notax'} type={'number'} value={data.support_price_notax} onChange={handleOnChange} />
					<hr/>
					<FormText label={'月額費用'} name={'total_price_notax'} value={data.total_price_notax} type={'number'} required={true} onChange={handleOnChange} />
					<FormButton label={''} name={''} value={'価格取得'} onClick={handleTotalPriceUpdate} />
					<hr/>
					<FormText label={'FQDN'} name={'fqdn'} value={data.fqdn} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false} />
					<FormButton label={''} name={''} value={'FQDN設定'} onClick={handleFQDNUpdate} />
					<FormSelect label={'OS'} name={'os'} value={data.os} data={ [ 'Windows', 'Linux', 'Amazon Linux', 'Amazon Linux2', 'CentOS' ] } onChange={handleOnChange} />
					<FormText label={'security_group'} name={'security_group'} value={data.security_group} onChange={handleOnChange} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							管理者アクセス
						</Form.Label>
					</Row>
					<FormText label={'ip'} name={'ip'} value={data.ip} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false} />
					<FormText label={'ssh_key'} name={'ssh_key'} value={data.ssh_key} onChange={handleOnChange} />
					<FormText label={'ssh_user'} name={'ssh_user'} value={data.ssh_user} onChange={handleOnChange} />
					<FormText label={'ssh_passwd'} name={'ssh_passwd'} value={data.ssh_passwd} onChange={handleOnChange} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							旧管理画面
						</Form.Label>
					</Row>
					<FormText label={'admin_url'} name={'admin_url'} value={data.admin_url} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false} />
					<FormText label={'admin_user'} name={'admin_user'} value={data.admin_user} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false} />
					<FormText label={'admin_passwd'} name={'admin_passwd'} value={data.admin_passwd} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? true : false} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							アクセス情報
						</Form.Label>
					</Row>
					<FormText label={'scp_user'} name={'scp_user'} value={data.scp_user} onChange={handleOnChange} />
					<FormText label={'web_url'} name={'web_url'} value={data.web_url} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? false : true} />
					<FormText label={'basic_user'} name={'basic_user'} value={data.basic_user} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? false : true} />
					<FormText label={'basic_passwd'} name={'basic_passwd'} value={data.basic_passwd} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? false : true} />
					<FormText label={'ftp_host'} name={'ftp_host'} value={data.ftp_host} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? false : true} />
					<FormText label={'ftp_user'} name={'ftp_user'} value={data.ftp_user} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? false : true} />
					<FormText label={'ftp_passwd'} name={'ftp_passwd'} value={data.ftp_passwd} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? false : true} />
					<FormText label={'phpmyadmin_url'} name={'phpmyadmin_url'} value={data.phpmyadmin_url} onChange={handleOnChange} readOnly={data.server_type === 'EC2' ? true : false} />
					<FormText label={'phpmyadmin_user'} name={'phpmyadmin_user'} value={data.phpmyadmin_user} onChange={handleOnChange} readOnly={data.server_type === 'EC2' ? true : false} />
					<FormText label={'phpmyadmin_passwd'} name={'phpmyadmin_passwd'} value={data.phpmyadmin_passwd} onChange={handleOnChange} readOnly={data.server_type === 'EC2' ? true : false} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							スケール関連
						</Form.Label>
					</Row>
					<FormText label={'instance_min_num'} name={'instance_min_num'} value={data.instance_min_num} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? false : true} />
					<FormText label={'instance_max_num'} name={'instance_max_num'} value={data.instance_max_num} onChange={handleOnChange} readOnly={data.server_type === 'ロードバランサー' ? false : true} />
					<Row>
						<Form.Label column sm={3}>
							web_scale_flag
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId={'web_scale_flag'}>
							        <input type="checkbox" name={'web_scale_flag'} onChange={handleOnChange} disabled={data.server_type === 'ロードバランサー' ? false : true} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Form.Label column sm={3}>
							db_scale_flag
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId={'db_scale_flag'}>
							        <input type="checkbox" name={'db_scale_flag'} onChange={handleOnChange} disabled={data.server_type === 'ロードバランサー' ? false : true} />
							</Form.Group>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							データベース関連
						</Form.Label>
					</Row>
					<FormText label={'db_num'} name={'db_num'} value={data.db_num} onChange={handleOnChange} readOnly={data.server_type === 'EC2' ? true : false} />
					<FormText label={'db_host'} name={'db_host'} value={data.db_host} onChange={handleOnChange} readOnly={data.server_type === 'EC2' ? true : false} />
					<FormText label={'db_port'} name={'db_port'} value={data.db_port} onChange={handleOnChange} readOnly={data.server_type === 'EC2' ? true : false} />
					<FormText label={'db_user'} name={'db_user'} value={data.db_user} onChange={handleOnChange} readOnly={data.server_type === 'EC2' ? true : false} />
					<FormText label={'db_passwd'} name={'db_passwd'} value={data.db_passwd} onChange={handleOnChange} readOnly={data.server_type === 'EC2' ? true : false} />
					<FormSelect label={'db_engine'} name={'db_engine'} value={data.db_engine} data={ [ 'MySQL', 'PostgreSQL' ] } onChange={handleOnChange} readOnly={data.server_type === 'EC2' ? true : false} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							基本情報
						</Form.Label>
					</Row>
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							オプション
						</Form.Label>
					</Row>
					<Row>
						<Col sm={12}>
					                <table cellSpacing="0" border="1" width="100%">
					                    <col width="10%" />
					                    <col width="60%" />
					                    <col width="10%" />
					                    <col width="10%" />
					                    <col width="10%" />
					                    <thead>
					                        <tr>
					                            <td>順番</td>
					                            <td>項目</td>
					                            <td>数量</td>
					                            <td>単価</td>
					                            <td>金額</td>
					                        </tr>
					                    </thead>
					                    <tbody>
					                    	{data.op.map(item => {
					                    		return(
						                            <tr key={item.uuid}>
						                                <td className="text-center">
						                                	<input type="button"name={item.uuid} defaultValue={'↑'} style={{width:'25px'}} onClick={handleSortClick} />　
						                                	<input type="button"name={item.uuid} defaultValue={'↓'} style={{width:'25px'}} onClick={handleSortClick} />
						                                </td>
						                                <td className="text-left">
						                                	<input type="text" name={item.uuid + ':details'} value={item.details} style={{width:'600px'}} onChange={handleOnChange} required={true} />　
						                                	<input type="button" name={item.uuid} defaultValue={'X'} style={{width:'25px'}} onClick={handleDropDetailsClick} />
						                                </td>
						                                <td className="text-right">
						                                	<input type="number" name={item.uuid + ':piece'} value={item.piece} style={{width:'50px'}} onChange={handleOnChange} />　
						                                	<input type="text" name={item.uuid + ':piece_type'} value={item.piece_type} style={{width:'30px'}} onChange={handleOnChange} />
						                                </td>
						                                <td className="text-right"><input type="number" name={item.uuid + ':price_notax'} value={item.price_notax} style={{width:'100px'}} onChange={handleOnChange} /></td>
						                                <td className="text-right"><input type="number" name={item.uuid + ':sub_price_notax'} value={item.sub_price_notax} style={{width:'100px'}} readOnly={true} /></td>
						                            </tr>
					                    		);
					                    	})}
				                    	    <tr>
				                                <td className="text-left"></td>
				                                <td className="text-center">　<input type="button" defaultValue={'行追加'} style={{width:'310px'}} onClick={handleAddDetailsClick} /></td>
				                                <td colSpan="3"></td>
				                            </tr>
					                    </tbody>
					                </table>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							請求月
						</Form.Label>
						<Col sm={9}>
							<input type="checkbox" name="bill_month01" checked={data.bill_month01 ? true : false} onChange={handleOnChange} /> 1月　
							<input type="checkbox" name="bill_month02" checked={data.bill_month02 ? true : false} onChange={handleOnChange} /> 2月　
							<input type="checkbox" name="bill_month03" checked={data.bill_month03 ? true : false} onChange={handleOnChange} /> 3月　
							<input type="checkbox" name="bill_month04" checked={data.bill_month04 ? true : false} onChange={handleOnChange} /> 4月　
							<input type="checkbox" name="bill_month05" checked={data.bill_month05 ? true : false} onChange={handleOnChange} /> 5月　
							<input type="checkbox" name="bill_month06" checked={data.bill_month06 ? true : false} onChange={handleOnChange} /> 6月　
							<input type="checkbox" name="bill_month07" checked={data.bill_month07 ? true : false} onChange={handleOnChange} /> 7月　
							<input type="checkbox" name="bill_month08" checked={data.bill_month08 ? true : false} onChange={handleOnChange} /> 8月　
							<input type="checkbox" name="bill_month09" checked={data.bill_month09 ? true : false} onChange={handleOnChange} /> 9月　
							<input type="checkbox" name="bill_month10" checked={data.bill_month10 ? true : false} onChange={handleOnChange} /> 10月　
							<input type="checkbox" name="bill_month11" checked={data.bill_month11 ? true : false} onChange={handleOnChange} /> 11月　
							<input type="checkbox" name="bill_month12" checked={data.bill_month12 ? true : false} onChange={handleOnChange} /> 12月
						</Col>
					</Row>
					<FormSelect label={'請求サイクル'} name={'bill_cycle'} value={data.bill_cycle} data={ [ '掛売', '当月', '先貰' ] } required={true} onChange={handleOnChange} />
					<FormText label={'契約単位（月）'} name={'contract_months'} defaultValue={1} value={data.contract_months} type={'number'} onChange={handleOnChange} required={true} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							摘要
						</Form.Label>
						<Col sm={1}>
							<input type="checkbox" name="contract_memo_flag" checked={data.contract_memo_flag ? true : false} onChange={handleOnChange} />　
						</Col>
						<Col sm={8}>
							<input type="text" name="contract_memo" value={data.contract_memo} className="form-control" onChange={handleOnChange} />
						</Col>
					</Row>
					<hr/>
					<FormText label={'メモ'} name={'memo_text'} value={data.memo_text} onChange={handleOnChange} />
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} value={dateShapingMonth(data.bill_start)} type={'month'} required={true} onChange={handleOnChange} />
					<FormText label={'課金停止月'} name={'bill_end'} value={dateShapingMonth(data.bill_end)} type={'month'} onChange={handleOnChange} />
					<hr/>
					<FormSelect label={'ステータス'} name={'status'} value={data.status} data={ [ '有効', '無効' ] } required={true} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>

				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block disabled={lock || server_no ? false : true} >Delete</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
