import { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import AwsExports from './@onocomm-system/include/AwsExports';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './Objects/Header';
import Footer from './Objects/Footer';
import StyleSheet from './Objects/StyleSheet';
import NotFound from './Objects/NotFound';
import LoginSignOut from './Pages/Login/SignOut';
import LoginSignIn from './Pages/Login/SignIn';
import LoginSignInChangePassword from './Pages/Login/SignInChangePassword';
import LoginForgotPassword from './Pages/Login/ForgotPassword';
import LoginForgotPasswordVerify from './Pages/Login/ForgotPasswordVerify';
import AccountMain from './Pages/Account/Main';
import AccountEmail from './Pages/Account/Email';
import AccountEmailVerify from './Pages/Account/EmailVerify';
import AccountPassword from './Pages/Account/Password';
import AccountUser from './Pages/Account/User';
import CustomerList from './Pages/Customer/List';
import CustomerMain from './Pages/Customer/Main';
import PortalUserList from './Pages/PortalUser/List';
import PortalUserMain from './Pages/PortalUser/Main';
import DomainList from './Pages/Domain/List';
import DomainMain from './Pages/Domain/Main';
import SSLList from './Pages/SSL/List';
import SSLMain from './Pages/SSL/Main';
import SSLKeys from './Pages/SSL/Keys';
import BillMemoList from './Pages/BillMemo/List';
import BillMemoMain from './Pages/BillMemo/Main';
import BillOnceList from './Pages/BillOnce/List';
import BillOnceMain from './Pages/BillOnce/Main';
import BillList from './Pages/Bill/List';
import BillMain from './Pages/Bill/Main';
import NS53DomainList from './Pages/NS53/Domain/List';
import NS53DomainMain from './Pages/NS53/Domain/Main';
import NS53RecordList from './Pages/NS53/Record/List';
import NS53RecordMain from './Pages/NS53/Record/Main';
import NS53List from './Pages/NS53/List';
import NS53Main from './Pages/NS53/Main';
import AWSList from './Pages/AWS/List';
import AWSMain from './Pages/AWS/Main';
import ServerAccess from './Pages/Server/Access';
import ServerList from './Pages/Server/List';
import ServerMain from './Pages/Server/Main';
import MailLogMain from './Pages/MailLog/Main';
import MailList from './Pages/Mail/List';
import MailMain from './Pages/Mail/Main';
import QuotationList from './Pages/Quotation/List';
import QuotationMain from './Pages/Quotation/Main';
import OrderList from './Pages/Order/List';
import OrderMain from './Pages/Order/Main';
import InvoiceList from './Pages/Invoice/List';
import InvoiceMain from './Pages/Invoice/Main';
import SupportNew from './Pages/Support/New';
import SupportList from './Pages/Support/List';
import SupportMain from './Pages/Support/Main';
import PrintSwitch from './Pages/Print/Switch';
import PrintInvoice from './Pages/Print/Invoice';
import PrintQuotation from './Pages/Print/Quotation';
import PrintOrder from './Pages/Print/Order';
import PrintNotFound from './Pages/Print/NotFound';
import ConnectServer from './Pages/Connect/Server';
import ConnectAWS from './Pages/Connect/AWS';
import StorageList from './Pages/Storage/List';
import StorageMain from './Pages/Storage/Main';
import StorageFilesList from './Pages/Storage/Files/List';
import Main from './Pages/Main';

Amplify.configure(AwsExports);

const App = () => {

	const [load, setLoad] = useState(true);
	const [auth, setAuth] = useState(undefined);
	const [print, setPrint] = useState(false);

	useEffect(() => {
		
		//���O�C���`�F�b�N
		const checkAuth = async () => {

			//�F�؃X�e�[�^�X�擾
			const getAuthStatus = async () => {

				try{
					//���O�C�����[�U���̎擾
					const username = (await Auth.currentAuthenticatedUser()).username;

					if(username){
						return(true);
					}
					else{
						return(false);
					}
				}
				catch(err) {
					return(false);
				}
			};
			
			//�F�؃X�e�[�^�X�̎擾
			const status = await getAuthStatus();
			
			//���ʋL�^
			if(status){
				setAuth(true);
			}
			else{
				setAuth(false);
			}
		}
		
		//���O�C���`�F�b�N
		checkAuth();
		
		setPrint(sessionStorage.getItem('print') === 'true' ? true : false);
		
		//�ǂݍ��݊���
		setLoad(false);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[true]);
	
	
	//�y�[�W����
	if(load){
		//�ǂݍ��ݒ�
		return(<div />);
	}
	else if(auth === true){
		if(print){
			return (
				<BrowserRouter>
					<Switch>
						<Route path='/print/quotation/:quotation_no' component={PrintQuotation} />
						<Route path='/print/order/:quotation_no' component={PrintOrder} />
						<Route path='/print/invoice/:invoice_no' component={PrintInvoice} />
						<Route component={PrintNotFound} />
					</Switch>
				</BrowserRouter>
			);
		}
		else{
			return (
				<BrowserRouter>
					<div>
						<Header/>
						<StyleSheet/>
						<Switch>
							<Route exact path='/' component={Main} />
							<Route path='/account/user' component={AccountUser} />
							<Route path='/account/email-verify' component={AccountEmailVerify}/>
							<Route path='/account/email' component={AccountEmail}/>
							<Route path='/account/password' component={AccountPassword}/>
							<Route path='/account' component={AccountMain}/>
							<Route path='/customer/new' component={CustomerMain} />
							<Route path='/customer/:customer_no' component={CustomerMain} />
							<Route path='/customer' component={CustomerList} />
							<Route path='/portal-user/new' component={PortalUserMain} />
							<Route path='/portal-user/:user_id' component={PortalUserMain} />
							<Route path='/portal-user' component={PortalUserList} />
							<Route path='/domain/new' component={DomainMain} />
							<Route path='/domain/:domain_name' component={DomainMain} />
							<Route path='/domain' component={DomainList} />
							<Route path='/ssl/new' component={SSLMain} />
							<Route path='/ssl/:ssl_name/keys' component={SSLKeys} />
							<Route path='/ssl/:ssl_name' component={SSLMain} />
							<Route path='/ssl' component={SSLList} />
							<Route path='/bill-memo/new' component={BillMemoMain} />
							<Route path='/bill-memo/:bill_memo_no' component={BillMemoMain} />
							<Route path='/bill-memo' component={BillMemoList} />
							<Route path='/bill-once/new' component={BillOnceMain} />
							<Route path='/bill-once/:bill_once_no' component={BillOnceMain} />
							<Route path='/bill-once' component={BillOnceList} />
							<Route path='/bill/new' component={BillMain} />
							<Route path='/bill/:bill_no' component={BillMain} />
							<Route path='/bill' component={BillList} />
							<Route path='/ns53/new' component={NS53Main} />
							<Route path='/ns53/:ns53_no/domains/new' component={NS53DomainMain} />
							<Route path='/ns53/:ns53_no/domains' component={NS53DomainList} />
							<Route path='/ns53/:ns53_no/:domain_name/new' component={NS53RecordMain} />
							<Route path='/ns53/:ns53_no/:domain_name/:name/:type' component={NS53RecordMain} />
							<Route path='/ns53/:ns53_no/:domain_name' component={NS53RecordList} />
							<Route path='/ns53/:ns53_no' component={NS53Main} />
							<Route path='/ns53' component={NS53List} />
							<Route path='/aws/new' component={AWSMain} />
							<Route path='/aws/:account_no' component={AWSMain} />
							<Route path='/aws' component={AWSList} />
							<Route path='/server/new' component={ServerMain} />
							<Route path='/server/:server_no/access' component={ServerAccess} />
							<Route path='/server/:server_no' component={ServerMain} />
							<Route path='/server' component={ServerList} />
							<Route path='/maillog' component={MailLogMain} />
							<Route path='/mail/new' component={MailMain} />
							<Route path='/mail/:domain_name' component={MailMain} />
							<Route path='/mail' component={MailList} />
							<Route path='/quotation/new' component={QuotationMain} />
							<Route path='/quotation/:quotation_no' component={QuotationMain} />
							<Route path='/quotation' component={QuotationList} />
							<Route path='/order/:order_no' component={OrderMain} />
							<Route path='/order' component={OrderList} />
							<Route path='/invoice/new' component={InvoiceMain} />
							<Route path='/invoice/:invoice_no' component={InvoiceMain} />
							<Route path='/invoice' component={InvoiceList} />
							<Route path='/support/new' component={SupportNew} />
							<Route path='/support/:case_id' component={SupportMain} />
							<Route path='/support' component={SupportList} />
							<Route path='/connect/server' component={ConnectServer} />
							<Route path='/connect/aws' component={ConnectAWS} />
							<Route path='/storage/new' component={StorageMain} />
							<Route path='/storage/:storage_id/files' component={StorageFilesList} />
							<Route path='/storage/:storage_id' component={StorageMain} />
							<Route path='/storage' component={StorageList} />
							<Route path='/print' component={PrintSwitch} />
							<Route path='/sign-out' component={LoginSignOut}/>
							<Route component={NotFound} />
						</Switch>
						<Footer/>
					</div>
				</BrowserRouter>
			);
		}
	}
	else{
		return (
			<BrowserRouter>
				<div>
					<StyleSheet/>
					<Switch>
						<Route path='/forgot-password-verify/:email' component={LoginForgotPasswordVerify} />
						<Route path='/forgot-password-verify' component={LoginForgotPasswordVerify} />
						<Route path='/forgot-password' component={LoginForgotPassword} />
						<Route path='/signin-change-password/:email' component={LoginSignInChangePassword} />
						<Route component={LoginSignIn} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
};

export default App;

