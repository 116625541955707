import { useState, useEffect  } from 'react';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { withRouter } from 'react-router-dom';


const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);

	useEffect(() => {
	
		const getData = async () => {
			
			const items = [
				{
					account_name: 'server',
					account_id: '482202236907',
					color: '99BCE3',
					biko: 'オノコムサーバー',
					type: '社内',
				},
				{
					account_name: 'serverless',
					account_id: '895738667535',
					color: '99BCE3',
					biko: 'オノコムサーバーレス',
					type: '社内',
				},
				{
					account_name: 'quickdeploy',
					account_id: '919189485352',
					color: '99BCE3',
					biko: 'クイックデプロイ',
					type: '社内',
				},
				{
					account_name: 'develop',
					account_id: '814017023783',
					color: '99BCE3',
					biko: 'オノコム開発環境',
					type: '社内',
				},
				{
					account_name: 'khnum',
					account_id: '669836052908',
					color: 'FAD791',
					biko: '株式会社クヌム',
					type: '社外（決済代行）',
				},
				{
					account_name: 'khnum houai',
					account_id: '749694266450',
					color: 'FAD791',
					biko: '株式会社クヌム（朋愛会）',
					type: '社外（決済代行）',
				},
				{
					account_name: 'khnum kenshinsupport',
					account_id: '508052356582',
					color: 'FAD791',
					biko: '株式会社クヌム（健診サポート）',
					type: '社外（決済代行）',
				},
				{
					account_name: 'humanic',
					account_id: '729486413004',
					color: 'FAD791',
					biko: '株式会社ヒューマニック',
					type: '社外（決済代行）',
				},
				{
					account_name: 'muginoho',
					account_id: '045294648983',
					color: 'FAD791',
					biko: '株式会社DAY TO LIFE',
					type: '社外（決済代行）',
				},
				{
					account_name: 'mindwave',
					account_id: '255913930404',
					color: 'FAD791',
					biko: '株式会社マインドウェイブ',
					type: '社外（決済代行）',
				},
				{
					account_name: 'kuusoo',
					account_id: '984497567154',
					color: 'FAD791',
					biko: 'KUUSOO株式会社（Top J Recrods）',
					type: '社内（ロードバランサーパッケージ）',
 				},
				{
					account_name: 'kuusoo tjr-pro',
					account_id: '706716082028',
					color: 'FAD791',
					biko: 'KUUSOO株式会社（TJR1 本番環境）',
					type: '社外（決済代行）',
				},
				{
					account_name: 'kuusoo tjr-stg',
					account_id: '611233622710',
					color: 'FAD791',
					biko: 'KUUSOO株式会社（TJR1 開発環境）',
					type: '社外（決済代行）',
				},
				{
					account_name: 'gather',
					account_id: '835418504718',
					color: 'FAD791',
					biko: '株式会社GATHER',
					type: '社内（ロードバランサーパッケージ）',
				},
				{
					account_name: 'belief',
					account_id: '210772962621',
					color: 'FAD791',
					biko: '株式会社Belief',
					type: '社外（決済代行）',
				},
				{
					account_name: 'honmagolf',
					account_id: '624973905985',
					color: 'FAD791',
					biko: '株式会社本間ゴルフ',
					type: '社外（決済代行）',
				},
				{
					account_name: 'crowdbank',
					account_id: '101066513747',
					color: 'FAD791',
					biko: '日本クラウド証券株式会社（本番環境）',
					type: '社外（サポート）',
				},
				{
					account_name: 'crowdbank+develop',
					account_id: '228587356262',
					color: 'FAD791',
					biko: '日本クラウド証券株式会社（開発環境）',
					type: '社外（決済代行）',
				},
				{
					account_name: 'crowdbank+staging',
					account_id: '468777726619',
					color: 'FAD791',
					biko: '日本クラウド証券株式会社（ステージング環境）',
					type: '社外（決済代行）',
				},
				{
					account_name: 'glam-style',
					account_id: '716547721789',
					color: 'FAD791',
					biko: '株式会社グラムスタイル',
					type: '社外（決済代行）',
				},
				{
					account_name: 'bluefog-solution',
					account_id: '861170967697',
					color: 'FAD791',
					biko: '株式会社エスユーエス',
					type: '社外（決済代行）',
				},
			];

			//データセット
			setData(items);
			setView(items);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const format1 = (cell, row) => {
		return (
			<input type="button" onClick={(e) => window.open('https://signin.aws.amazon.com/switchrole?account=' + row.account_id +'&roleName=OnocommAdminRole&displayName=' + row.account_name + '&color=' + row.color, '_blank')  } defaultValue={'切替'} />
		);
	};

	const columns = [
		{
			dataField: 'button',
			text: '切替',
			sort: false,
			formatter: format1,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'account_name',
			text: 'アカウント名',
			sort: true,
		},
		{
			dataField: 'account_id',
			text: 'アカウントID',
			sort: true,
		},
		{
			dataField: 'biko',
			text: '説明',
			sort: true,
		},
		{
			dataField: 'type',
			text: '区分',
			sort: true,
		},
	];

	const options = {
		sizePerPageList: [
			{ text: 'All', value: data.length},
		],
	};

	const defaultSorted = [
		{
			dataField: 'account_name',
			order: 'asc',
		}
	];	
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h1>AWSアカウント切替</h1>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'account_name'} data={view} columns={columns} checkbox={false} defaultSorted={defaultSorted}　options={options} />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
