import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
} from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import { FormText } from '../../Objects/Form';


const Default = () => {

	const { server_no } = useParams();
	const [load, setLoad] = useState(true);
	const [data, setData] = useState({});
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};

			if(server_no){
				//データ取得
				newData = (await API.Server.Get(server_no)).item;
			}

			
			setData(newData);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>

				<Form name="form">

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
					</Row>
					<hr/>
					<FormText label={'契約ID'} name={'server_no'} defaultValue={data.server_no} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'ホスト名'} name={'server_name'} defaultValue={data.server_name} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true}  />
					<FormText label={'接続(Windows)'} name={''} defaultValue={'C:\\Program Files (x86)\\PuTTY\\putty.exe -ssh ' + data.ssh_user + '@' + data.ip + ' -i "\\\\onocommfs2\\共有\\Project\\AWS\\_onocomm-server_keys\\' + data.ssh_key + '"'} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'接続(Mac)'} name={''} defaultValue={'ssh ' + data.ssh_user + '@' + data.ip + ' -i "/Volumes/共有/Project/AWS/_onocomm-server_keys/' + (data.ssh_key === 'onocomm-server.ppk' ? 'onocomm-server.pem' : data.ssh_key) + '"'} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'パスワード'} name={'ssh_passwd'} defaultValue={data.ssh_passwd} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'IPアドレス'} name={'ip'} defaultValue={data.ip} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							旧管理画面
						</Form.Label>
					</Row>
					<FormText label={'admin_url'} name={'admin_url'} defaultValue={data.admin_url} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'admin_user'} name={'admin_user'} defaultValue={data.admin_user} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'admin_passwd'} name={'admin_passwd'} defaultValue={data.admin_passwd} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							アクセス情報
						</Form.Label>
					</Row>
					<FormText label={'scp_user'} name={'scp_user'} defaultValue={data.scp_user} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'web_url'} name={'web_url'} defaultValue={data.web_url} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'basic_user'} name={'basic_user'} defaultValue={data.basic_user} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'basic_passwd'} name={'basic_passwd'} defaultValue={data.basic_passwd} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'ftp_host'} name={'ftp_host'} defaultValue={data.ftp_host} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'ftp_user'} name={'ftp_user'} defaultValue={data.ftp_user} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'ftp_passwd'} name={'ftp_passwd'} defaultValue={data.ftp_passwd} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'phpmyadmin_url'} name={'phpmyadmin_url'} defaultValue={data.phpmyadmin_url} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'phpmyadmin_user'} name={'phpmyadmin_user'} defaultValue={data.phpmyadmin_user} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'phpmyadmin_passwd'} name={'phpmyadmin_passwd'} defaultValue={data.phpmyadmin_passwd} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							データベース関連
						</Form.Label>
					</Row>
					<FormText label={'db_host'} name={'db_host'} defaultValue={data.db_host} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'db_port'} name={'db_port'} defaultValue={data.db_port} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'db_user'} name={'db_user'} defaultValue={data.db_user} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'db_passwd'} name={'db_passwd'} defaultValue={data.db_passwd} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'db_engine'} name={'db_engine'} defaultValue={data.db_engine} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<hr/>
					<FormText label={'対象顧客'} name={'customer_no'} defaultValue={data.customer_no + '　' + data.customer_name} readOnly={true} onClick={(e) => e.currentTarget.select()}  copy={true} />
					<FormText label={'タイプ'} name={'server_type'} defaultValue={data.server_type} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'AWSアカウント番号'} name={'account_no'} defaultValue={data.account_no} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'インスタンスID'} name={'instance_id'} defaultValue={data.instance_id} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'インスタンスタイプ'} name={'instance_type'} defaultValue={data.instance_type} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'FQDN'} name={'fqdn'} defaultValue={data.fqdn} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
					<FormText label={'OS'} name={'os'} defaultValue={data.os} readOnly={true} onClick={(e) => e.currentTarget.select()} copy={true} />
				</Form>


			</Container>
		);
	}
};


export default withRouter(Default);
