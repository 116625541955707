import { useState, useEffect  } from 'react';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { withRouter } from 'react-router-dom';


const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);

	useEffect(() => {
	
		const getData = async () => {
			
			const items = [
				{
					server_name: 'ReactDevel',
					type: 'SSH',
					connect: 'ssh ec2-user@3.112.115.215 -i /Volumes/共有/Project/AWS/Account/_オノコム/_keys/default.pem',
					ip: '3.112.115.215',
					biko: 'React 開発環境',
				},
				/*
				{
					server_name: 'system.onocomm',
					type: 'SSH',
					connect: 'ssh ec2-user@54.168.52.150 -i /Volumes/共有/Project/AWS/Account/_オノコム/_keys/default.pem',
					ip: '54.168.52.150',
					biko: '旧社内システム',
				},
				*/
				/*
				{
					server_name: 'web.onocomm',
					type: 'SSH',
					connect: 'ssh ec2-user@54.248.162.195 -i /Volumes/共有/Project/AWS/Account/_オノコム/_keys/default.pem',
					ip: '54.248.162.195',
					biko: '旧ポータルサイト',
				},
				{
					server_name: 'bin.onocomm',
					type: 'SSH',
					connect: 'ssh ec2-user@54.95.2.230 -i /Volumes/共有/Project/AWS/Account/_オノコム/_keys/default.pem',
					ip: '54.95.2.230',
					biko: '旧システム用バッチサーバー',
				},
				*/
				{
					server_name: 'mail1.onocomm.jp',
					type: 'SSH',
					connect: 'ssh ec2-user@52.193.101.83 -i /Volumes/共有/Project/AWS/Account/_オノコム+Server/_keys/onocomm-server.pem',
					ip: '52.193.101.83',
					biko: '共有メールサーバー',
				},
				/*
				{
					server_name: 'a.ns53.jp',
					type: 'SSH',
					connect: 'ssh root@54.95.142.137 -i /Volumes/共有/Project/オノコムクラウド/keys/標準認証鍵.pem',
					ip: '54.95.142.137',
					biko: '旧NS53',
				},
				{
					server_name: 'b.ns53.jp',
					type: 'SSH',
					connect: 'ssh root@52.197.140.186 -i /Volumes/共有/Project/オノコムクラウド/keys/標準認証鍵.pem',
					ip: '2.197.140.186',
					biko: '旧NS53',
				},
				*/
				{
					server_name: 'a.ns53.jp',
					type: 'SSH',
					connect: 'ssh ec2-user@18.179.105.49 -i /Volumes/共有/Project/AWS/Account/_オノコム/_keys/default.pem',
					ip: '18.179.105.49',
					biko: '新NS53',
				},
				{
					server_name: 'b.ns53.jp',
					type: 'SSH',
					connect: 'ssh ec2-user@35.76.62.38 -i /Volumes/共有/Project/AWS/Account/_オノコム/_keys/default.pem',
					ip: '35.76.62.38',
					biko: '新NS53',
				},
				{
					server_name: 'res03.onocomm.jp',
					type: 'SSH',
					connect: 'ssh ec2-user@54.65.189.29 -i /Volumes/共有/Project/AWS/Account/_オノコム+Server/_keys/onocomm-server.pem',
					ip: '54.65.189.29',
					biko: '共有WEBサーバー',
				},
				{
					server_name: 'backup.g2',
					type: 'SSH',
					connect: 'ssh admin@52.68.18.151',
					ip: '52.68.18.151',
					biko: '監視サーバー',
				},
				{
					server_name: 'file.backup',
					type: 'SSH',
					connect: 'ssh root@192.168.1.32',
					ip: '92.168.1.32',
					biko: '社内バックアップサーバー',
				},
			];

			//データセット
			setData(items);
			setView(items);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const format1 = (cell, row) => {
		return (
			<input type="text" onClick={(e) => e.currentTarget.select() } defaultValue={cell} size="100"/>
		);
	};

	const format2 = (cell, row) => {
		return (
			<input type="text" onClick={(e) => e.currentTarget.select() } defaultValue={cell} size="20"/>
		);
	};

	const format3 = (cell, row) => {
		return (
			<Button variant="dark" onClick={() => navigator.clipboard.writeText(row.connect) }>
				COPY
			</Button>
		);
	};

	const columns = [
		{
			dataField: 'server_name',
			text: 'サーバー名',
			sort: true,
		},
		{
			dataField: 'type',
			text: '種別',
			sort: true,
		},
		{
			dataField: 'ip',
			text: 'IPアドレス',
			sort: false,
			formatter: format2,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'connect',
			text: '接続詞',
			sort: false,
			formatter: format1,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'copy',
			text: 'コピー',
			sort: false,
			formatter: format3,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'biko',
			text: '説明',
			sort: true,
		},
	];

	const options = {
		sizePerPageList: [
			{ text: 'All', value: data.length},
		],
	};

	const defaultSorted = [
		{
			dataField: 'server_name',
			order: 'asc',
		}
	];	
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h1>サーバー接続</h1>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'server_name'} data={view} columns={columns} checkbox={false} defaultSorted={defaultSorted}　options={options} />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
