import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const BillMemo = {};

BillMemo.Get = async (bill_memo_no) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getBillMemo, { bill_memo_no : bill_memo_no }));
		const item = ref.data.getBillMemo;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

BillMemo.Query = async (bill_date) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryBillMemo, {
											bill_date : bill_date,
										}));
		let items = ref.data.queryBillMemo.items;
		while (ref.data.queryBillMemo.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryBillMemo, {
												bill_date : bill_date,
												nextToken: ref.data.queryBillMemo.nextToken
											}));
			items = items.concat(ref.data.queryBillMemo.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

BillMemo.Update = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.updateBillMemo, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


BillMemo.Create = async (params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.createBillMemo, { data: params }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

BillMemo.Delete = async (bill_memo_no) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.deleteBillMemo, { bill_memo_no: bill_memo_no }));
		return ({
			result: true,
			error: null,
			ref: ref,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

export default BillMemo;


