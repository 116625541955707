import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect, FormButton } from '../../Objects/Form';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const { domain_name } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [customer, setCustomer] = useState([]);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};

			if(domain_name){
				//データ取得
				newData = (await API.Mail.Get(domain_name)).item;
			}
			else{
				newData.domain_name='';
				newData.customer_no='';
				newData.customer_name='';
				newData.contract_date=Math.floor((new Date()).getTime() / 1000);
				newData.bill_start= covertTime('2007-01');
				newData.status='有効';
			}
			
			setData(newData);
			setCustomer((await API.Customer.GetAll()).items);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			if(domain_name){
				//更新
				const ref = await API.Mail.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				//新規
				const ref = await API.Mail.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(domain_name){
				//削除
				const ref = await API.Mail.Delete(domain_name);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};
	
	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setShowCustomer([false]);
		setRefresh([true]);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.op){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.op = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/mail/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/mail/' + data.domain_name;
		}
		else{
			setReload(reload+1);
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'ドメイン名'} name={'domain_name'} value={data.domain_name} readOnly={domain_name ? true : false} required={domain_name ? false : true} onChange={handleOnChange} />
					<FormButton label={'管理画面'} value={'管理画面にログインする'} onClick={()=>{document.login.submit();}} disabled={data.status !== '有効'} />
					<hr/>
					<FormButton label={'対象顧客'} name={'customer_no'} value={data.customer_no ? (data.customer_no + '　' + data.customer_name) : '未選択'} required={true} onClick={handleCustomerShow} />
					<FormText label={'契約日'} name={'contract_date'} value={dateShapingDate(data.contract_date)} type={'date'} required={true} onChange={handleOnChange} />
					<FormText label={'ホスト名'} name={'hostname'} value={data.hostname} required={true} onChange={handleOnChange} />
					<FormText label={'パスワード'} name={'password'} value={data.password} required={true} onChange={handleOnChange} />
					<FormSelect label={'ディスク容量'} name={'disk'} value={data.disk} data={ [ '10240', '102400' ] } required={true} onChange={handleOnChange} />
					<FormText label={'月額費用'} name={'price_notax'} value={data.price_notax} type={'number'} required={true} onChange={handleOnChange} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							アラート
						</Form.Label>
						<Col sm={9}>
							<Form.Group controlId={'disk_alert_flag'}>
								<Form.Control name={'disk_alert_flag'} as="select" value={data.disk_alert_flag} size={'md'} onChange={handleOnChange} >
									<option value="">----</option>
									<option value={true}>有効</option>
									<option value={false}>無効</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<FormText label={'送信先'} name={'disk_alert_to'} value={data.disk_alert_to} type={'email'} onChange={handleOnChange} />
					<FormText label={'通知率'} name={'disk_soft'} value={data.disk_soft} type={'number'} onChange={handleOnChange} />
					<FormText label={'警告率'} name={'disk_hard'} value={data.disk_hard} type={'number'} onChange={handleOnChange} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							削除保護
						</Form.Label>
						<Col sm={9}>
							<input type="checkbox" name="protect_flag" checked={data.protect_flag ? true : false} onChange={handleOnChange} />
						</Col>
					</Row>
					<hr/>
					<FormText label={'メモ'} name={'memo_text'} value={data.memo_text} onChange={handleOnChange} />
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} value={dateShapingMonth(data.bill_start)} type={'month'} required={true} onChange={handleOnChange} />
					<FormText label={'課金停止月'} name={'bill_end'} value={dateShapingMonth(data.bill_end)} type={'month'} onChange={handleOnChange} />
					<hr/>
					<FormSelect label={'ステータス'} name={'status'} value={data.status} data={ [ '有効', '無効' ] } required={true} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>

				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block disabled={lock || domain_name ? false : true} >Delete</Button>
					</Col>
				</Row>
				</Form>

				<form action="https://mail1.onocomm.jp/mail.cgi?" method="POST" name="login" target="_blank">
				<input type="hidden" name="flag" VALUE="login"/>
				<input type="hidden" name="domain_name" VALUE={data.domain_name}/>
				<input type="hidden" name="password" VALUE={data.password}/>
				</form>

			</Container>
		);
	}
};


export default withRouter(Default);
