import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect, FormButton } from '../../Objects/Form';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';
import { v4 as uuidv4 } from 'uuid';


const Default = () => {

	const history = useHistory();
	const { bill_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [customer, setCustomer] = useState([]);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};

			if(bill_no){
				//データ取得
				newData = (await API.Bill.Get(bill_no)).item;
			}
			else{
				newData.bill_no=uuidv4();
				newData.customer_no='';
				newData.customer_name='';
				newData.contract_date=Math.floor((new Date()).getTime() / 1000);
				newData.bill_start= covertTime('2007-01');
				newData.bill_cycle='掛売';
				newData.status='有効';
				newData.details=[{
					uuid:uuidv4(),
					sort:1,
					details:'',
					piece:0,
					piece_type:'件',
					price_notax:0,
					sub_price_notax:0,
				}];
			}

			//ソート
			newData.details.sort(function(a,b){
				if(a.sort < b.sort) return -1;
				if(a.sort > b.sort) return 1;
				return 0;
			});
			
			setData(newData);
			setCustomer((await API.Customer.GetAll()).items);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			if(bill_no){
				//更新
				const ref = await API.Bill.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				//新規
				const ref = await API.Bill.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(bill_no){
				//削除
				const ref = await API.Bill.Delete(bill_no);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};
	
	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setShowCustomer([false]);
		setRefresh([true]);
	};
	
	const handleAddDetailsClick = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.details.push({
			uuid:uuidv4(),
			sort:newData.details.length+1,
			details:'',
			piece:0,
			piece_type:'件',
			price_notax:0,
			sub_price_notax:0,
		});
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleDropDetailsClick = (event) => {
		const name = event.target.name;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.details){
			if(object.uuid !== name){
				newDetails.push(object);
			}
		}
		newData.details = newDetails;
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleSortClick = (event) => {
		const uuid = event.target.name;
		const value = event.target.value;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.details){
			if(object.uuid === uuid){
				if(value === '↑'){
					object.sort = object.sort - 2;
				}
				else{
					object.sort = object.sort + 2;
				}
			}
			newDetails.push(object);
		}
		newData.details = newDetails;
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.details){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.details = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		const updateData = dataUpdate(newData);
		
		//更新
		setData(updateData);
		setRefresh([true]);
	};
	
	const dataUpdate = (data) => {
		const newCalc = dataCalc(data);
		const newSort = dataSort(newCalc);
		return(newSort);
	};
	
	const dataCalc = (data) => {
		const isNumber = (value) => {
			try{
				return ((typeof Number(value) === 'number') && (isFinite(Number(value))));
			}
			catch(err){
				return(false);
			}
		};
		//金額の計算
		let total_price_notax = 0;
		const newDetails = [];
		for(const object of data.details){
			
			//計算（計算対象が数値でなければ0とする）
			const piece = isNumber(object.piece) ? Number(object.piece) : 0;
			const price_notax = isNumber(object.price_notax) ? Number(object.price_notax) : 0;
			
			//計算（計算対象が0なら結果も0とする）
			object.sub_price_notax = (piece || price_notax) ? Number(piece * price_notax) : 0;
			
			//合計値
			total_price_notax = Number(total_price_notax + object.sub_price_notax);
			
			newDetails.push(object);
		}
		data.details = newDetails;
		data.total_price_notax = total_price_notax;
		return(data);
	};
	
	const dataSort = (data) => {
		//ソート
		data.details.sort(function(a,b){
			if(a.sort < b.sort) return -1;
			if(a.sort > b.sort) return 1;
			return 0;
		});
		
		//ソート番号振り直し
		let i = 1;
		for(const object of data.details){
			object.sort=i++;
		}
		return(data);
	};

	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/bill/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/bill/' + data.bill_no;
		}
		else{
			setReload(reload+1);
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'課金ID'} name={'bill_no'} value={data.bill_no} readOnly={true} onChange={handleOnChange} />
					<hr/>
					<FormButton label={'対象顧客'} name={'customer_no'} value={data.customer_no ? (data.customer_no + '　' + data.customer_name) : '未選択'} onClick={handleCustomerShow} required={true} />
					<FormText label={'契約日'} name={'contract_date'} value={dateShapingDate(data.contract_date)} required={true} type={'date'} onChange={handleOnChange} />
					<FormText label={'件名'} name={'title'} value={data.title} required={true} onChange={handleOnChange} />
					<FormText label={'月額費用'} name={'total_price_notax'} value={data.total_price_notax ? data.total_price_notax.toLocaleString() + '円' : ''} readOnly={true} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							明細
						</Form.Label>
					</Row>
					<Row>
						<Col sm={12}>
					                <table cellSpacing="0" border="1" width="100%">
					                    <col width="10%" />
					                    <col width="60%" />
					                    <col width="10%" />
					                    <col width="10%" />
					                    <col width="10%" />
					                    <thead>
					                        <tr>
					                            <td>順番</td>
					                            <td>項目</td>
					                            <td>数量</td>
					                            <td>単価</td>
					                            <td>金額</td>
					                        </tr>
					                    </thead>
					                    <tbody>
					                    	{data.details.map(item => {
					                    		return(
						                            <tr key={item.uuid}>
						                                <td className="text-center">
						                                	<input type="button"name={item.uuid} defaultValue={'↑'} style={{width:'25px'}} onClick={handleSortClick} />　
						                                	<input type="button"name={item.uuid} defaultValue={'↓'} style={{width:'25px'}} onClick={handleSortClick} />
						                                </td>
						                                <td className="text-left">
						                                	<input type="text" name={item.uuid + ':details'} value={item.details} style={{width:'600px'}} onChange={handleOnChange} required={true} />　
						                                	<input type="button" name={item.uuid} defaultValue={'X'} style={{width:'25px'}} onClick={handleDropDetailsClick} />
						                                </td>
						                                <td className="text-right">
						                                	<input type="number" name={item.uuid + ':piece'} value={item.piece} style={{width:'50px'}} onChange={handleOnChange} />　
						                                	<input type="text" name={item.uuid + ':piece_type'} value={item.piece_type} style={{width:'30px'}} onChange={handleOnChange} />
						                                </td>
						                                <td className="text-right"><input type="number" name={item.uuid + ':price_notax'} value={item.price_notax} style={{width:'100px'}} onChange={handleOnChange} /></td>
						                                <td className="text-right"><input type="number" name={item.uuid + ':sub_price_notax'} value={item.sub_price_notax} style={{width:'100px'}} readOnly={true} /></td>
						                            </tr>
					                    		);
					                    	})}
				                    	    <tr>
				                                <td className="text-left"></td>
				                                <td className="text-center">　<input type="button" defaultValue={'行追加'} style={{width:'310px'}} onClick={handleAddDetailsClick} /></td>
				                                <td colSpan="3"></td>
				                            </tr>
					                    </tbody>
					                </table>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							請求月
						</Form.Label>
						<Col sm={9}>
							<input type="checkbox" name="bill_month01" checked={data.bill_month01 ? true : false} onChange={handleOnChange} /> 1月　
							<input type="checkbox" name="bill_month02" checked={data.bill_month02 ? true : false} onChange={handleOnChange} /> 2月　
							<input type="checkbox" name="bill_month03" checked={data.bill_month03 ? true : false} onChange={handleOnChange} /> 3月　
							<input type="checkbox" name="bill_month04" checked={data.bill_month04 ? true : false} onChange={handleOnChange} /> 4月　
							<input type="checkbox" name="bill_month05" checked={data.bill_month05 ? true : false} onChange={handleOnChange} /> 5月　
							<input type="checkbox" name="bill_month06" checked={data.bill_month06 ? true : false} onChange={handleOnChange} /> 6月　
							<input type="checkbox" name="bill_month07" checked={data.bill_month07 ? true : false} onChange={handleOnChange} /> 7月　
							<input type="checkbox" name="bill_month08" checked={data.bill_month08 ? true : false} onChange={handleOnChange} /> 8月　
							<input type="checkbox" name="bill_month09" checked={data.bill_month09 ? true : false} onChange={handleOnChange} /> 9月　
							<input type="checkbox" name="bill_month10" checked={data.bill_month10 ? true : false} onChange={handleOnChange} /> 10月　
							<input type="checkbox" name="bill_month11" checked={data.bill_month11 ? true : false} onChange={handleOnChange} /> 11月　
							<input type="checkbox" name="bill_month12" checked={data.bill_month12 ? true : false} onChange={handleOnChange} /> 12月
						</Col>
					</Row>
					<FormSelect label={'請求サイクル'} name={'bill_cycle'} value={data.bill_cycle} data={ [ '掛売', '当月', '先貰' ] } required={true} onChange={handleOnChange} />
					<FormText label={'メモ'} name={'memo_text'} value={data.memo_text} onChange={handleOnChange} />
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} value={dateShapingMonth(data.bill_start)} type={'month'} required={true} onChange={handleOnChange} />
					<FormText label={'課金停止月'} name={'bill_end'} value={dateShapingMonth(data.bill_end)} type={'month'} onChange={handleOnChange} />
					<hr/>
					<FormSelect label={'ステータス'} name={'status'} value={data.status} data={ [ '有効', '無効' ] } required={true} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>

				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block disabled={lock || bill_no ? false : true} >Delete</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
