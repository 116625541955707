import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../../Objects/Table';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../../Objects/Modal';


const Default = () => {
	
	const history = useHistory();
	const { storage_id } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [showDelete, setShowDelete] = useState([false]);
	const [domain_name, setDomainName] = useState('');

	useEffect(() => {
	
		const getData = async () => {
			
			//件数の取得
			const ref = await API.Storage.FilesGetAll(storage_id);
			
			//データセット
			setData(ref.items);
			setView(ref.items);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleDelete = async (event) => {
	
		setShowDelete([false]);
		
		try {

			/*
			if(domain_name){
			
				//レコードの確認
				const ref1 = await API.Storage.RecordGetAll(domain_name);
				if(ref1.items.length > 2){
					throw new Error('レコードを削除の上、ゾーンを削除してください。');
				}
				
				//レコードも削除
				for(const item of ref1.items){
					//更新
					const ref3 = await API.NS53.RecordDelete(item.name, item.type);
					
					//エラー確認
					if(ref3.error){
						throw new Error('入力エラーがありました。' + ref3.error);
					}
				}
				
				//ゾーン削除
				const ref2 = await API.NS53.DomainDelete(domain_name);
				//エラー確認
				if(ref2.error){
					throw new Error('入力エラーがありました。' + ref2.error);
				}
				
				
				throw new Error('削除しました。');
			}
			*/
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const handleDeleteShow = (domain_name) => {
		console.log(domain_name);
		setShowDelete([true]);
		setDomainName(domain_name);
	};
	
	const handleClose = () => {
		setAlert([]);
		setShowDelete([false]);
		handleReload();
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const formatButton1 = (cell, row) => {
		return (
			<div>
				<Button variant="dark" type="button" onClick={() => history.push('/ns53/' + row.storage_id + '/' + row.name + '/')}>
					詳細
				</Button>
			</div>
		);
	};

	const formatButton2 = (cell, row) => {
		return (
			<div>
				<Button variant="danger" type="button" onClick={() => handleDeleteShow(row.name)}>
					削除
				</Button>
			</div>
		);
	};

	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'dummy1',
			isDummyField: true,
			text: '詳細',
			sort: false,
			formatter: formatButton1,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'title',
			text: 'ファイル名',
			sort: true,
		},
		{
			dataField: 'date_add',
			text: '登録日',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'dummy2',
			isDummyField: true,
			text: '削除',
			sort: false,
			formatter: formatButton2,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalDelete domain_name={domain_name} show={showDelete} onClick={handleDelete} onHide={handleClose} />
				<Row>
					<Col sm={12}>
						<h4>ストレージ（{storage_id}） - ファイル一覧</h4>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1}>
									<Button variant="danger" type="button" block onClick={() => history.push('./new')} >フォルダの作成</Button>
								</Col>
								<Col sm={1}>
								        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
								</Col>
							</Row>
							
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'file_id'} data={view} columns={columns} checkbox={false} sort={ {field: 'title', order: 'asc'} } />
					</Col>
				</Row>
			</Container>
		);
	}
};

const ModalDelete = (props) => {

	/*
	const [flag, setFlag] = useState(true);

	useEffect(() => {
	
		const getData = async () => {
			
			//件数の取得
			const ref = await API.NS53.RecordGetAll(props.domain_name);
			
			//レコードが2件以下なら実行可能
			setFlag(ref.items.length <= 2 ? false : true);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	const handleClose = () => {
		setFlag(true);
		props.onHide();
	};
	
	return (
		<Modal show={props.show[0]} onHide={handleClose} size="lg" centered>
			<Modal.Body>
				<Form>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							「{props.domain_name}」のゾーンを削除しますか？<br/>
						        SOAレコード、NSレコード以外のレコード全てを削除の上、ゾーンを削除してください。
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} disabled={flag} >削除する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={handleClose} >キャンセル</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
	*/
	return(<></>);
};

export default withRouter(Default);
