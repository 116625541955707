import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, withRouter } from 'react-router-dom';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [hidden, setHidden] = useState(JSON.parse(sessionStorage.getItem('bill_veiw') ?? true));

	useEffect(() => {
	
		const getData = async () => {
			
			//件数の取得
			const ref = await API.Bill.GetAll();

			let newData = [];

			if(hidden){
				//対象外を除外
				newData = JSON.parse(JSON.stringify(ref.items.filter(item => item.status !== '無効')));
			}
			else{
				newData = JSON.parse(JSON.stringify(ref.items));
			}
			
			//データセット
			setData(newData);
			setView(newData);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload, hidden]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const handleClick = () => {
		const flag = hidden ? false : true;
		sessionStorage.setItem('bill_veiw', JSON.stringify(flag));
		setHidden(flag);
	};

	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/bill/' + row.bill_no)}>
					詳細
				</Button>
			</div>
		);
	};

	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'bill_no',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'customer_no',
			text: '顧客番号',
			sort: true,
		},
		{
			dataField: 'customer_name',
			text: '顧客名',
			sort: true,
		},
		{
			dataField: 'title',
			text: '件名',
			sort: true,
		},
		{
			dataField: 'total_price_notax',
			text: '月額費用',
			sort: true,
			formatter: (cell) => { return(cell ? cell.toLocaleString() + '円' : ''); },
			classes: 'text-right',
		},
		{
			dataField: 'contract_date',
			text: '契約日',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'status',
			text: 'ステータス',
			sort: true,
		},
	];
	
	const rowStyle = (row, rowIndex) => {
		const style = {};
		if (row.status === '無効') {
			style.backgroundColor = 'silver';
		}
		return style;
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h1>月額課金</h1>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1}>
									<Button variant="danger" type="button" block onClick={() => history.push('/bill/new')} >新規登録</Button>
								</Col>
								<Col>
									<Form.Check type="checkbox" label="無効を表示しない" checked={hidden ? true : false} onChange={handleClick}/>
								</Col>
							</Row>
							
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'bill_no'} data={view} columns={columns} checkbox={false} sort={ {field: 'total_price_notax', order: 'desc'} } rowStyle={rowStyle} />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
