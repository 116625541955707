import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const MailLog = {};

MailLog.Get = async (log_id) => {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getMailLog, { log_id : log_id }));
		const item = ref.data.getMailLog;
		return ({
			item: JSON.parse(JSON.stringify(item ?? {})),
			error: null,
		});
	}
	catch(err) {
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

MailLog.GetAll = async () => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanMailLog));
		let items = ref.data.scanMailLog.items;
		while (ref.data.scanMailLog.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanMailLog, { nextToken: ref.data.scanMailLog.nextToken }));
			items = items.concat(ref.data.scanMailLog.items);
		}
		return ({
			items:JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

MailLog.Query = async ({from_address, start_time, end_time}) => {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryMailLog, {
			from_address,
			start_time,
			end_time,
		}));
		let items = ref.data.queryMailLog.items;
		while (ref.data.queryMailLog.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryMailLog, {
				from_address,
				start_time,
				end_time,
				nextToken: ref.data.queryMailLog.nextToken
			}));
			items = items.concat(ref.data.queryMailLog.items);
		}
		return ({
			items: JSON.parse(JSON.stringify(items ?? [])),
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

export default MailLog;


