import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useParams, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';


const Default = () => {

	const { ssl_name } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [server_no, setServerNO] = useState(null);
	const [server_name, setServerName] = useState(null);
	const [alert, setAlert] = useState([]);

	useEffect(() => {
	
		const getData = async () => {

			const getServerNO = async (server_name) => {
				let server_no = null;
				const data = (await API.Server.GetAll()).items;
				if(data.filter(x => x.server_name === server_name).length > 1){
					return(null);
				}
				for(const item of data){
					if(item.server_name === server_name){
						server_no = item.server_no;
						break; 
					}
				}
				return(server_no);
			};

			//ターゲットのサーバー名
			const server_name = ((await API.SSL.Get(ssl_name)).item).target_server;
			console.log(server_name);
			setServerName(server_name);
			
			//サーバーNOの取得
			if(server_name){
				const server_no = await getServerNO(server_name);
				console.log(server_no);
				setServerNO(server_no);
			}

			//件数の取得
			const ref = await API.SSL.DetailsGetAll(ssl_name);
			
			//データセット
			setData(ref.items);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。' || alert[0] ==='追加しました。'){
			setReload(reload+1);
			//window.location.reload();
		}
	};
	
	const handleSubmit = async (row) => {
		
		try {
		
			const params ={
				ssl_name : ssl_name,
				//date_add:  Math.floor(((new Date()).getTime() - (60 * 60 * 9 * 1000)) / 1000),
				//crt_key: row.crt_key,
				//csr_key: row.csr_key,
				//key_key: row.key_key,
				//ori_key: row.ori_key,
				expiration: Math.floor(((new Date((new Date()).getFullYear() + 1, (new Date()).getMonth() + 1, 0)).getTime()) / 1000),
			};
			
			//更新
			const ref = await API.SSL.DetailsCreate(params);
			console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。' + ref.error);
			}
			
			throw new Error('追加しました。');
			
		}
		catch(err){
			//console.log(err);
			setAlert([err.message]);
		}
		
	};
	
	/*
	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/ssl/' + row.ssl_name)}>
					公開
				</Button>
			</div>
		);
	};
	*/
	
	const formatButtonUpdate = (cell, row, index) => {
	
		const handleUpdate = async (date_add) => {
			
			
			try {
				
				const params ={
					ssl_name : ssl_name,
					date_add: date_add,
					crt_key: document[date_add + ':crt_key'].content.value,
					cer_key: document[date_add + ':cer_key'].content.value,
					expiration: Math.floor(((new Date(document[date_add + ':expiration'].content.value)).getTime() - (60 * 60 * 9 * 1000)) / 1000),
					memo_text: document[date_add + ':memo_text'].content.value,
				};
				
				//更新
				const ref = await API.SSL.DetailsUpdate(params);

				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
				
			}
			catch(err){
				//console.log(err);
				setAlert([err.message]);
			}
			
		};
	
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => handleUpdate(row.date_add)}>
					編集
				</Button>
			</div>
		);
	};
	
	const formatButtonDelete = (cell, row, index) => {
	
		const handleDelete = async (row) => {
			
			try {
				
				//更新
				const ref = await API.SSL.DetailsDelete(ssl_name, row.date_add);

				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
				
			}
			catch(err){
				//console.log(err);
				setAlert([err.message]);
			}
			
		};
	
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => handleDelete(row)} disabled="true">
					削除
				</Button>
			</div>
		);
	};
	
	const formatDate1 = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const formatDate2 = (cell, row,a ) => {
		const dateObj      = new Date(Number(cell * 1000));
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const dateText     = (year + '-' + month + '-' + day);
		return (
			<Form  name={row.date_add + ':expiration'}>
				<input type="date" defaultValue={dateText} name={'content'} />
			</Form>
		);
	};
	
	const formatTextArea1 = (cell, row) => {
		return (
			<Form name={row.date_add + ':key_key'}>
				<textarea defaultValue={cell} name={'content'} readOnly={true} onClick={(e) => e.currentTarget.select()}/>
				<br/>
				<Button variant="dark" onClick={() => navigator.clipboard.writeText(cell) }>
					COPY
				</Button>
			</Form>
		);
	};

	const formatTextArea2 = (cell, row) => {
		return (
			<Form name={row.date_add + ':csr_key'}>
				<textarea defaultValue={cell} name={'content'} readOnly={true} onClick={(e) => e.currentTarget.select()} />
				<br/>
				<Button variant="dark" onClick={() => navigator.clipboard.writeText(cell) }>
					COPY
				</Button>
			</Form>
		);
	};

	const formatTextArea3 = (cell, row) => {
		return (
			<Form name={row.date_add + ':crt_key'}>
				<textarea defaultValue={cell} name={'content'} onClick={(e) => e.currentTarget.select()} />
				<br/>
				<Button variant="dark" onClick={() => navigator.clipboard.writeText(cell) }>
					COPY
				</Button>
			</Form>
		);
	};

	const formatTextArea4 = (cell, row) => {
		return (
			<Form name={row.date_add + ':cer_key'}>
				<textarea defaultValue={cell} name={'content'} onClick={(e) => e.currentTarget.select()} />
				<br/>
				<Button variant="dark" onClick={() => navigator.clipboard.writeText(cell) }>
					COPY
				</Button>
			</Form>
		);
	};

	const formatTextArea5 = (cell, row) => {
		return (
			<Form name={row.date_add + ':memo_text'}>
				<textarea defaultValue={cell} name={'content'} onClick={(e) => e.currentTarget.select()}/>
			</Form>
		);
	};
	
	const columns = [
		/*
		{
			dataField: 'dummy1',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		*/
		{
			dataField: 'date_add',
			text: '作成日',
			sort: true,
			formatter: formatDate1,
		},
		{
			dataField: 'expiration',
			text: '有効期限',
			sort: true,
			formatter: formatDate2,
		},
		{
			dataField: 'key_key',
			text: 'key(秘密鍵暗号解除)',
			sort: true,
			formatter: formatTextArea1,
		},
		{
			dataField: 'csr_key',
			text: 'CSRキー',
			sort: true,
			formatter: formatTextArea2,
		},
		{
			dataField: 'crt_key',
			text: 'CRTキー（公開鍵',
			sort: true,
			formatter: formatTextArea3,
		},
		{
			dataField: 'cer_key',
			text: 'CERキー（中間CA）',
			sort: true,
			formatter: formatTextArea4,
		},
		{
			dataField: 'memo_text',
			text: 'メモ',
			sort: true,
			formatter: formatTextArea5,
		},
		{
			dataField: 'dummy2',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButtonUpdate,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'dummy3',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButtonDelete,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
				<Row>
					<Col sm={12}>
						<h1>証明書一覧（{ssl_name}）</h1>
					</Col>
					<Col sm={12}>
						<Form>
							<Row style={{ margin: 20 }}>
							<Col sm={1}>
									<Button variant="danger" type="button" block onClick={handleSubmit} >新規登録</Button>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="button" block onClick={()=>{window.open('https://web.apply.pubcert.jprs.jp/', '_blank');}} >JPRS</Button>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="button" block onClick={()=>{window.open('https://jprs.jp/pubcert/service/certificate/', '_blank');}} >CA</Button>
								</Col>
								<Col sm={3}>
									<Button variant="dark" type="button" block onClick={()=>{window.open('/ssl/' + ssl_name, '_blank');}} >{ssl_name}</Button>
								</Col>
								<Col sm={3}>
									<Button variant="dark" type="button" block onClick={()=>{window.open('/server/' + server_no + '/access/', '_blank');}} disabled={server_no ? false : true}>{server_name ? server_name : 'N/A'}</Button>
								</Col>

								<Col sm={3} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
							
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'date_add'} data={data} columns={columns} sort={ {field: 'date_add', order: 'desc'} } />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
