import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';
import { v4 as uuidv4 } from 'uuid';


const Default = () => {

	const history = useHistory();
	const { quotation_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState([]);
	const [customer, setCustomer] = useState([]);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			if(quotation_no){
				//データ取得
				const item = (await API.Quotation.Get(quotation_no)).item;
				
				//ソート
				item.details.sort(function(a,b){
					if(a.sort < b.sort) return -1;
					if(a.sort > b.sort) return 1;
					return 0;
				});
				
				/*
				const newDetails = [];
				for(const object of item.details){
					object.uuid = uuidv4();
					newDetails.push(object);
				}
				item.details = newDetails;
				*/
				
				setData(item);
			}
			else{
				const newData = {};
				
				const dateObj = new Date();
				
				//本日
				newData.date_quotation = covertTime(dateObj);
				
				//一ヶ月後
				dateObj.setMonth(dateObj.getMonth() + 1);
				dateObj.setDate(dateObj.getDate() - 1);
				newData.date_limit = covertTime(dateObj);
				
				//変数
				let customer_no = '';
				let customer_name = '';
				let title = '';
				let i_total_price_notax = 0;
				let m_total_price_notax = 0;
				let i_total_tax = 0;
				let m_total_tax = 0;
				let i_total_price = 0;
				let m_total_price = 0;
				let details = [{
					uuid:uuidv4(),
					sort:1,
					details:'',
					piece:0,
					piece_type:'件',
					tax_per: 0.1,
					i_price_notax:0,
					i_sub_price_notax:0,
					i_tax:0,
					m_price_notax:0,
					m_sub_price_notax:0,
					m_tax:0,
				}];

				//コピー
				const quotation_text = sessionStorage.getItem('quotation_data');
				if(quotation_text){
					const quotation_data = JSON.parse(quotation_text);
					customer_no = quotation_data.customer_no;
					customer_name = quotation_data.customer_name;
					title = quotation_data.title;
					i_total_price_notax = quotation_data.i_total_price_notax;
					m_total_price_notax = quotation_data.m_total_price_notax;
					i_total_tax = quotation_data.i_total_tax;
					m_total_tax = quotation_data.m_total_tax;
					i_total_price = quotation_data.i_total_price;
					m_total_price = quotation_data.m_total_price;
					details = quotation_data.details;
					//削除
					sessionStorage.removeItem('quotation_data');
				}
				
				newData.customer_no = customer_no;
				newData.customer_name = customer_name;
				newData.title = title;
				newData.i_total_price_notax = i_total_price_notax;
				newData.m_total_price_notax = m_total_price_notax;
				newData.i_total_tax = i_total_tax;
				newData.m_total_tax = m_total_tax;
				newData.i_total_price = i_total_price;
				newData.m_total_price = m_total_price;
				newData.details=details;
				
				setData(newData);
			
			}
			
			setCustomer((await API.Customer.GetAll()).items);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleSubmit = async (event) => {
	
		//顧客番号の生成
		const generate = async () => {
		
			try{
			
				//チェックデジットの計算
				const checkDigit = async (jan) => {
				
					// 逆順にする
					const getReverse = async (req) => {
						return(req.split("").reverse().join(""));
					};

					// 偶数列、奇数列をそれぞれ足し合わせて返す
					const getTotal = async (req) => {
						const arr = req.split("");
						const evenList = [];
						const oddList = [];
						const len = arr.length;
						
						let lim = 0;
						for (let i = 0; i < len; i++) {
							lim++;
							const num = arr[i];
							if (i % 2 !== 0) {
								evenList.push(Number(num));
							}
							else {
								oddList.push(Number(num));
							}
							if (lim === len) {
								const eve = evenList.reduce(function(a, b){ return a + b });
								const odd = oddList.reduce(function(a, b){ return a + b });
								return([eve, odd * 3]);
							}
						}
					};

					// 偶数の合計と奇数の合計を足した末尾を使ってチェックデジットを返す
					const getDigit = async (sumEven, sumOdd) =>{
						const sum = sumEven + sumOdd;
						const end = Number(String(sum).slice(-1));
						if (end === 0) {
							return('0');
						}
						else {
							const req = 10 - end;
							return(req.toString());
						}
					};
					
					const req = await getReverse(jan);
					
					const [sumEven, sumOdd] = await getTotal(req);
					
					const digit = await getDigit(sumEven, sumOdd);
					
					return(jan + digit);

				};

				//月初め
				const dateObj = new Date();
				dateObj.setDate(1);
				const start = covertTime(dateObj);
				const year  = ('00' + dateObj.getFullYear().toString()).slice(-2);
				const month = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
				
				//月末
				dateObj.setMonth(dateObj.getMonth() + 1);
				dateObj.setDate(dateObj.getDate() - 1);
				const end = covertTime(dateObj);
				
				//件数の取得
				const ref = await API.Quotation.GetAll();
				
				//データのソート
				ref.items.sort(
					(a, b) => {
						if (a.date_quotation > b.date_quotation) return -1;
						if (a.date_quotation < b.date_quotation) return 1;
						return 0;
					}
				);
				
				//範囲の抽出
				const newDate = [];
				for(const item of ref.items){
					if(item.date_quotation >= start && item.date_quotation <= end){
						newDate.push(item);
					}
				}
				
				//シリアルの作成
				const serial = ('0000' + (Number(newDate.length) + 1).toString()).slice(-4);
				
				//見積番号
				const number = year + month + serial;

				const di = await checkDigit(number);
				
				const quotation_no = 'M' + di;
				
				const checkno = ref.items.filter(item => item.quotation_no === quotation_no).length;
				
				//console.log(checkno);
				
				if(checkno === 0){
					return(quotation_no);
				}
				else {
					return('');
				}
			}
			catch(err){
				console.log(err);
			}
		
		};
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			if(quotation_no){
			
				//更新
				const ref = await API.Quotation.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				
				//見積番号の取得
				data.quotation_no = await generate();
				
				//更新
				const ref = await API.Quotation.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
		
		console.log(reload);
		
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			//削除
			const ref = await API.Quotation.Delete(quotation_no);
			//console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('エラーがありました。' + ref.error);
			}
			
			throw new Error('削除しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};

	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};
	
	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setRefresh([true]);
		setShowCustomer([false]);
	};
	
	const handleSortClick = (event) => {
		const uuid = event.target.name;
		const value = event.target.value;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.details){
			if(object.uuid === uuid){
				if(value === '↑'){
					object.sort = object.sort - 2;
				}
				else{
					object.sort = object.sort + 2;
				}
			}
			newDetails.push(object);
		}
		newData.details = newDetails;
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleAddDetailsClick = (sort) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.details.push({
			uuid:uuidv4(),
			sort: sort ? sort : newData.details.length+1,
			details:'',
			piece:0,
			piece_type:'件',
			tax_per: 0.1,
			i_price_notax:0,
			i_sub_price_notax:0,
			i_tax:0,
			m_price_notax:0,
			m_sub_price_notax:0,
			m_tax:0,
		});
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleAddDetailsSSLDVClick = (sort) => {
		const newData = JSON.parse(JSON.stringify(data));
		data.title = 'SSLサーバー証明書取得費用';
		newData.details.push({
			uuid:uuidv4(),
			sort: sort ? sort : newData.details.length+1,
			details:'JPRS　SSLサーバー証明書　DV（ドメイン認証型）　「」　１年間',
			piece:1,
			piece_type:'件',
			tax_per: 0.1,
			i_price_notax:12000,
			i_sub_price_notax:0,
			i_tax:0,
			m_price_notax:0,
			m_sub_price_notax:0,
			m_tax:0,
		});
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleDropDetailsClick = (event) => {
		const name = event.target.name;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.details){
			if(object.uuid !== name){
				newDetails.push(object);
			}
		}
		newData.details = newDetails;
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleCutoffFlagClick = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			//cutoff_flag反転
			data.cutoff_flag = data.cutoff_flag ? false : true;
			
			//cutoff_flagがfalseならflag_1もfalseへ
			data.flag_1 =  data.cutoff_flag ? data.flag_1 : false;
			
			//更新
			const ref = await API.Quotation.Update(data);
			//console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('エラーがありました。' + ref.error);
			}
			
			throw new Error('更新しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleFlag1Click = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			//cutoff_flag反転
			data.flag_1 = data.flag_1 ? false : true;
			
			//更新
			const ref = await API.Quotation.Update(data);
			//console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('エラーがありました。' + ref.error);
			}
			
			throw new Error('更新しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.details){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.details = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else{
			newData[name] = value;
		}
		
		const updateData = dataUpdate(newData);
		
		//更新
		setData(updateData);
		setRefresh([true]);
	};

	const dataUpdate = (data) => {
		const newCalc = dataCalc(data);
		const newSort = dataSort(newCalc);
		return(newSort);
	};
	
	const dataCalc = (data) => {
		const isNumber = (value) => {
			try{
				return ((typeof Number(value) === 'number') && (isFinite(Number(value))));
			}
			catch(err){
				return(false);
			}
		};
		//金額の計算
		let i_total_price_notax = 0;
		let m_total_price_notax = 0;
		let i_total_tax = 0;
		let m_total_tax = 0;
		const newDetails = [];
		for(const object of data.details){
			
			//計算（計算対象が数値でなければ0とする）
			const piece = isNumber(object.piece) ? Number(object.piece) : 0;
			const i_price_notax = isNumber(object.i_price_notax) ? Number(object.i_price_notax) : 0;
			const m_price_notax = isNumber(object.m_price_notax) ? Number(object.m_price_notax) : 0;
			const tax_per = isNumber(object.tax_per) ? Number(object.tax_per) : 0;
			
			//計算（計算対象が0なら結果も0とする）
			object.i_sub_price_notax = (piece || i_price_notax) ? Number(piece * i_price_notax) : 0;
			object.m_sub_price_notax = (piece || m_price_notax) ? Number(piece * m_price_notax) : 0;
			
			//計算（計算対象が0なら結果も0とする）
			object.i_tax = object.i_sub_price_notax ? Number(object.i_sub_price_notax * tax_per) : 0;
			object.m_tax = object.m_sub_price_notax ? Number(object.m_sub_price_notax * tax_per) : 0;
			
			//合計値
			i_total_price_notax = Number(i_total_price_notax + object.i_sub_price_notax);
			m_total_price_notax = Number(m_total_price_notax + object.m_sub_price_notax);
			
			//消費税
			i_total_tax = Number(i_total_tax + object.i_tax);
			m_total_tax = Number(m_total_tax + object.m_tax);
			
			newDetails.push(object);
		}
		data.details = newDetails;
		data.i_total_price_notax = i_total_price_notax;
		data.m_total_price_notax = m_total_price_notax;
		data.i_total_tax = Math.floor(i_total_tax);
		data.m_total_tax = Math.floor(m_total_tax);
		data.i_total_price = Number(data.i_total_price_notax + data.i_total_tax);
		data.m_total_price = Number(data.m_total_price_notax + data.m_total_tax);
		return(data);
	};
	
	const dataSort = (data) => {
		//ソート
		data.details.sort(function(a,b){
			if(a.sort < b.sort) return -1;
			if(a.sort > b.sort) return 1;
			return 0;
		});
		
		//ソート番号振り直し
		let i = 1;
		for(const object of data.details){
			object.sort=i++;
		}
		return(data);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='登録しました。'){
			window.location.href = '/quotation/' + data.quotation_no;
		}
	};

	const handleCopy = () => {
		sessionStorage.setItem('quotation_data', JSON.stringify(data));
		window.location.href='/quotation/new/';
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }} fluid>
			        <link rel="stylesheet" type="text/css" media="screen" href="/css/quotation/report_screen.css" />
			        <link rel="stylesheet" type="text/css" media="print" href="/css/quotation/report_print.css" />
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={11} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row className="justify-content-md-center">
						<Col md="auto">
							<div className="content" style={{width: '327mm'}}>
								<div>
									<Button variant={data.cutoff_flag ? 'secondary' : 'success'} onClick={ handleCutoffFlagClick } disabled={quotation_no ? false : true} >
										{data.cutoff_flag ? '締切済' : '未締切'}
									</Button>
									　
									<Button variant={data.flag_1 ? 'success' : 'secondary'} onClick={ handleFlag1Click } disabled={quotation_no ? (data.cutoff_flag ? false : true) : true} >
										{data.flag_1 ? '公開済' : '未公開'}
									</Button>
									　
									<Button variant="info" onClick={ ()=>{window.location.href = '/print/quotation/' + quotation_no + '/'} } disabled={quotation_no ? false : true} >
										見積書
									</Button>
									　
									<Button variant="info" onClick={ ()=>{window.location.href = '/print/order/' + quotation_no + '/'} } disabled={quotation_no ? false : true} >
										発注書
									</Button>
									　
									<Button variant="info" onClick={handleCopy} disabled={quotation_no ? false : true} >
										複製
									</Button>
									　
									<Button variant="secondary" onClick={()=>{ history.goBack(); }} >
										もどる
									</Button>
								</div>
								<div className="page">
							                
									<p className="quotation_head">御　見　積　書</p>

							                <table className="invoice_separate" >
							                    <tbody>
							                    <tr>
							                        <td className="cell">
											<p className="quotation_head2" style={{width: '80%'}}>
												<div  className="text-left">
													　<input type="button" defaultValue={data.customer_no ? data.customer_no : '選択'} onClick={handleCustomerShow} style={{width: '300px', marginBottom: 10}}/><br/>
													
													　<input type="text" name="customer_name" value={data.customer_name} style={{width: '300px', fontSize: '80%'}} onChange={handleOnChange} required={true} />　御中
												</div>
											</p>
											<p>御照会賜りました件、下記の通り御見積り申し上げます。</p>

							                            <table className="invoice_info_left">
								                    <tbody>
							                            <tr>
							                                <th className="text-center">発行日</th>
							                                <td>
							                                	　　<input type="date" name="date_quotation" value={dateShapingDate(data.date_quotation)} onChange={handleOnChange} required={true} />
							                                </td>
							                            </tr>
							                            <tr>
							                                <th className="text-center">件名</th>
							                                <td>
							                                	　　<input type="text" name="title" value={data.title} style={{width:'300px'}} onChange={handleOnChange} required={true} />
							                                </td>
							                            </tr>
							                            <tr>
							                                <th className="text-center">見積有効期</th>
							                                <td>
							                                	　　<input type="date" name="date_limit" value={dateShapingDate(data.date_limit)} onChange={handleOnChange} required={true} />
							                                </td>
							                            </tr>
							                            <tr>
							                                <th className="text-center">お取引条件</th>
							                                <td>　　別途ご相談</td>
							                            </tr>
								                    </tbody>
							                            </table>

							                        </td>
							                        <td className="space" style={{width: '40%'}}>　</td>
							                        <td className="cell">
											<p className="text-right">{dateShapingDate(data.date_quotation).replace(/-/,'年').replace(/-/,'月')+'日'}<br/>（No.{quotation_no ? quotation_no : '[未発行]'}）</p>
											<p className="publisher_seal">
												<img src="/img/logo.png" alt="株式会社オノコム" /><br/>
												〒530-0005<br/>
												大阪市北区中之島4 丁目3 番25 号<br/>
												フジヒサFJ 中之島ビル11 階<br/>
												TEL : 06-6441-6030 FAX : 06-6441-6031<br/>
												株式会社オノコム
											</p>
							                        </td>
							                    </tr>
							                    </tbody>
							                </table>

									<h2>御見積内容</h2>

							                <table className="t1" cellSpacing="0">
							                    <col width="6%" />
							                    <col width="46%" />
							                    <col width="10%" />
							                    <col width="8%" />
							                    <col width="8%" />
							                    <col width="8%" />
							                    <col width="8%" />
							                    <col width="8%" />
							                    <thead>
							                        <tr>
							                            <th rowSpan="2" className="text-center">順番</th>
							                            <th rowSpan="2" className="text-center">項目</th>
							                            <th rowSpan="2" className="text-center">数量</th>
							                            <th rowSpan="2" className="text-center">消費税</th>
							                            <th colSpan="2" className="text-center">初期費用</th>
							                            <th colSpan="2" className="text-center">月額費用</th>
							                        </tr>
							                        <tr>
							                            <th className="text-center">単価</th>
							                            <th className="text-center">金額</th>
							                            <th className="text-center">単価</th>
							                            <th className="text-center">金額</th>
							                        </tr>
							                    </thead>
							                    <tbody>
							                    	{data.details.map(item => {
							                    	
							                    		return(
								                    	    <tr key={item.uuid}>
								                                <td className="text-center">
								                                	<input type="button"name={item.uuid} defaultValue={'↑'} style={{width:'20px'}} onClick={handleSortClick} />　
								                                	<input type="button"name={item.uuid} defaultValue={'↓'} style={{width:'20px'}} onClick={handleSortClick} />
								                                </td>
								                                <td className="text-left">
								                                	<input type="text" name={item.uuid + ':details'} value={item.details} style={{width:'400px'}} onChange={handleOnChange} required={true} />　
								                                	<input type="button" name={item.uuid} defaultValue={'X'} style={{width:'20px'}} onClick={handleDropDetailsClick} />　
								                                	<input type="button" name={item.uuid} defaultValue={'+'} style={{width:'20px'}} onClick={()=>{handleAddDetailsClick(item.sort)}} />
								                                </td>
								                                <td className="text-right">
								                                	<input type="number" name={item.uuid + ':piece'} value={item.piece} style={{width:'50px'}} onChange={handleOnChange} />　
								                                	<input type="text" name={item.uuid + ':piece_type'} value={item.piece_type} style={{width:'30px'}} onChange={handleOnChange} />
								                                </td>
								                                <td className="text-right"><input type="number" name={item.uuid + ':tax_per'} value={item.tax_per} style={{width:'60px'}} onChange={handleOnChange} /></td>
								                                <td className="text-right"><input type="number" name={item.uuid + ':i_price_notax'} value={item.i_price_notax} style={{width:'60px'}} onChange={handleOnChange} /></td>
								                                <td className="text-right"><input type="number" name={item.uuid + ':i_sub_price_notax'} value={item.i_sub_price_notax} style={{width:'60px'}} readOnly={true} /></td>
								                                <td className="text-right"><input type="number" name={item.uuid + ':m_price_notax'} value={item.m_price_notax} style={{width:'60px'}} onChange={handleOnChange} /></td>
								                                <td className="text-right"><input type="number" name={item.uuid + ':m_sub_price_notax'} value={item.m_sub_price_notax} style={{width:'60px'}} readOnly={true} /></td>
								                            </tr>
							                    		);
							                    	
							                    	})}
						                    	    <tr>
						                                <td className="text-left"></td>
						                                <td className="text-center">
															<input type="button" defaultValue={'行追加'} style={{width:'100px'}} onClick={handleAddDetailsClick} />
															　
															<input type="button" defaultValue={'SSL DV'} style={{width:'100px'}} onClick={handleAddDetailsSSLDVClick} />
														</td>
						                                <td colSpan="6"></td>
						                            </tr>
							                    </tbody>
							                </table>



							                <table className="t2" cellSpacing="0">
							                    <col width="55%" />
							                    <col width="3%" />
							                    <col width="6%" />
							                    <col width="6%" />
							                    <col width="10%" />
							                    <col width="10%" />
							                    <col width="10%" />
							                    <tbody>
							                        <tr>
										    <td rowSpan="3" className="memo">
											　備　考<br/>
											<textarea cols="75" rows="4" name="biko_text" value={data.biko_text} onChange={handleOnChange} />
										    </td>
							                            <td rowSpan="3" className="space">　</td>
							                            <td className="sum">小計</td>
							                            <td className="sum">　</td>
							                            <td className="price">{data.i_total_price_notax.toLocaleString()} 円 </td>
							                            <td className="sum">　</td>
							                            <td className="price">{data.m_total_price_notax.toLocaleString()} 円 </td>
							                        </tr>
							                        <tr>
							                            <td className="sum">消費税</td>
							                            <td className="sum">　</td>
							                            <td className="price">{data.i_total_tax.toLocaleString()} 円 </td>
							                            <td className="sum">　</td>
							                            <td className="price">{data.m_total_tax.toLocaleString()} 円 </td>
							                        </tr>
							                        <tr>
							                            <td className="sum">合計</td>
							                            <td className="sum">　</td>
							                            <td className="price">{data.i_total_price.toLocaleString()} 円 </td>
							                            <td className="sum">　</td>
							                            <td className="price">{data.m_total_price.toLocaleString()} 円 </td>
							                        </tr>
							                    </tbody>
							                </table>

							        </div>

							</div>
						</Col>
					</Row>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>


				<Form onSubmit={handleDelete}>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="submit" block disabled={quotation_no ? (lock ? true : false) : true}>Delete</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};

export default withRouter(Default);
