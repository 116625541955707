import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect, FormButton } from '../../Objects/Form';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const { user_id } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [customer, setCustomer] = useState([]);
	const [showCustomer1, setShowCustomer1] = useState([false]);
	const [showCustomer2, setShowCustomer2] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};

			if(user_id){
				//データ取得
				newData = (await API.PortalUser.Get(user_id)).item;
			}
			else{
				//newData.user_id = uuidv4();
				newData.user_id = null;
				newData.user_name = '';
				newData.customer_no = '';
				newData.customer_name = '';
				newData.customer_group = [];
				newData.status = '有効';
			}
			
			setData(newData);
			setCustomer((await API.Customer.GetAll()).items);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			console.log(data);

			if(user_id){
				
				//更新
				const ref = await API.PortalUser.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				//新規
				//const ref = await API.PortalUser.Create(data);
				const ref = await API.PortalUser.executeLambda(data);

				console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(user_id){
				//削除
				const ref = await API.PortalUser.Delete(user_id);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleCustomerShow1 = () => {
		setShowCustomer1([true]);
	};
	
	const handleCustomerShow2 = () => {
		setShowCustomer2([true]);
	};
	
	const handleCustomerClick1 = (customer_no, customer_name) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setShowCustomer1([false]);
		setRefresh([true]);
	};
	
	const handleCustomerClick2 = (customer_no, customer_name) => {
		const newData = JSON.parse(JSON.stringify(data));
		let flag = false;
		for(const item of newData.customer_group){
			if(customer_no === item.customer_no){
				flag = true;
			}
		}
		if(!flag){
			newData.customer_group.push({
				customer_no : customer_no,
				customer_name : customer_name,
			});
		}
		setData(newData);
		setShowCustomer2([false]);
		setRefresh([true]);
	};
	
	const handleDropCustomerGroup = (customer_no) => {
		const newData = JSON.parse(JSON.stringify(data));
		const newGroup = [];
		for(const item of newData.customer_group){
			if(customer_no !== item.customer_no){
				newGroup.push({
					customer_no : item.customer_no,
					customer_name : item.customer_name,
				});
			}
		}
		newData.customer_group = newGroup;
		setData(newData);
		setShowCustomer2([false]);
		setRefresh([true]);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.op){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.op = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		if(alert[0] ==='削除しました。'){
			window.location.href = '/portal-user/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/portal-user/';
		}
		setAlert([]);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer1} lock={lock} onSelect={handleCustomerClick1} />
				<ModalCustomer data={customer} show={showCustomer2} lock={lock} onSelect={handleCustomerClick2} />
				
				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<div  style={{display : data.user_id ? '' : 'none'}}>
						<hr/>
						<FormText label={'ユーザID'} name={'user_id'} value={data.user_id ? data.user_id : '未取得'} readOnly={true} required={false} />
					</div>
					<hr/>
					<FormButton label={'対象顧客'} name={'customer_no'} value={data.customer_no ? (data.customer_no + '　' + data.customer_name) : '未選択'} required={true} onClick={handleCustomerShow1} />
					<FormText label={'ユーザ名'} name={'user_name'} value={data.user_name} required={true} onChange={handleOnChange} />
					<FormText label={'メール'} name={'email'} value={data.email} required={true} onChange={handleOnChange} />
					<hr style={{display : 'none'}}/>
					<Row style={{display : 'none'}}>
						<Form.Label column sm={3}>
							管理者フラグ
						</Form.Label>
						<Col sm={9}>
							<input type="checkbox" name="admin_flag" checked={data.admin_flag ? true : false} onChange={handleOnChange} />
						</Col>
					</Row>
					<hr style={{display : data.user_id ? '' : 'none'}}/>
					<Row style={{display : data.user_id ? '' : 'none'}}>
						<Form.Label column sm={3}>
							対象顧客
						</Form.Label>
						<Col sm={9}>
					                <table cellSpacing="0" border="1" width="100%">
					                    <col width="10%" />
					                    <col width="45%" />
					                    <col width="45%" />
					                    <tbody>
					                    	{data.customer_group.map(item => {
					                    		return(
						                            <tr key={item.customer_no}>
						                                <td className="text-center">
						                                	<input type="button" defaultValue={'削除'} onClick={()=>{handleDropCustomerGroup(item.customer_no)}} />
						                                </td>
						                                <td className="text-center">
						                                	{item.customer_no}
						                                </td>
						                                <td className="text-center">
						                                	{item.customer_name}
						                                </td>
						                            </tr>
					                    		);
					                    	})}
				                    	    <tr>
				                                <td colSpan="3" className="text-center">
				                                	<input type="button" defaultValue={'追加'} style={{width:'310px'}} onClick={handleCustomerShow2} />
				                                </td>
				                            </tr>
					                    </tbody>
					                </table>
						</Col>
					</Row>
					<hr/>
					<FormText label={'メモ'} name={'memo_text'} value={data.memo_text} onChange={handleOnChange} />
					<hr/>
					<FormSelect label={'ステータス'} name={'status'} value={data.status} data={ [ '有効', '無効' ] } required={true} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>

				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block disabled={lock || user_id ? false : true} >Delete</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
