import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useParams, withRouter } from 'react-router-dom';
import { FormText, FormTextArea } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';
import { v4 as uuidv4 } from 'uuid';


const Default = () => {

	//const history = useHistory();
	const { bill_memo_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};

			if(bill_memo_no){
				//データ取得
				newData = (await API.BillMemo.Get(bill_memo_no)).item;
				//console.log(newData);
			}
			else{

				const dateObj      = new Date((new Date()).getFullYear().toString(), (new Date()).getMonth(), 1, 0, 0, 0);
				const bill_date    = Math.floor(dateObj.getTime() / 1000);

				//初期データ
				newData.bill_memo_no=uuidv4();
				newData.bill_date=Math.floor(bill_date);
				newData.title='';
				newData.person='';
				newData.memo_text='';
			}
			
			setData(newData);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			if(bill_memo_no){
				//更新
				const ref = await API.BillMemo.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				//新規
				const ref = await API.BillMemo.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(bill_memo_no){
				//削除
				const ref = await API.BillMemo.Delete(bill_memo_no);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};	
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.details){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.details = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
		
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/bill-memo/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/bill-memo/' + data.bill_memo_no;
		}
		else{
			setReload(reload+1);
		}
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big>　<a href="/bill-memo/">戻る</a></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'メモID'} name={'bill_memo_no'} value={data.bill_memo_no} readOnly={true} onChange={handleOnChange} />
					<FormText label={'対象月'} name={'bill_date'} value={dateShapingMonth(data.bill_date)} type={'month'} onChange={handleOnChange} required={true} />
					<hr/>
					<FormText label={'記入者'} name={'person'} value={data.person} required={true} onChange={handleOnChange} />
					<FormText label={'件名'} name={'title'} value={data.title} required={true} onChange={handleOnChange} />
					<hr/>
					<FormTextArea label={'メモ'} name={'memo_text'} rows={20} value={data.memo_text} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ window.location.href = '/bill-memo/'; }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>

				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block disabled={lock || bill_memo_no ? false : true} >Delete</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
