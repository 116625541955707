import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, withRouter } from 'react-router-dom';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [selectMount, setSelectMount] = useState((new Date()).getFullYear().toString() + ':' + ('00' + ((new Date()).getMonth()).toString()).slice(-2));

	useEffect(() => {
	
		const getData = async () => {
			
			const year         = Number(selectMount.split(':')[0]);
			const month        = Number(selectMount.split(':')[1]);

			const dateObj      = new Date(year, month - 1, 1, 0, 0, 0);
			const bill_date    = Math.floor(dateObj.getTime() / 1000);

			//件数の取得
			const ref = await API.BillMemo.Query(bill_date);
			
			//debug
			//console.log(bill_date);
			//console.log(ref);
			
			//データセット
			setData(ref.items);
			setView(ref.items);

			//表示開始
			setLoad(false);

		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const generateMonth = () => {
		
		const newMonth = [];
		
		for(const i of [...Array(60).keys()]){
			const dateObj      = new Date();
			dateObj.setMonth(dateObj.getMonth() - i + 12);
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			newMonth.push({
				year  : year,
				month : month,
			});
		}
		return(newMonth);
	};

	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const handleSelectMountOnChange = (event) => {
		setSelectMount(event.target.value);
		handleReload();
	};

	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/bill-memo/' + row.bill_memo_no)}>
					詳細
				</Button>
			</div>
		);
	};

	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			//const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'bill_memo_no',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'bill_date',
			text: '対象月',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'person',
			text: '記入者',
			sort: true,
		},
		{
			dataField: 'title',
			text: '件名',
			sort: true,
		},
		{
			dataField: 'memo_text',
			text: 'メモ',
			sort: true,
		},
	];
	
	const rowStyle = (row, rowIndex) => {
		const style = {};
		if (row.status === '無効') {
			style.backgroundColor = 'silver';
		}
		return style;
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h1>課金メモ</h1>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1}>
									<Button variant="danger" type="button" block onClick={() => history.push('/bill-memo/new')} >新規登録</Button>
								</Col>
							</Row>
							
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={2}>
									<Form.Group controlId="select_month">
									        <Form.Control name="select_month" as="select" onChange={handleSelectMountOnChange} value={selectMount}>
									        {generateMonth().map(item => {
										        return(<option key={item.year + item.month} value={item.year + ':' + item.month}>{item.year}年{item.month}月</option>);
										})}
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={4} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'bill_memo_no'} data={view} columns={columns} checkbox={false} sort={ {field: 'bill_date', order: 'desc'} } rowStyle={rowStyle} />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
