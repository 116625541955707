import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { useParams, withRouter } from 'react-router-dom';

const firstPageNum = 7;
const secondPageNum = 18;

const Default = () => {

	const { quotation_no } = useParams();
	const [load, setLoad] = useState(true);
	const [page, setPage] = useState([]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			const getPageData = async (data) => {
			
				const newData = Object.assign({}, data);
				
				const PageData = [];
				
				//1ページ目のデータ
				const FirstPageData = Object.assign({}, newData);
				const FirstPageDetails = [];
				const SecoundPageDetails = [];
				let i = 0;
				for(const item of newData.details){
					if(i++ < firstPageNum){
						FirstPageDetails.push(item);
					}
					else{
						SecoundPageDetails.push(item);
					}
				}
				FirstPageData.details = FirstPageDetails;
				FirstPageData.page = 1;
				PageData.push(FirstPageData);
				
				//2ページ目のデータ
				if(SecoundPageDetails.length){
					for(const page of [...Array(Math.ceil(SecoundPageDetails.length / secondPageNum)).keys()].map(i => ++i)){
						const SecoundData = Object.assign({}, newData);
						const PageDetails = [];
						let j = 0;
						for(const item of SecoundPageDetails){
							j++;
							
							if(j > Number(secondPageNum * (page - 1)) &&j <= Number(secondPageNum * page)){
								PageDetails.push(item);
							}
						}
						SecoundData.details = PageDetails;
						SecoundData.page = Number(page + 1);
						PageData.push(SecoundData);
					}
				}
				
				//MAXページ
				const max_page = PageData.slice(-1)[0].page;
				const newPageData = [];
				for(const page of PageData){
					page.max_page = max_page;
					newPageData.push(page);
				}

				//最終ページが奇数だった場合空ページを差し込む
				/*
				if( (max_page % 2) !== 0 && max_page !== 1){
					newPageData.push({empty_page : true});
				}
				*/
				
				return(newPageData);
			};
			
			//データ取得
			const item = (await API.Quotation.Get(quotation_no)).item;
			
			//テスト
			/*
			item.details = item.details.concat(item.details);
			item.details = item.details.concat(item.details);
			item.details = item.details.concat(item.details);
			item.details = item.details.concat(item.details);
			item.details = item.details.concat(item.details);
			item.details = item.details.concat(item.details);
			const newDetail = [];
			let z = 0;
			for(const detail of item.details){
				z++;
				if(z <= 25 + 18){
					newDetail.push(detail);
				}
			}
			item.details = newDetail;
			*/
			
			//ソートとページ作成
			if(item.details && item.details.length){
				item.details.sort(function(a,b){
					if(a.sort < b.sort) return -1;
					if(a.sort > b.sort) return 1;
					return 0;
				});
				setPage(await getPageData(item));
			}
			
			//タイトル設定
			window.document.title = '発注書_O' + item.quotation_no;

			//表示開始
			setLoad(false);
			
			//削除
			sessionStorage.removeItem('print');
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[true]);
	
	if(load){
		return (
			<div style={{ marginTop: 200, width: '100%', textAlign: 'center'}}>
			        <link rel="stylesheet" type="text/css" media="screen" href="/css/quotation/report_screen.css" />
			        <link rel="stylesheet" type="text/css" media="print" href="/css/quotation/report_print.css" />
				<img src="/img/loading.gif" alt="loading" />
			</div>
		);
	}
	else{
		return (
			<div>
			        <link rel="stylesheet" type="text/css" media="screen" href="/css/quotation/report_screen.css" />
			        <link rel="stylesheet" type="text/css" media="print" href="/css/quotation/report_print.css" />
				
				<form className="no_print" style={{ width: '100%', textAlign: 'center', marginTop: 50, marginBottom: 20}}>
					<input type="button" value={'見積書一覧に戻る'} onClick={()=>{ window.location.href = '/quotation/'; }} />
					　
					<input type="button" value={quotation_no + 'の詳細画面に戻る'} onClick={()=>{ window.location.href = '/quotation/' + quotation_no + '/'; }} />
				</form>
				
	                    	{page.map(data => { return(<Page data={data} />); })}
			</div>
		);
	}
};

const Page = (props) =>{
	
	const data = props.data;
	
	if(data.empty_page){
		return(
			<div className="content">
				<div className="page">
					　
				</div>
			</div>
		);
	}
	else{
		return (
			<div className="content">

				<div className="page">
			                
					<p className="quotation_head" style={{display: data.page === 1 ? '' : 'none' }}>注　文　書</p>

			                <table className="quotation_separate" style={{display: data.page === 1 ? '' : 'none' }}>
			                    <tbody>
			                    <tr>
			                        <td className="cell">
							<p className="quotation_head2">株式会社オノコム　宛</p>
							<p>下記のとおり注文致します。</p>

			                            <table className="quotation_info_left">
			                            <tbody>
			                            <tr>
			                                <th className="text-center">注文対象</th>
			                                <td>　　{data.quotation_no}</td>
			                            </tr>
			                            <tr>
			                                <th className="text-center">件名</th>
			                                <td>　　{data.title}</td>
			                            </tr>
			                            <tr>
			                                <th className="text-center">見積有効期</th>
			                                <td>　　別途取り決めによる</td>
			                            </tr>
			                            </tbody>
			                            </table>

			                        </td>
			                        <td className="space"></td>
			                        <td className="cell">
							<p className="text-right">（No.O{data.quotation_no}）</p>
							<p className="publisher" style={{borderColor:'silver', borderStyle:'dotted', borderWidth:'2px', height:'120px', width:'300px', color:'silver'}}>
								　<span style={{color:'black', textDecorationLine:'underline'}}>発注日　　　　　　年　　　　月　　　　日</span><br/>
								<br/>
								　（お客様名のご記入及びご捺印をお願いします。）
							</p>
			                        </td>
			                    </tr>
			                    </tbody>
			                </table>

					<div style={{ width: '100%', textAlign: 'right', display: data.page !== 1 ? '' : 'none' }}>
						（No.O{data.quotation_no}）
					</div>

					<h2>注文内容</h2>

			                <table className="t1" cellSpacing="0">
			                    <col width="5%" />
			                    <col width="50%" />
			                    <col width="5%" />
			                    <col width="10%" />
			                    <col width="10%" />
			                    <col width="10%" />
			                    <col width="10%" />
			                    <thead>
			                        <tr>
			                            <th rowSpan="2" className="text-center">項番</th>
			                            <th rowSpan="2" className="text-center">項目</th>
			                            <th rowSpan="2" className="text-center">数量</th>
			                            <th colSpan="2" className="text-center">初期費用</th>
			                            <th colSpan="2" className="text-center">月額費用</th>
			                        </tr>
			                        <tr>
			                            <th className="text-center">単価</th>
			                            <th className="text-center">金額</th>
			                            <th className="text-center">単価</th>
			                            <th className="text-center">金額</th>
			                        </tr>
			                    </thead>
			                    <tbody>
			                    	{data.details.map(item => {
			                    		return(
				                    	    <tr key={item.sort}>
				                                <td className="text-left">　{item.sort}</td>
				                                <td className="text-left">　{item.details}</td>
				                                <td className="text-right">{item.piece ? item.piece.toLocaleString() + ' ' + item.piece_type : ''}</td>
				                                <td className="text-right">{item.piece ? item.i_price_notax.toLocaleString() : ''}</td>
				                                <td className="text-right">{item.piece ? item.i_sub_price_notax.toLocaleString() : ''}</td>
				                                <td className="text-right">{item.piece ? item.m_price_notax.toLocaleString() : ''}</td>
				                                <td className="text-right">{item.piece ? item.m_sub_price_notax.toLocaleString() : ''}</td>
				                            </tr>
			                    		);
			                    	
			                    	})}
			                    </tbody>
			                </table>

			                <table className="t2" cellSpacing="0" style={{display: data.page === data.max_page ? '' : 'none' }}>
			                    <col width="55%" />
			                    <col width="3%" />
			                    <col width="6%" />
			                    <col width="6%" />
			                    <col width="10%" />
			                    <col width="10%" />
			                    <col width="10%" />
			                    <tbody>
			                        <tr>
						    <td rowSpan="3" className="memo">
							　備　考<br/>
							{data.biko_text}
						    </td>
			                            <td rowSpan="3" className="space">　</td>
			                            <td className="sum">小計</td>
			                            <td className="sum">　</td>
			                            <td className="price">{data.i_total_price_notax.toLocaleString()} 円 </td>
			                            <td className="sum">　</td>
			                            <td className="price">{data.m_total_price_notax.toLocaleString()} 円 </td>
			                        </tr>
			                        <tr>
			                            <td className="sum">消費税</td>
			                            <td className="sum">　</td>
			                            <td className="price">{data.i_total_tax.toLocaleString()} 円 </td>
			                            <td className="sum">　</td>
			                            <td className="price">{data.m_total_tax.toLocaleString()} 円 </td>
			                        </tr>
			                        <tr>
			                            <td className="sum">合計</td>
			                            <td className="sum">　</td>
			                            <td className="price">{data.i_total_price.toLocaleString()} 円 </td>
			                            <td className="sum">　</td>
			                            <td className="price">{data.m_total_price.toLocaleString()} 円 </td>
			                        </tr>
			                    </tbody>
			                </table>
			                
			                <table className="quotation_separate">
			                    <tbody>
			                    <tr>
			                        <td className="text-center">{data.page} / { data.max_page} ページ</td>
			                    </tr>
		                            </tbody>
			                </table>
			                
		        	        <table className="quotation_separate"  style={{display: (data.page < data.max_page) && ( ( data.page % 2 ) !== 0 )  ? '' : 'none' }}>
			                    <tbody>
			                    <tr>
			                        <td className="text-right">（内容に続きがございます。裏面もご覧ください。）</td>
			                    </tr>
		                            </tbody>
			                </table>
			        </div>
			</div>
		);
	}

};
export default withRouter(Default);
