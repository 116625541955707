import { useState, useEffect  } from 'react';
import { Auth } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Card,
	Button,
	Badge,
} from 'react-bootstrap';
import { ModalAlert } from '../../Objects/Modal';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const Default = () => {

	const history = useHistory();
	const { case_id } = useParams();
	const [alert, setAlert] = useState([]);
	const [load, setLoad] = useState(true);
	const [data, setData] = useState({});
	const [comment, setComment] = useState([]);
	const [reload, setReload] = useState(null);
	const [supportUserId, setSupportUserId] = useState('');
	const [supportUserName, setSupportUserName] = useState('');
	const [supportPersonId, setSupportPersonId] = useState('');
	const [supportPersonName, setSupportPersonName] = useState('');
	const [buildStatus, setBuildStatus] = useState('');
	const [visibleCount, setVisibleCount] = useState(3);
	const [latestComment, setLatestComment] = useState({});
	const [showCleanCopy, setShowCleanCopy] = useState(false);
	const [commentText, setCommentText] = useState('');
	const [cleanCopyText, setCleanCopyText] = useState('');

	useEffect(() => {
	
		const getData = async () => {
			
			const resultAWSSupport = await API.AWSSupport.Get(case_id);
			const itemAWSSupport   = resultAWSSupport.item;

			if(Object.keys(itemAWSSupport).length === 0){
				setAlert(['ケースが見つかりません']);
				setLoad(false);
				return;
			}

			const resultAWSSupportComment = await API.AWSSupport.CommentGetAll(case_id);
			const itemAWSSupportComment  = resultAWSSupportComment.items;

			//ケースについているコメントを追加
			itemAWSSupportComment.push(
				{
					"case_id": case_id,
					"date": itemAWSSupport.date_add,
					"user_name": itemAWSSupport.user_name,
					"account_no": itemAWSSupport.account_no,
					"customer_no": itemAWSSupport.customer_no,
					"text_value": itemAWSSupport.text_value,
					"file1_original_name": itemAWSSupport.file1_original_name,
					"file2_original_name": itemAWSSupport.file2_original_name,
					"file3_original_name": itemAWSSupport.file3_original_name,
					"file1_name": itemAWSSupport.file1_name,
					"file2_name": itemAWSSupport.file2_name,
					"file3_name": itemAWSSupport.file3_name,
				}
			);
			itemAWSSupportComment.sort((a, b) => new Date(b.date) - new Date(a.date));

			//返答用表示に1件取得
			const latestComment = itemAWSSupportComment.find(comment => !comment.onocomm_flag);
			
			//データセット
			setData(itemAWSSupport);
			setComment(itemAWSSupportComment);
			setBuildStatus(itemAWSSupport.build_status);
			setLatestComment(latestComment);

			//ログインユーザからサポートユーザを取得
			const supportUserId = (await Auth.currentAuthenticatedUser()).username;
			let supportUserName = '';
			if(supportUserId === 'a43733bd-9c04-44a2-b1f4-b7e32bdbd6ae'){
				supportUserName = '斧山　洋一';
			}
			else if(supportUserId === '56d02973-3285-444f-9fc9-c859021bd4e5'){
				supportUserName = '滝口　泰之';
			}
			else if(supportUserId === 'bc9a089c-2172-4096-b523-1b5e8e0bfe20'){
				supportUserName = '東方　昭洋';
			}
			if(supportUserId && supportUserName){
				setSupportUserId(supportUserId);
				setSupportUserName(supportUserName);
			}

			//主担当者を取得
			setSupportPersonId(itemAWSSupport.person_id);
			setSupportPersonName(itemAWSSupport.person_name);

			//表示開始
			setLoad(false);

			console.log(reload);
			
		};
		
		getData();
		
	},[case_id, reload]);

	const formatDate = (date) => {
		const dateObj      = new Date(Number(date) * 1000);
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second       = ('00' + dateObj.getSeconds().toString()).slice(-2);
		const dateText     = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
		return (dateText);
	};
	
	const handleSubmit = async (event) => {

		event.preventDefault();
		event.stopPropagation();

		setLoad(true);

		try{

			Storage.configure({ AWSS3: { bucket: 'onocomm-system-support-upload', region: 'ap-northeast-1', level: 'public' } });

			//account_noとcustomer_noはポータルサイトから取得するためにコメントをするユーザにかかわらず常にお客様の情報をセットする
			const account_no = data.account_no;
			const customer_no = data.customer_no;

			//コメントをするユーザの情報
			const user_name = supportUserName;
			const update_user = supportUserId;

			//主担当者の情報
			const person_name = supportPersonName ?? null;
			const person_id = supportPersonId ?? null;

			//コメント内容
			const text_value = showCleanCopy ? cleanCopyText : commentText;
			const file1Object = event.target?.file1?.files?.[0] ?? null;
			const file2Object = event.target?.file2?.files?.[0] ?? null;
			const file3Object = event.target?.file3?.files?.[0] ?? null;
			const build_status = buildStatus;

			if(data.status === 'クローズ'){
				throw new Error('ケースがクローズされているため、お問い合わせ出来ません');
			}

			if(!text_value){
				throw new Error('お問い合わせ内容が入力されていません');
			}

			if(!account_no){
				throw new Error('アカウント番号が取得できませんでした');
			}

			if(!customer_no){
				throw new Error('お客様番号が取得できませんでした');
			}

			if(!user_name){
				throw new Error('ユーザー名が取得できませんでした');
			}

			if(!update_user){
				throw new Error('ユーザIDが取得できませんでした');
			}

			let file1_original_name = null;
			let file1_name = null;
			if(file1Object){
				const file1Extension = file1Object.name.split('.').pop();
				const file1Size = file1Object.size;
				if(file1Size > 1024 * 1024 * 5){
					throw new Error('ファイルサイズが5MBを超えています');
				}
				file1_original_name = file1Object.name;
				file1_name = uuidv4() + '.' + file1Extension;
				await Storage.put(file1_name, file1Object);
			}
			let file2_original_name = null;
			let file2_name = null;
			if(file2Object){
				const file2Extension = file2Object.name.split('.').pop();
				const file2Size = file2Object.size;
				if(file2Size > 1024 * 1024 * 5){
					throw new Error('ファイルサイズが5MBを超えています');
				}
				file2_original_name = file2Object.name;
				file2_name = uuidv4() + '.' + file2Extension;
				await Storage.put(file2_name, file2Object);
			}
			let file3_original_name = null;
			let file3_name = null;
			if(file3Object){
				const file3Object = event.target.file3.files[0];
				const file3Extension = file3Object.name.split('.').pop();
				const file3Size = file3Object.size;
				if(file3Size > 1024 * 1024 * 5){
					throw new Error('ファイルサイズが5MBを超えています');
				}
				file3_original_name = file3Object.name;
				file3_name = uuidv4() + '.' + file3Extension;
				await Storage.put(file3_name, file3Object);
			}

			//ビルドのステータスが変更された場合は更新
			if(build_status){
				const updateParam = {
					case_id,
					build_status,
					person_name,
					person_id,
				};
				const updateResult = await API.AWSSupport.Update(updateParam);
				if(updateResult.error){
					throw new Error(updateResult.error);
				}
			}

			const params = {
				case_id,
				update_user,
				user_name,
				account_no,
				customer_no,
				text_value,
				file1_original_name,
				file1_name,
				file2_original_name,
				file2_name,
				file3_original_name,
				file3_name,
			};

			const result = await API.AWSSupport.CommentCreate(params);

			if(result.error){
				throw new Error(result.error);
			}

			const resultDate = result?.ref?.data?.createAWSSupportComment?.date;

			if(!resultDate){
				throw new Error('コメントの更新が取得できませんでした');
			}

			//クリア
			setCleanCopyText('');
			setCommentText('');
			setShowCleanCopy(false);

			//リロード
			setReload(resultDate);

		}
		catch(err){
			setAlert([err.message]);
		}

		setLoad(false);
	};

	const handleCleanCopy = async () => {

		if(!commentText){ return;}

		if(showCleanCopy){
			setShowCleanCopy(false);
			setCleanCopyText('');
		}
		else{
			setShowCleanCopy(true);

			const result = await API.AWSSupport.executeLambda(commentText);

			const cleanCopyText = result.message;
			setCleanCopyText(cleanCopyText);
		}

	};

	const handleClose = () => {
		(async () => {
			try{
				setLoad(true);

				if(data.build_status === '作業中'){
					throw new Error('ビルドが作業中のため、クローズできません');
				}

				const param = {
					case_id,
					status: 'クローズ',
				};
				const result = await API.AWSSupport.Update(param);
				if(result.error){
					throw new Error(result.error);
				}
				setTimeout(() => {
					setReload(Math.round((new Date()).getTime() / 1000));
					setLoad(false);
				}, 10000); // 10000ミリ秒（10秒）
			}
			catch(err){
				setLoad(false);
				setAlert([err.message]);
			}
		})();
	};

	const handleOnChange = async (event) => {
		const value = event.target.value;
		setBuildStatus(value);
	};

	const handleFileDownload = async (file_original_name, file_name) => {

		//console.log(file_original_name);
		//console.log(file_name);

		try {

			Storage.configure({ AWSS3: { bucket: 'onocomm-system-support-upload', region: 'ap-northeast-1', level: 'public' } });

			// S3からファイルをダウンロード
			const result = await Storage.get(file_name, { download: true });
			const blob = result.Body;

			// ブラウザでダウンロード
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = file_original_name;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

		} catch (error) {
			console.error('Error downloading file', error);
		}
	};
	
	const handleSetUserName = (event) => {
		const value = event.target.value;
		const array = value.split(':');
		const user_id = array[0];
		const user_name = array[1];
		setSupportUserId(user_id);
		setSupportUserName(user_name);
	};

	const handleSetPersonName = (event) => {
		const value = event.target.value;
		const array = value.split(':');
		const user_id = array[0];
		const user_name = array[1];
		setSupportPersonId(user_id);
		setSupportPersonName(user_name);
	};

	const urlify = (text) => {
		const urlPattern = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlPattern, (url) => {
			return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
		});
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={()=>setAlert([])} />
				<Row>
					<Col sm={8}>
						<h4>ケースID：{case_id}</h4>
					</Col>
					<Col sm={2} className="text-right">
						<Button variant="primary" onClick={() => navigator.clipboard.writeText('https://portal.onocomm.jp/support/case/' + data.account_no + '/' + case_id) }>URLコピーする</Button>
					</Col>
					<Col sm={2} className="text-right">
						<Button variant="secondary" onClick={handleClose} disabled={data.status !== 'クローズ' ? false : true}>クローズする</Button>
					</Col>
				</Row>
				<Row style={{ marginTop: 20}}>
					<Col sm={12}>
						<Card>
							<Card.Header>ケースの詳細</Card.Header>
							<Card.Body>
								<Card.Text>
									<Row>
										<Col sm={6} className="border-right">
											題名<br/>
											{data.subject}<br/>
											<br/>
											日付<br/>
											{formatDate(data.date_add)}<br/>
											<br/>
											アカウント<br/>
											{data.account_no}<br/>
											{(()=>{
												if(data.build_id){
													return (
														<>
															<br/>
															ビルドID<br/>
															{data.build_id}<br/>
															<br/>
															費用<br/>
															{
															data.build_off ?
															(
																data.build_price - Math.floor(data.build_price * (data.build_off / 100)))?.toLocaleString() :
																data.build_price?.toLocaleString()
															}円（税別） {data.off ? <><Badge variant="danger">{data.build_off}%OFF</Badge><br /></> : ''}
														<br/>
														</>
													)
												}
											})()}
										</Col>
										<Col sm={6}>
											ステータス<br/>
											{data.status}<br/>
											<br/>
											種別<br/>
											{data.type}<br/>
											<br/>
											お問合せされた方{data.onocomm_flag ? '（当社がケースをオープン）' :''}<br/>
											{data.user_name}<br/>
											<br/>
											主担当者<br/>
											{data.person_name}<br/>
											{(()=>{
												if(data.build_id){
													return (
														<>
															<br/>
															詳細<br/>
															{data.build_description}<br/>
															<br/>
															期間<br/>
															{data.build_period}日間{data.build_status ? '（' + data.build_status + '）' : ''}<br/>
														</>
													)
												}
											})()}
										</Col>
									</Row>
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				　
				{data.status !== 'クローズ' &&
					<Row>
						<Col sm={12}>
							<Card>
								<Card.Header>サポート対応</Card.Header>
								<Card.Body>
									<Card.Text>
										<Row>
											<Col sm={3} className="border-right">
												日付<br/>
												{formatDate(latestComment.date)}<br/>
												<br/>
												{latestComment.onocomm_flag ? '当社担当者' :'お問合せされた方'}<br/>
												{latestComment.user_name}<br/>
												<br/>
											</Col>
											<Col sm={9}>
												<pre
													style={{
														whiteSpace: 'pre-wrap',
														wordWrap: 'break-word'
													}} 
													dangerouslySetInnerHTML={{ __html: urlify(latestComment.text_value) }}
												/><br/>
												<br/>
												{latestComment.file1_original_name && (
												<>添付ファイル1：
													<button
													onClick={() => handleFileDownload(latestComment.file1_original_name, latestComment.file1_name)}
													style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
													>
													{latestComment.file1_original_name}
													</button><br/>
												</>
												)}
												{latestComment.file2_original_name && (
												<>添付ファイル2：
													<button
													onClick={() => handleFileDownload(latestComment.file2_original_name, latestComment.file2_name)}
													style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
													>
													{latestComment.file2_original_name}
													</button><br/>
												</>
												)}
												{latestComment.file3_original_name && (
												<>添付ファイル3：
													<button
													onClick={() => handleFileDownload(latestComment.file3_original_name, latestComment.file3_name)}
													style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
													>
													{latestComment.file3_original_name}
													</button><br/>
												</>
												)}
											</Col>
										</Row>
									</Card.Text>
									<hr/>
								</Card.Body>
								<Card.Body>
									<Form onSubmit={handleSubmit}>
										<Form.Group controlId="formTechnicalUser">
											<Form.Label>ユーザ名</Form.Label>
											<Form.Control as="select" name="user_name" onChange={handleSetUserName} value={supportUserId + ':' + supportUserName} required>
												<option value={''}>---</option>
												<option value={'a43733bd-9c04-44a2-b1f4-b7e32bdbd6ae:斧山　洋一'}>斧山　洋一</option>
												<option value={'56d02973-3285-444f-9fc9-c859021bd4e5:滝口　泰之'}>滝口　泰之</option>
												<option value={'bc9a089c-2172-4096-b523-1b5e8e0bfe20:東方　昭洋'}>東方　昭洋</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="formTechnicalValue">
											<Form.Label>お問い合わせ内容</Form.Label>
											<Form.Control as="textarea" name="text_value" rows={10} placeholder="お問い合わせ内容" value={commentText} onChange={(e)=>{setCommentText(e.target.value)}} required readOnly={showCleanCopy} />
										</Form.Group>
										<Form.Group controlId="formCleanCopy" className="text-center">
											<Button variant={commentText ? 'success' : 'secondary'} type="button" onClick={handleCleanCopy} disabled={commentText ? false : true}>{showCleanCopy ? '閉じる' : '生成AIで校正する'}</Button>
										</Form.Group>
										<Form.Group controlId="formCleanCopy" className="text-center">
											<Form.Control as="textarea" name="clean_copy" rows={10} value={cleanCopyText} onChange={(e)=>{setCleanCopyText(e.target.value)}} placeholder="校正中・・・" style={{ display: showCleanCopy ? 'block' : 'none' }} />
										</Form.Group>
										<Form.Group controlId="formTechnicalFile1">
											<Form.File name="file1" id="custom-file" label="ファイル1を添付" />
										</Form.Group>
										<Form.Group controlId="formTechnicalFile2">
											<Form.File name="file2" id="custom-file" label="ファイル2を添付" />
										</Form.Group>
										<Form.Group controlId="formTechnicalFile3">
											<Form.File name="file3" id="custom-file" label="ファイル3を添付" />
										</Form.Group>
										<Form.Group controlId="formBuildStatus" style={{ display: data.type !== 'build' ? 'none' : 'block' }}>
											<Form.Label>ビルドステータス</Form.Label>
											<Form.Control as="select" name="build_status" onChange={handleOnChange} value={buildStatus} required>
												<option value={'作業中'}>作業中</option>
												<option value={'作業完了'}>作業完了</option>
												<option value={'作業中止'}>作業中止</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="formTechnicalPerson" style={{ display: data.person_name ? 'none' : 'block' }}>
											<Form.Label>主担当者</Form.Label>
											<Form.Control as="select" name="person_name" onChange={handleSetPersonName} value={supportPersonId + ':' + supportPersonName} required>
												<option value={''}>---</option>
												<option value={'a43733bd-9c04-44a2-b1f4-b7e32bdbd6ae:斧山　洋一'}>斧山　洋一</option>
												<option value={'56d02973-3285-444f-9fc9-c859021bd4e5:滝口　泰之'}>滝口　泰之</option>
												<option value={'bc9a089c-2172-4096-b523-1b5e8e0bfe20:東方　昭洋'}>東方　昭洋</option>
											</Form.Control>
										</Form.Group>
										<Button variant="primary" type="submit">コメントを追加する</Button>
									</Form>

								</Card.Body>
							</Card>
						</Col>
					</Row>
				}
				　
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>やりとり</Card.Header>
							{comment.slice(0, visibleCount).map((item, index) => (
								<Card.Body key={index}>
									<Card.Text>
										<Row>
											<Col sm={3} className="border-right">
												日付<br/>
												{formatDate(item.date)}<br/>
												<br/>
												{item.onocomm_flag ? '当社担当者' :'お問合せされた方'}<br/>
												{item.user_name}<br/>
												<br/>
											</Col>
											<Col sm={9}>
												<pre
													style={{
														whiteSpace: 'pre-wrap',
														wordWrap: 'break-word'
													}} 
													dangerouslySetInnerHTML={{ __html: urlify(item.text_value) }}
												/><br/>
												<br/>
												{item.file1_original_name && (
												<>添付ファイル1：
													<button
													onClick={() => handleFileDownload(item.file1_original_name, item.file1_name)}
													style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
													>
													{item.file1_original_name}
													</button><br/>
												</>
												)}
												{item.file2_original_name && (
												<>添付ファイル2：
													<button
													onClick={() => handleFileDownload(item.file2_original_name, item.file2_name)}
													style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
													>
													{item.file2_original_name}
													</button><br/>
												</>
												)}
												{item.file3_original_name && (
												<>添付ファイル3：
													<button
													onClick={() => handleFileDownload(item.file3_original_name, item.file3_name)}
													style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none', padding: 0 }}
													>
													{item.file3_original_name}
													</button><br/>
												</>
												)}
											</Col>
										</Row>
									</Card.Text>
									<hr/>
								</Card.Body>
							))}
							{visibleCount < comment.length && (
								<div className="text-center mb-3">
									<Button onClick={()=>{setVisibleCount(prevCount => prevCount + 3)}} variant="primary">さらに読み込む</Button>
								</div>
							)}
						</Card>
					</Col>
				</Row>
				　
				<Row style={{ marginTop: 10 }}>
					<Col sm={{ span: 6, offset: 3 }}>
							<Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >Back</Button>
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
