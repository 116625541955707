import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect, FormButton } from '../../Objects/Form';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';
import { v4 as uuidv4 } from 'uuid';


const Default = () => {

	const history = useHistory();
	const { storage_id } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [customer, setCustomer] = useState([]);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};
			
			if(storage_id){
				//データ取得
				newData = (await API.Storage.Get(storage_id)).item;
				console.log(newData);
			}
			else{
				newData.storage_id=uuidv4();
				newData.customer_no='';
				newData.customer_name='';
				newData.title='AWSサポート用ファイルストレージ';
				newData.contract_date=Math.floor((new Date()).getTime() / 1000);
				newData.contract_months=1;
				newData.file_size_limit=100;
				newData.file_num_limit=10000;
				newData.price_notax=0;
				newData.bill_start= covertTime('2007-01');
				newData.bill_cycle='掛売';
				newData.status='有効';
			}
			setData(newData);
			setCustomer((await API.Customer.GetAll()).items);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			if(storage_id){
				//更新
				const ref = await API.Storage.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				//更新
				const ref = await API.Storage.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(storage_id){
				//削除
				const ref = await API.Storage.Delete(storage_id);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};
	
	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setShowCustomer([false]);
		setRefresh([true]);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.details){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.details = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/storage/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/storage/' + data.storage_id;
		}
		else{
			setReload(reload+1);
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'契約ID'} name={'storage_id'} value={data.storage_id} readOnly={true} onChange={handleOnChange} />
					<hr/>
					<FormButton label={'対象顧客'} name={'customer_no'} value={data.customer_no ? (data.customer_no + '　' + data.customer_name) : '未選択'} onClick={handleCustomerShow} required={true} />
					<FormText label={'契約日'} name={'contract_date'} value={dateShapingDate(data.contract_date)} type={'date'} onChange={handleOnChange} required={true} />
					<FormText label={'プラン名'} name={'title'} value={data.title} onChange={handleOnChange} required={true} />
					<FormText label={'容量（GB）'} name={'file_size_limit'} value={data.file_size_limit} type={'number'} onChange={handleOnChange} required={true} />
					<FormText label={'ファイル数'} name={'file_num_limit'} value={data.file_num_limit} type={'number'} onChange={handleOnChange} required={true} />
					<FormText label={'月額費用'} name={'price_notax'} value={data.price_notax} type={'number'} onChange={handleOnChange} required={true} />
					<FormText label={'契約単位（月）'} name={'contract_months'} value={data.contract_months} type={'number'} onChange={handleOnChange} required={true} />
					<Row>
						<Form.Label column sm={3}>
							請求月
						</Form.Label>
						<Col sm={9}>
							<input type="checkbox" name="bill_month01" checked={data.bill_month01 ? true : false} onChange={handleOnChange} /> 1月　
							<input type="checkbox" name="bill_month02" checked={data.bill_month02 ? true : false} onChange={handleOnChange} /> 2月　
							<input type="checkbox" name="bill_month03" checked={data.bill_month03 ? true : false} onChange={handleOnChange} /> 3月　
							<input type="checkbox" name="bill_month04" checked={data.bill_month04 ? true : false} onChange={handleOnChange} /> 4月　
							<input type="checkbox" name="bill_month05" checked={data.bill_month05 ? true : false} onChange={handleOnChange} /> 5月　
							<input type="checkbox" name="bill_month06" checked={data.bill_month06 ? true : false} onChange={handleOnChange} /> 6月　
							<input type="checkbox" name="bill_month07" checked={data.bill_month07 ? true : false} onChange={handleOnChange} /> 7月　
							<input type="checkbox" name="bill_month08" checked={data.bill_month08 ? true : false} onChange={handleOnChange} /> 8月　
							<input type="checkbox" name="bill_month09" checked={data.bill_month09 ? true : false} onChange={handleOnChange} /> 9月　
							<input type="checkbox" name="bill_month10" checked={data.bill_month10 ? true : false} onChange={handleOnChange} /> 10月　
							<input type="checkbox" name="bill_month11" checked={data.bill_month11 ? true : false} onChange={handleOnChange} /> 11月　
							<input type="checkbox" name="bill_month12" checked={data.bill_month12 ? true : false} onChange={handleOnChange} /> 12月
						</Col>
					</Row>
					<FormSelect label={'請求サイクル'} name={'bill_cycle'} value={data.bill_cycle} data={ [ '掛売', '当月', '先貰' ] } onChange={handleOnChange} required={true} />
					<FormText label={'メモ'} name={'memo_text'} value={data.memo_text} onChange={handleOnChange} />
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} value={dateShapingMonth(data.bill_start)} type={'month'} onChange={handleOnChange} required={true} />
					<FormText label={'課金停止月'} name={'bill_end'} value={dateShapingMonth(data.bill_end)} type={'month'} onChange={handleOnChange} />
					<hr/>
					<FormSelect label={'ステータス'} name={'status'} value={data.status} data={ [ '有効', '無効' ] } onChange={handleOnChange} required={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>

				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block disabled={lock || storage_id ? false : true} >Delete</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
