import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormTextArea, FormSelect, FormButton } from '../../Objects/Form';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const { ssl_name } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState([]);
	const [customer, setCustomer] = useState([]);
	const [server_no, setServerNO] = useState(null);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [showOV, setShowOV] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {

			const getServerNO = async (server_name) => {
				let server_no = null;
				const data = (await API.Server.GetAll()).items;
				if(data.filter(x => x.server_name === server_name).length > 1){
					return(null);
				}
				for(const item of data){
					if(item.server_name === server_name){
						server_no = item.server_no;
						break; 
					}
				}
				return(server_no);
			};
			
			let newData = {};
			
			if(ssl_name){
				//データ取得
				newData = (await API.SSL.Get(ssl_name)).item;
			}
			else{
				newData.customer_no = '';
				newData.customer_name = '';
				newData.status = '新規取得';
				newData.expiration = Math.floor(( (new Date( (new Date()).getFullYear(), (new Date()).getMonth() + 1, 0) ).getTime() / 1000) + (60 * 60 * 24 *365));
				newData.contract_years = '1';
				newData.bill_start= covertTime('2007-01');
			}
			//console.log(newData.is_get_key);

			//サーバーNOの取得
			if(newData.target_server){
				setServerNO(await getServerNO(newData.target_server));
			}

			setData(newData);
			setCustomer((await API.Customer.GetAll()).items);
			
			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
		setShowOV([false]);
	};
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);

		console.log(data);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			if(ssl_name){
				//更新
				const ref = await API.SSL.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				//新規
				const ref = await API.SSL.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(ssl_name){
				//削除
				const ref = await API.SSL.Delete(ssl_name);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};
	
	const handleOVShow = () => {
		setShowOV([true]);
	};
	
	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = { ...data };
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setShowCustomer([false]);
		setRefresh([true]);
	};
	
	const handlePriceUpdate = () => {
		const newData = { ...data };
		if(data.ssl_kbn === 'JPRS DV'){
			newData.price_notax = 12000;
		}
		else if(data.ssl_kbn === 'JPRS DV ワイルドカード'){
			newData.price_notax = 24000;
		}
		else if(data.ssl_kbn === 'JPRS OV'){
			newData.price_notax = 44000;
		}
		else if(data.ssl_kbn === 'JPRS OV ワイルドカード'){
			newData.price_notax = 88000;
		}
		else if(data.ssl_kbn === 'ベリサイン'){
			newData.price_notax = 34800;
		}
		else{
			newData.price_notax = 0;
		}
		setData(newData);
		setRefresh([true]);
	};
	
	const handleDateUpdate1 = () => {
		const newData = { ...data };
		const dateObj = new Date(data.expiration * 1000);
		newData.expiration = Math.floor(new Date( dateObj.getFullYear() + 1, dateObj.getMonth() + 1, 0) / 1000);
		setData(newData);
		setRefresh([true]);
	};
	
	const handleDateUpdate2 = () => {
		const newData = { ...data };
		const dateObj = new Date(data.expiration * 1000);
		newData.billing_date = Math.floor(new Date( dateObj.getFullYear(), dateObj.getMonth() - (dateObj.getDate < 25 ? 3 : 2), 1) / 1000);
		newData.setup_date = Math.floor(new Date( dateObj.getFullYear(), dateObj.getMonth(), 10) / 1000);
		setData(newData);
		setRefresh([true]);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が日付かどうか
		if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}

		console.log(name);
		console.log(value);
		console.log(newData);
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleOnDonwloadFlag = async (event) => {

		const newData = JSON.parse(JSON.stringify(data));

		try{

			//値
			newData.is_get_key = null;

			//更新
			//console.log(newData);
			const ref = await API.SSL.Update(newData);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。' + ref.error);
			}
			
			throw new Error('有効にしました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//更新
		setData(newData);
		setRefresh([true]);
		
	};
	
	const handleClose = () => {
		setAlert([]);
		setShowOV([false]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/ssl/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/ssl/' + data.ssl_name;
		}
		else{
			//setReload(reload+1);
		}
	};

	const handleMoveBill = () => {
		sessionStorage.setItem('ssl_details', JSON.stringify(data));
		window.location.href='/bill-once/new/';
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />
				<ModalOV data={data} show={showOV} onClick={handleClose} onChange={handleOnChange} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={1}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={1}>
							<Button variant="dark" type="button" block onClick={()=>{window.open('https://web.apply.pubcert.jprs.jp/', '_blank');}} >JPRS</Button>
						</Col>
						<Col sm={1}>
							<Button variant="dark" type="button" block onClick={()=>{window.open('https://jprs.jp/pubcert/service/certificate/', '_blank');}} >CA</Button>
						</Col>
						<Col sm={9} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'コモン名'} name={'ssl_name'} value={data.ssl_name} onChange={handleOnChange} readOnly={ssl_name ? true : false} required={ssl_name ? false : true} copy={true} />
					<hr/>
					<FormButton label={'対象顧客'} name={'customer_no'} value={data.customer_no ? (data.customer_no + '　' + data.customer_name) : '未選択'} onClick={handleCustomerShow} onChange={handleOnChange} required={true} />
					<FormSelect label={'登録区分'} name={'status'} value={data.status} data={ [ '対象外', '更新対象' ] } onChange={handleOnChange} required={true} />
					<FormSelect label={'区分'} name={'ssl_kbn'} value={data.ssl_kbn} data={ [ 'JPRS DV', 'JPRS DV ワイルドカード', 'JPRS OV', 'JPRS OV ワイルドカード', 'ベリサイン', 'ジオトラスト' ] } onChange={handleOnChange} required={true} />
					<hr/>
					<FormText label={'更新価格'} name={'price_notax'} value={data.price_notax} type={'number'} onChange={handleOnChange} required={true} />
					<FormButton label={''} name={''} value={'価格取得'} onClick={handlePriceUpdate} />
					<hr/>
					<FormText label={'契約年数'} name={'contract_years'} value={data.contract_years} type={'number'} onChange={handleOnChange} required={true} />
					<hr/>
					<FormText label={'有効期限'} name={'expiration'} value={dateShapingDate(data.expiration)} type={'date'} onChange={handleOnChange} required={true} />
					<FormButton label={''} name={''} value={'日付設定'} onClick={handleDateUpdate1} />
					<hr/>
					<FormText label={'請求月'} name={'billing_date'} value={dateShapingMonth(data.billing_date)} type={'month'} onChange={handleOnChange} required={true} />
					<FormText label={'設定希望日'} name={'setup_date'} value={dateShapingDate(data.setup_date)} type={'date'} onChange={handleOnChange} />
					<FormButton label={''} name={''} value={'日付設定'} onClick={handleDateUpdate2} />
					<hr/>
					<FormButton label={'ダウンロード'} name={'is_get_key'} value={data.is_get_key ? '有効にする' : '有効になっています'} onClick={handleOnDonwloadFlag} disabled={data.is_get_key ? false : true} />
					<hr/>
					<FormButton label={'OV登録情報'} name={'ov'} value={data.ov_org_name_jpn ? data.ov_org_name_jpn : '（Empty）'} onClick={handleOVShow} />
					<hr/>
					<FormSelect label={'ダブルドメイン'} name={'double_domain_type'} value={data.double_domain_type} data={ [ '有効', '無効' ] } onChange={handleOnChange} />
					<FormSelect label={'承認方法'} name={'approval_type'} value={data.approval_type} data={ [ 'ファイル認証', 'メール認証' ] } onChange={handleOnChange} />
					<FormText label={'承認メールアドレス'} name={'approval_mail'} value={data.approval_mail} type={'mail'} onChange={handleOnChange} readOnly={data.approval_type === 'ファイル認証' ? true : false} copy={true} />
					<FormText label={'承認ファイル'} name={'approval_file'} value={data.approval_file} onChange={handleOnChange} readOnly={data.approval_type === 'ファイル認証' ? false : true} copy={true} />
					<FormText label={'承認テキスト'} name={'approval_text'} value={data.approval_text} onChange={handleOnChange} readOnly={data.approval_type === 'ファイル認証' ? false : true} copy={true} />
					<FormSelect label={'引渡方法'} name={'target_type'} value={data.target_type} data={ [ 'オノコムサーバー内設置', 'ファイル引渡', 'その他' ] } onChange={handleOnChange} />
					<FormText label={'設定先サーバーホスト名'} name={'target_server'} value={data.target_server} onChange={handleOnChange} copy={true} />
					<FormButton label={''} name={'server_no'} value={'サーバー接続へ移動'} onClick={()=>{window.open('/server/' + server_no + '/access/', '_blank');}} disabled={server_no ? false : true} />
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} value={dateShapingMonth(data.bill_start)} type={'month'} onChange={handleOnChange} required={true} />
					<FormText label={'課金停止月'} name={'bill_end'} value={dateShapingMonth(data.bill_end)} type={'month'} onChange={handleOnChange} />
					<hr/>
					<FormTextArea label={'設定情報'} name={'memo_text'} value={data.memo_text} onChange={handleOnChange} rows={10} />
					<hr/>
					<FormButton label={'証明書'} name={'details'} value={'証明書へ移動'} onClick={()=>{window.location.href='/ssl/' + data.ssl_name + '/keys/'}} />
					<hr/>
					<FormButton label={'課金登録'} name={'button'} value={'初期費用登録へ移動'} onClick={handleMoveBill} disabled={ssl_name ? false : true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>

					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>

				<Form onSubmit={handleDelete}>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="submit" block disabled={lock || ssl_name ? false : true} >Delete</Button>
						</Col>
					</Row>
				</Form>


			</Container>
		);
	}
};

const ModalOV = (props) => {

	const [show, setShow] = useState(false);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);

	return (
		<Modal show={show} onHide={props.onClick} size="lg">
			<Modal.Body>
				<Form>
					<FormSelect label={'組織種別'} name={'ov_org_kbn'} value={props.data.ov_org_kbn} data={ [ '登記により設立される法人・組織', '認可により設立される法人・組織', '教育機関', '日本国政府機関', '地方公共団体（またはその下部組織）', '任意団体（法人により構成された団体）', '個人事業主', 'その他' ] } onChange={props.onChange} />
					<FormText label={'組織名（日本語）'} name={'ov_org_name_jpn'} value={props.data.ov_org_name_jpn} onChange={props.onChange} />
					<FormText label={'部署名（日本語）'} name={'ov_org_unit_jpn'} value={props.data.ov_org_unit_jpn} onChange={props.onChange} />
					<hr/>
					<FormText label={'担当者名（姓）（日本語）'} name={'ov_person_name_1_jpn'} value={props.data.ov_person_name_1_jpn} onChange={props.onChange} />
					<FormText label={'担当者名（名）（日本語）'} name={'ov_person_name_2_jpn'} value={props.data.ov_person_name_2_jpn} onChange={props.onChange} />
					<FormText label={'担当者名（姓）（英語）'} name={'ov_person_name_1_eng'} value={props.data.ov_person_name_1_eng} onChange={props.onChange} />
					<FormText label={'担当者名（名）（英語）'} name={'ov_person_name_2_eng'} value={props.data.ov_person_name_2_eng} onChange={props.onChange} />
					<hr/>
					<FormText label={'郵便番号'} name={'ov_postcode'} value={props.data.ov_postcode} type={'zip'} onChange={props.onChange} />
					<FormText label={'住所1（都道府県）（日本語）'} name={'ov_addr_1_jpn'} value={props.data.ov_addr_1_jpn} onChange={props.onChange} />
					<FormText label={'住所2（市区町村）（日本語）'} name={'ov_addr_2_jpn'} value={props.data.ov_addr_2_jpn} onChange={props.onChange} />
					<FormText label={'住所3（番地、地名）（日本語）'} name={'ov_addr_3_jpn'} value={props.data.ov_addr_3_jpn} onChange={props.onChange} />
					<FormText label={'住所4（ビル名）（日本語）'} name={'ov_addr_4_jpn'} value={props.data.ov_addr_4_jpn} onChange={props.onChange} />
					<hr/>
					<FormText label={'住所1（都道府県）（英語）'} name={'ov_addr_1_eng'} value={props.data.ov_addr_1_eng} onChange={props.onChange} />
					<FormText label={'住所2（市区町村）（英語）'} name={'ov_addr_2_eng'} value={props.data.ov_addr_2_eng} onChange={props.onChange} />
					<FormText label={'住所3（番地、地名）（英語）'} name={'ov_addr_3_eng'} value={props.data.ov_addr_3_eng} onChange={props.onChange} />
					<FormText label={'住所4（ビル名）（英語）'} name={'ov_addr_4_eng'} value={props.data.ov_addr_4_eng} onChange={props.onChange} />
					<hr/>
					<FormText label={'電話番号'} name={'ov_tel'} value={props.data.ov_tel} type={'tel'} onChange={props.onChange} />
					<FormText label={'FAX番号'} name={'ov_fax'} value={props.data.ov_fax} type={'tel'} onChange={props.onChange} />
					<hr/>
					<FormText label={'都道府県（英語、CSR情報）'} name={'ov_state'} value={props.data.ov_state} onChange={props.onChange} />
					<FormText label={'市区町村（英語、CSR情報）'} name={'ov_locality'} value={props.data.ov_locality} onChange={props.onChange} />
					<FormText label={'組織名称（英語、CSR情報）'} name={'ov_org_name'} value={props.data.ov_org_name} onChange={props.onChange} />
					<FormText label={'部署名（英語、CSR情報）'} name={'ov_org_unit'} value={props.data.ov_org_unit} onChange={props.onChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} >OK</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(Default);
