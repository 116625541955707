import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect } from '../../Objects/Form';
import { ModalAlert } from '../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const { customer_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};
			
			if(customer_no){
				newData = (await API.Customer.Get(customer_no)).item;
			}
			
			//データ取得
			setData(newData);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleCopy = (params) => {
	
		const newData = JSON.parse(JSON.stringify(data));	
		for(const param of params){
			newData[param.dst] = data[param.src];
		}
		setData(newData);
		setRefresh([true]);
	};

	const handleSubmit = async (event) => {
		
		//顧客番号の生成
		const generate = async () => {
		
			try{
			
				//チェックデジットの計算
				const checkDigit = async (jan) => {
				
					// 逆順にする
					const getReverse = async (req) => {
						return(req.split("").reverse().join(""));
					};

					// 偶数列、奇数列をそれぞれ足し合わせて返す
					const getTotal = async (req) => {
						const arr = req.split("");
						const evenList = [];
						const oddList = [];
						const len = arr.length;
						
						let lim = 0;
						for (let i = 0; i < len; i++) {
							lim++;
							const num = arr[i];
							if (i % 2 !== 0) {
								evenList.push(Number(num));
							}
							else {
								oddList.push(Number(num));
							}
							if (lim === len) {
								const eve = evenList.reduce(function(a, b){ return a + b });
								const odd = oddList.reduce(function(a, b){ return a + b });
								return([eve, odd * 3]);
							}
						}
					};

					// 偶数の合計と奇数の合計を足した末尾を使ってチェックデジットを返す
					const getDigit = async (sumEven, sumOdd) =>{
						const sum = sumEven + sumOdd;
						const end = Number(String(sum).slice(-1));
						if (end === 0) {
							return('0');
						}
						else {
							const req = 10 - end;
							return(req.toString());
						}
					};
					
					const req = await getReverse(jan);
					
					const [sumEven, sumOdd] = await getTotal(req);
					
					const digit = await getDigit(sumEven, sumOdd);
					
					return(jan + digit);

				};
			
				//日付の整形
				const dateObj     = new Date();
				const year        = dateObj.getFullYear().toString().substr(2,2);
				const month       = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
				
				//件数の取得
				const ref = await API.Customer.GetAll();
				
				//データのソート
				ref.items.sort(
					(a, b) => {
						if (a.customer_id > b.customer_id) return -1;
						if (a.customer_id < b.customer_id) return 1;
						return 0;
					}
				);
				
				//customer_idの取得
				const customer_id = ('0000' + (Number(ref.items[0].customer_id) + 1).toString()).slice(-4);
				
				const number = year + month + customer_id;

				const di = await checkDigit(number);
				
				const customer_no = 'N' + di;
				
				const checkno = ref.items.filter(item => item.customer_no === customer_no).length;
				
				console.log(checkno);
				
				if(checkno === 0){
					return([customer_no, customer_id]);
				}
				else {
					return(['', 0]);
				}
			}
			catch(err){
				console.log(err);
			}
		
		};
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {

			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(customer_no){
			
				//更新
				const ref = await API.Customer.Update(data);

				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{

				//顧客番号の取得
				const [customer_no, customer_id] = await generate();
				
				if(customer_no === ''){
					throw new Error('顧客番号の生成に失敗しました。');
				}

				const newData = JSON.parse(JSON.stringify(data));
				newData.customer_no = customer_no;
				newData.customer_id = customer_id;
				
				//新規
				const ref = await API.Customer.Create(newData);

				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
		
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(customer_no){
				//削除
				const ref = await API.Customer.Delete(customer_no);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.details){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.details = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/customer/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/customer/' + data.customer_no;
		}
		else{
			setReload(reload+1);
		}
	};
	
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Form onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'登録日時'} name={'contract_date'} defaultValue={customer_no ? dateShapingDate(data.date_add) : '未登録'} readOnly={true} type={customer_no ? 'date' : 'text'} />
					<FormText label={'顧客番号'} name={'customer_no'} defaultValue={customer_no ? customer_no : '未登録'} readOnly={true} />
					<hr/>
					<FormSelect label={'登録区分'} name={'contract_kbn'} value={data.contract_kbn} data={ [ '法人', '個人'] } onChange={handleOnChange} />
					<FormText label={'顧客名'} name={'customer_name'} value={data.customer_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'customer_name_furi'} value={data.customer_name_furi} onChange={handleOnChange} required={true} />
					<FormText label={'英語'} name={'customer_name_en'} value={data.customer_name_en} onChange={handleOnChange} />
					<hr/>
					<FormText label={'代表者名'} name={'contractor_name'} value={data.contractor_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'contractor_name_furi'} value={data.contractor_name_furi} onChange={handleOnChange} />
					<hr/>
					<FormText label={'電話番号'} name={'contractor_tel'} value={data.contractor_tel} type={'tel'} onChange={handleOnChange} required={true} />
					<hr/>
					<FormText label={'郵便番号'} name={'contractor_zip'} value={data.contractor_zip} onChange={handleOnChange} required={true} />
					<FormText label={'住所1'} name={'contractor_addr_1'} value={data.contractor_addr_1} onChange={handleOnChange} required={true} />
					<FormText label={'住所2'} name={'contractor_addr_2'} value={data.contractor_addr_2} onChange={handleOnChange} required={true} />
					<FormText label={'住所3'} name={'contractor_addr_3'} value={data.contractor_addr_3} onChange={handleOnChange} />
					<FormText label={'住所1（英語）'} name={'contractor_addr_en_1'} value={data.contractor_addr_en_1} onChange={handleOnChange} />
					<FormText label={'住所2（英語）'} name={'contractor_addr_en_2'} value={data.contractor_addr_en_2} onChange={handleOnChange} />
					<FormText label={'住所3（英語）'} name={'contractor_addr_en_3'} value={data.contractor_addr_en_3} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>契約担当者情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'contractor_name', dst:'person_name'},
																{src:'contractor_name_furi', dst:'person_name_furi'},
																{src:'contractor_tel', dst:'person_tel'},
															])} >代表情報 &gt; 契約担当者 Copy</Button>
						</Col>
					</Row>
					<FormText label={'部署名'} name={'person_section'} value={data.person_section} onChange={handleOnChange} />
					<FormText label={'担当者名'} name={'person_name'} value={data.person_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'person_name_furi'} value={data.person_name_furi} onChange={handleOnChange} />
					<FormText label={'電話番号'} name={'person_tel'} value={data.person_tel} type={'tel'} onChange={handleOnChange} required={true} />
					<FormText label={'FAX'} name={'person_fax'} value={data.person_fax} type={'tel'} onChange={handleOnChange} />
					<FormText label={'Eメール'} name={'person_mail'} value={data.person_mail} type={'email'} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>技術担当者情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'person_section', dst:'tech_section'},
																{src:'person_name', dst:'tech_name'},
																{src:'person_name_furi', dst:'tech_name_furi'},
																{src:'person_tel', dst:'tech_tel'},
																{src:'person_fax', dst:'tech_fax'},
																{src:'person_mail', dst:'tech_mail'},
															])} >契約担当者 &gt; 技術担当者 Copy</Button>
						</Col>
					</Row>
					<FormText label={'部署名'} name={'tech_section'} value={data.tech_section} onChange={handleOnChange}  />
					<FormText label={'担当者名'} name={'tech_name'} value={data.tech_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'tech_name_furi'} value={data.tech_name_furi} onChange={handleOnChange} />
					<FormText label={'電話番号'} name={'tech_tel'} value={data.tech_tel} type={'tel'} onChange={handleOnChange} required={true} />
					<FormText label={'FAX'} name={'tech_fax'} value={data.tech_fax} type={'tel'} onChange={handleOnChange} />
					<FormText label={'Eメール'} name={'tech_mail'} value={data.tech_mail} type={'email'} onChange={handleOnChange} />
					<FormText label={'緊急連絡先'} name={'tech_emer_tel'} value={data.tech_emer_tel} type={'tel'} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>経理担当者情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'person_section', dst:'invoice_person_section'},
																{src:'person_name', dst:'invoice_person_name'},
																{src:'person_name_furi', dst:'invoice_person_name_furi'},
																{src:'person_tel', dst:'invoice_person_tel'},
																{src:'person_fax', dst:'invoice_person_fax'},
																{src:'person_mail', dst:'invoice_person_mail'},
															])} >契約担当者 &gt; 経理担当者 Copy</Button>
						</Col>
					</Row>
					<FormText label={'部署名'} name={'invoice_person_section'} value={data.invoice_person_section} onChange={handleOnChange} />
					<FormText label={'担当者名'} name={'invoice_person_name'} value={data.invoice_person_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'invoice_person_name_furi'} value={data.invoice_person_name_furi} onChange={handleOnChange} />
					<FormText label={'電話番号'} name={'invoice_person_tel'} value={data.invoice_person_tel} type={'tel'} onChange={handleOnChange} required={true} />
					<FormText label={'FAX'} name={'invoice_person_fax'} value={data.invoice_person_fax} type={'tel'} onChange={handleOnChange} />
					<FormText label={'Eメール'} name={'invoice_person_mail'} value={data.invoice_person_mail} type={'email'} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>請求書宛名情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'invoice_person_section', dst:'invoice_section'},
																{src:'invoice_person_name', dst:'invoice_name'},
																{src:'invoice_person_name_furi', dst:'invoice_name_furi'},
															])} >経理担当者 &gt; 請求書宛名 Copy</Button>
							　
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'contractor_zip', dst:'invoice_zip'},
																{src:'contractor_addr_1', dst:'invoice_addr_1'},
																{src:'contractor_addr_2', dst:'invoice_addr_2'},
																{src:'contractor_addr_3', dst:'invoice_addr_3'},
															])} >契約者住所 &gt; 請求書宛名 Copy</Button>
						</Col>
					</Row>
					<FormText label={'部署名'} name={'invoice_section'} value={data.invoice_section} onChange={handleOnChange}　/>
					<FormText label={'担当者名'} name={'invoice_name'} value={data.invoice_name} onChange={handleOnChange} required={true} />
					<FormText label={'フリガナ'} name={'invoice_name_furi'} value={data.invoice_name_furi} onChange={handleOnChange} />
					<FormText label={'郵便番号'} name={'invoice_zip'} value={data.invoice_zip} onChange={handleOnChange} required={true} />
					<FormText label={'住所1'} name={'invoice_addr_1'} value={data.invoice_addr_1} onChange={handleOnChange} required={true} />
					<FormText label={'住所2'} name={'invoice_addr_2'} value={data.invoice_addr_2} onChange={handleOnChange} required={true} />
					<FormText label={'住所3'} name={'invoice_addr_3'} value={data.invoice_addr_3} onChange={handleOnChange} />
					<hr/>
					<p>請求書送付先情報</p>
					<Row style={{ marginBottom: 10 }}>
						<Col sm={3}>
							<p>請求書宛名情報</p>
						</Col>
						<Col sm={6}>
							<Button variant="dark" type="button" onClick={()=>handleCopy([
																{src:'invoice_person_mail', dst:'invoice_mail'},
															])} >経理担当者 &gt; 送付先Eメール Copy</Button>
						</Col>
					</Row>
					<FormSelect label={'送付区分'} name={'invoice_mail_flag'} value={data.invoice_mail_flag} data={ [ '郵送', 'メール'] } onChange={handleOnChange} />
					<FormText label={'送付先Eメール'} name={'invoice_mail'} value={data.invoice_mail} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_1）'} name={'invoice_mail_cc_1'} value={data.invoice_mail_cc_1} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_2）'} name={'invoice_mail_cc_2'} value={data.invoice_mail_cc_2} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_3）'} name={'invoice_mail_cc_3'} value={data.invoice_mail_cc_3} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_4）'} name={'invoice_mail_cc_4'} value={data.invoice_mail_cc_4} type={'email'} onChange={handleOnChange} />
					<FormText label={'送付先Eメール（cc_5）'} name={'invoice_mail_cc_5'} value={data.invoice_mail_cc_5} type={'email'} onChange={handleOnChange} />
					<hr/>
					<p>請求管理情報</p>
					<FormSelect label={'請求方法'} name={'invoice_pay_kbn'} value={data.invoice_pay_kbn} data={ [ '請求書', '銀行自動振替', 'クレジットカード決済'] } onChange={handleOnChange} required={true} />
					<FormSelect label={'銀行口座'} name={'invoice_bank_pay_kbn'} value={data.invoice_bank_pay_kbn} data={ [ '三井住友銀行', '紀陽銀行', '永和信用金庫', '大阪信用金庫'] } onChange={handleOnChange} required={true} />
					<FormSelect label={'印鑑区分'} name={'invoice_seal_flag'} value={data.invoice_seal_flag} data={ [ '有効', '無効'] } onChange={handleOnChange} required={true} />
					<FormSelect label={'AWS区分'} name={'invoice_aws_flag'} value={data.invoice_aws_flag} data={ [ '有効', '無効'] } onChange={handleOnChange} />
					<FormText label={'メモ'} name={'invoice_text'} value={data.invoice_text} onChange={handleOnChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>
				
				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block disabled={lock || customer_no ? false : true} >Delete</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};

export default withRouter(Default);
