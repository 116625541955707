import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, withRouter } from 'react-router-dom';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [hidden, setHidden] = useState(JSON.parse(sessionStorage.getItem('support_view') ?? true));

	useEffect(() => {
	
		const getData = async () => {
			
			const ref = await API.AWSSupport.GetAll();

			let newData = [];

			if(hidden){
				//対象外を除外
				newData = JSON.parse(JSON.stringify(ref.items.filter(item => item.status !== 'クローズ')));
			}
			else{
				newData = JSON.parse(JSON.stringify(ref.items));
			}


			//データセット
			setData(newData);
			setView(newData);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
	},[hidden, reload]);

	const handleReload = () => {
		setReload(reload+1);
	};

	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const handleClick = () => {
		const flag = hidden ? false : true;
		sessionStorage.setItem('support_view', JSON.stringify(flag));
		setHidden(flag);
	};

	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/support/' + row.case_id)}>
					詳細
				</Button>
			</div>
		);
	};

	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const second       = ('00' + dateObj.getSeconds().toString()).slice(-2);
			const dateText     = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'case_id',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'subject',
			text: '題名',
			sort: true,
		},
		{
			dataField: 'type',
			text: '種別',
			sort: true,
			formatter: (cell) => {
				switch(cell){
					case 'technical':
						return '技術';
					case 'basic':
						return '一般';
					case 'build':
						return '構築';
					default:
						return '';
				};
			},
		},
		{
			dataField: 'person_name',
			text: '主担当者',
			sort: true,
		},
		{
			dataField: 'user_name',
			text: 'お問合せされた方',
			sort: true,
		},
		{
			dataField: 'status',
			text: 'ステータス',
			sort: true,
		},
		{
			dataField: 'date_add',
			text: '作成日時',
			sort: true,
			formatter: formatDate,
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<Row>
					<Col sm={12}>
						<h1>サポートケース</h1>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1}>
									<Button variant="danger" type="button" block onClick={() => history.push('/support/new')} >新規登録</Button>
								</Col>
								<Col>
									<Form.Check type="checkbox" label="クローズを表示しない" checked={hidden ? true : false} onChange={handleClick}/>
								</Col>
							</Row>
							
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'case_id'} data={view} columns={columns} checkbox={false} sort={ {field: 'date_add', order: 'desc'} } />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
