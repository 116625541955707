import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect, FormButton } from '../../Objects/Form';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';
import { v4 as uuidv4 } from 'uuid';


const Default = () => {

	const history = useHistory();
	const { account_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [customer, setCustomer] = useState([]);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};
			
			if(account_no){
				//データ取得
				newData = (await API.AWS.Get(account_no)).item;
			}
			else{
				newData.contract_date=Math.floor((new Date()).getTime() / 1000);
				newData.bill_start= covertTime('2007-01');
				newData.customer_no='';
				newData.customer_name='';
				newData.status='有効';
				newData.aws_support=[{
					uuid:uuidv4(),
							support_name: null,
							contract_date: Math.floor((new Date()).getTime() / 1000),
							price_notax: 0,
							bill_start: covertTime('2007-01'),
							bill_end: null,
							memo_text: null,
							status: '有効',
				}];
				newData.aws_discount=[{
					uuid:uuidv4(),
							percent: 0,
							contract_date: Math.floor((new Date()).getTime() / 1000),
							bill_start: covertTime('2007-01'),
							bill_end: null,
							memo_text: null,
							status: '有効',
				}];
			}

			if(newData.aws_support === null){
				newData.aws_support=[];
			}
			
			if(newData.aws_discount === null){
				newData.aws_discount=[];
			}

			//ソート
			newData.aws_support.sort(function(a,b){
				if(a.contract_date < b.contract_date) return -1;
				if(a.contract_date > b.contract_date) return 1;
				return 0;
			});

			//ソート
			newData.aws_discount.sort(function(a,b){
				if(a.contract_date < b.contract_date) return -1;
				if(a.contract_date > b.contract_date) return 1;
				return 0;
			});
			
			setData(newData);
			setCustomer((await API.Customer.GetAll()).items);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			if(account_no){
			
				//更新
				const ref = await API.AWS.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				//新規
				const ref = await API.AWS.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(account_no){
				//削除
				const ref = await API.AWS.Delete(account_no);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};
	
	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setShowCustomer([false]);
		setRefresh([true]);
	};
	
	const handleAddDetailsClick = async () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.aws_support.push({
			uuid:uuidv4(),
	            	support_name: null,
	            	contract_date: Math.floor((new Date()).getTime() / 1000),
	            	price_notax: 0,
	            	bill_start: covertTime('2007-01'),
	            	bill_end: null,
	            	memo_text: null,
	            	status: '有効',
		});
		setData(newData);
		setRefresh([true]);
	};

	const handleAddDetailsSupportBClick = async () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.aws_support.push({
			uuid:uuidv4(),
	            	support_name: 'ベーシック',
	            	contract_date: Math.floor((new Date()).getTime() / 1000),
	            	price_notax: 5000,
	            	bill_start: covertTime('2007-01'),
	            	bill_end: null,
	            	memo_text: null,
	            	status: '有効',
		});
		setData(newData);
		setRefresh([true]);
	};
	
	const handleAddDetailsSupportAClick = async () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.aws_support.push({
			uuid:uuidv4(),
	            	support_name: 'アドバンスド',
	            	contract_date: Math.floor((new Date()).getTime() / 1000),
	            	price_notax: 20000,
	            	bill_start: covertTime('2007-01'),
	            	bill_end: null,
	            	memo_text: null,
	            	status: '有効',
		});
		setData(newData);
		setRefresh([true]);
	};

	const handleAddDetailsSupportAOClick = async () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.aws_support.push({
			uuid:uuidv4(),
	            	support_name: 'アドバンスド On-Ramp',
	            	contract_date: Math.floor((new Date()).getTime() / 1000),
	            	price_notax: 0,
	            	bill_start: covertTime('2007-01'),
	            	bill_end: null,
	            	memo_text: null,
	            	status: '有効',
		});
		setData(newData);
		setRefresh([true]);
	};

	const handleAddDetailsSupportMClick = async () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.aws_support.push({
			uuid:uuidv4(),
	            	support_name: 'マネージド',
	            	contract_date: Math.floor((new Date()).getTime() / 1000),
	            	price_notax: 100000,
	            	bill_start: covertTime('2007-01'),
	            	bill_end: null,
	            	memo_text: null,
	            	status: '有効',
		});
		setData(newData);
		setRefresh([true]);
	};
	
	const handleDropDetailsClick = (event) => {
		const name = event.target.name;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.aws_support){
			if(object.uuid !== name){
				newDetails.push(object);
			}
		}
		newData.aws_support = newDetails;
		setData(newData);
		setRefresh([true]);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.aws_support){
				if(object.uuid === uuid){
					if(type === 'date' || type === 'month'){
						const newValue = covertTime(value);
						object[target] = newValue;
					}
					else{
						object[target] = value;
					}
				}
				newDetails.push(object);
			}
			newData.aws_support = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleAddDetailsClick2 = async () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.aws_discount.push({
			uuid:uuidv4(),
					percent: 0,
	            	contract_date: Math.floor((new Date()).getTime() / 1000),
	            	bill_start: covertTime('2007-01'),
	            	bill_end: null,
	            	memo_text: null,
	            	status: '有効',
		});
		setData(newData);
		setRefresh([true]);
	};

	const handleAddDetailsDiscount3Click = async () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.aws_discount.push({
			uuid:uuidv4(),
					percent: 3,
	            	contract_date: Math.floor((new Date()).getTime() / 1000),
	            	bill_start: covertTime('2007-01'),
	            	bill_end: null,
	            	memo_text: null,
	            	status: '有効',
		});
		setData(newData);
		setRefresh([true]);
	};
	
	const handleDropDetailsClick2 = (event) => {
		const name = event.target.name;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.aws_discount){
			if(object.uuid !== name){
				newDetails.push(object);
			}
		}
		newData.aws_discount = newDetails;
		setData(newData);
		setRefresh([true]);
	};
	
	const handleOnChange2 = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.aws_discount){
				if(object.uuid === uuid){
					if(type === 'date' || type === 'month'){
						const newValue = covertTime(value);
						object[target] = newValue;
					}
					else{
						object[target] = value;
					}
				}
				newDetails.push(object);
			}
			newData.aws_discount = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};

	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/aws/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/aws/' + data.account_no;
		}
		else{
			setReload(reload+1);
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'アカウント番号'} name={'account_no'} value={data.account_no} onChange={handleOnChange} required={account_no ? false : true} readOnly={account_no ? true : false} />
					<hr/>
					<FormButton label={'対象顧客'} name={'customer_no'} value={data.customer_no ? (data.customer_no + '　' + data.customer_name) : '未選択'} onClick={handleCustomerShow} required={true} />
					<FormText label={'契約日'} name={'contract_date'} value={dateShapingDate(data.contract_date)} type={'date'} onChange={handleOnChange} required={true} />
					<FormText label={'アカウント名'} name={'account_name'} value={data.account_name} onChange={handleOnChange} />
					<FormSelect label={'組織アカウント'} name={'organization_flag'} value={data.organization_flag} data={ [ '管理アカウント', 'メンバーアカウント', '対象外' ] } onChange={handleOnChange} required={true} />
					<FormText label={'管理アカウント'} name={'organization_account_no'} value={data.organization_account_no} onChange={handleOnChange} />
					<FormSelect label={'決済サービス'} name={'payment_service'} value={data.payment_service} data={ [ '有効', '無効' ] } onChange={handleOnChange} required={true} />
					{/*
						<FormText label={'プロフィール名'} name={'profile_name'} value={data.profile_name} onChange={handleOnChange} />
						<FormText label={'access_key'} name={'access_key'} value={data.access_key}  onChange={handleOnChange} />
						<FormText label={'sercret_key'} name={'sercret_key'} value={data.sercret_key}  onChange={handleOnChange} />
					*/}
					<FormText label={'メモ'} name={'memo_text'} value={data.memo_text} onChange={handleOnChange} />
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							サポート
						</Form.Label>
					</Row>
					<Row>
						<Col sm={12}>
					                <table cellSpacing="0" border="1" width="100%">
					                    <thead>
					                        <tr>
					                            <td>削除</td>
					                            <td>項目</td>
					                            <td>金額</td>
					                            <td>契約日</td>
					                            <td>課金開始月</td>
					                            <td>課金停止月</td>
					                            <td>ステータス</td>
					                        </tr>
					                    </thead>
					                    <tbody>
					                    	{data.aws_support.map(item => {
					                    		return(
						                            <tr key={item.uuid}>
						                                <td className="text-center"><input type="button" name={item.uuid} defaultValue={'X'} style={{width:'25px'}} onClick={handleDropDetailsClick} /></td>
						                                <td className="text-center"><input type="text" name={item.uuid + ':support_name'} value={item.support_name} style={{width:'200px'}} onChange={handleOnChange} required={true} /></td>
						                                <td className="text-center">
						                                	<input type="text" name={item.uuid + ':price_notax'} value={item.price_notax} style={{width:'100px'}} onChange={handleOnChange} required={true} />
						                                </td>
						                                <td className="text-center"><input type="date" name={item.uuid + ':contract_date'} value={dateShapingDate(item.contract_date)} onChange={handleOnChange} required={true} /></td>
						                                <td className="text-center"><input type="month" name={item.uuid + ':bill_start'} value={dateShapingMonth(item.bill_start)} onChange={handleOnChange} required={true} /></td>
						                                <td className="text-center"><input type="month" name={item.uuid + ':bill_end'} value={dateShapingMonth(item.bill_end)} onChange={handleOnChange} /></td>
						                                <td className="text-center">
							                                <select name={item.uuid + ':status'} value={item.status} onChange={handleOnChange} required={true}>
							                                <option value="">--</option>
							                                <option value="有効">有効</option>
							                                <option value="無効">無効</option>
							                                </select>
						                                </td>
						                            </tr>
					                    		);
					                    	})}
				                    	    <tr>
				                                <td className="text-center" colSpan="7">
													<input type="button" defaultValue={'行追加'} style={{width:'310px'}} onClick={handleAddDetailsClick} />　
													<input type="button" defaultValue={'ベーシック追加'} onClick={handleAddDetailsSupportBClick} />　
													<input type="button" defaultValue={'アドバンスドOn-Ramp追加'} onClick={handleAddDetailsSupportAOClick} />　
													<input type="button" defaultValue={'アドバンスド追加'} onClick={handleAddDetailsSupportAClick} />　
													<input type="button" defaultValue={'マネージド追加'} onClick={handleAddDetailsSupportMClick} />　
												</td>
				                            </tr>
					                    </tbody>
					                </table>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Form.Label column sm={3}>
							割引プラン
						</Form.Label>
					</Row>
					<Row>
						<Col sm={12}>
					                <table cellSpacing="0" border="1" width="100%">
					                    <thead>
					                        <tr>
					                            <td>削除</td>
					                            <td>割引率</td>
					                            <td>契約日</td>
					                            <td>課金開始月</td>
					                            <td>課金停止月</td>
					                            <td>ステータス</td>
					                        </tr>
					                    </thead>
					                    <tbody>
					                    	{data.aws_discount.map(item => {
					                    		return(
						                            <tr key={item.uuid}>
						                                <td className="text-center"><input type="button" name={item.uuid} defaultValue={'X'} style={{width:'25px'}} onClick={handleDropDetailsClick2} /></td>
						                                <td className="text-center"><input type="number" name={item.uuid + ':percent'} value={item.percent} style={{width:'200px'}} onChange={handleOnChange2} required={true} /></td>
						                                <td className="text-center"><input type="date" name={item.uuid + ':contract_date'} value={dateShapingDate(item.contract_date)} onChange={handleOnChange2} required={true} /></td>
						                                <td className="text-center"><input type="month" name={item.uuid + ':bill_start'} value={dateShapingMonth(item.bill_start)} onChange={handleOnChange2} required={true} /></td>
						                                <td className="text-center"><input type="month" name={item.uuid + ':bill_end'} value={dateShapingMonth(item.bill_end)} onChange={handleOnChange2} /></td>
						                                <td className="text-center">
							                                <select name={item.uuid + ':status'} value={item.status} onChange={handleOnChange2} required={true}>
							                                <option value="">--</option>
							                                <option value="有効">有効</option>
							                                <option value="無効">無効</option>
							                                </select>
						                                </td>
						                            </tr>
					                    		);
					                    	})}
				                    	    <tr>
				                                <td className="text-center" colSpan="7">
													<input type="button" defaultValue={'行追加'} style={{width:'310px'}} onClick={handleAddDetailsClick2} />　
													<input type="button" defaultValue={'3%割引プラン追加'} onClick={handleAddDetailsDiscount3Click} />　
												</td>
				                            </tr>
					                    </tbody>
					                </table>
						</Col>
					</Row>
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} value={dateShapingMonth(data.bill_start)} type={'month'} onChange={handleOnChange} required={true} />
					<FormText label={'課金停止月'} name={'bill_end'} value={dateShapingMonth(data.bill_end)} type={'month'} onChange={handleOnChange} />
					<hr/>
					<FormSelect label={'ステータス'} name={'status'} value={data.status} data={ [ '有効', '無効' ] } onChange={handleOnChange} required={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>

				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block disabled={lock || account_no ? false : true} >Delete</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
