import { useState  } from 'react';
import {
	Alert,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';

const Default = (props) => {

	const [selectIDs, setSelectIDs] = useState([]);

	const selectRows = (row, isSelect, rowIndex, e) => {
		
		let refIDs = selectIDs;
		
		//新しく選択した内容
		const selectID  = row[props.keyField];

		//選択したかどうか
		if(isSelect){
			//新しく選択した内容を追加
			refIDs.push(selectID);
		
			//重複を削除
			refIDs = refIDs.filter((x, i, self) => self.indexOf(x) === i);
		}
		else{
			//対応する要素を削除
			refIDs = refIDs.filter(n => n !== selectID);
		}
		//console.log(refIDs);
		
		//今まで選択した内容を更新
		setSelectIDs(refIDs);
		
		//親に数値を渡す
		props.getSelectIDs(refIDs);
		
	};

	const selectAllRows = (isSelect, rows, e) => {

		let refIDs = selectIDs;

		//ループ処理
		rows.some(row => {
		
			//新しく選択した内容
			const selectID  = row[props.keyField];
			
			//選択したかどうか
			if(isSelect){
				//新しく選択した内容を追加
				refIDs.push(selectID);
			
				//重複を削除
				refIDs = refIDs.filter((x, i, self) => self.indexOf(x) === i);
			}
			else{
				//対応する要素を削除
				refIDs = refIDs.filter(n => n !== selectID);
			}
			
			return false;
		
		});

		//今まで選択した内容を更新
		setSelectIDs(refIDs);
		
		//親に数値を渡す
		props.getSelectIDs(refIDs);

	};

	const defaultSorted = [
		{
			dataField: props.sort ? (props.sort.field ? props.sort.field : props.keyField) : props.keyField,
			order: props.sort ? (props.sort.order ? props.sort.order : 'desc') : 'desc',
		}
	];

	const selectRow = {
		mode         : 'checkbox',
		clickToSelect: false,
		onSelect     : selectRows,
		onSelectAll  : selectAllRows,
	};

	const options = {
		sizePerPageList: [
			{ text: '10', value: 10},
			{ text: '50', value: 50},
			{ text: '100', value: 100},
			{ text: 'All', value: props.data === null ? 0 : props.data.length},
		],
	};
	
	if(props.data === null || props.data.length === 0){
		return (
			<Alert variant="danger">
				Data Not Found.
			</Alert>
		);
	}
	else{
		
		return (
			<BootstrapTable bootstrap4 keyField={ props.keyField } data={ props.data } columns={ props.columns } bordered={ props.bordered ? true : false } defaultSorted={ props.defaultSorted ? props.defaultSorted : defaultSorted } selectRow={ props.checkbox ? selectRow : undefined } pagination={ paginationFactory(props.options ? props.options : options) } rowStyle={ props.rowStyle } cellEdit={ props.cellEdit ? cellEditFactory(props.cellEdit) : undefined } filter={props.filter} filterPosition={props.filterPosition} />
		);
	}
};


export default Default;

