import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { dateShapingDate } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormTextArea } from '../../Objects/Form';
import NotFound from '../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { order_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState({});
	const [notfound, setNotfound] = useState(false);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//データ取得
			const newData = (await API.Order.Get(order_no)).item;
			
			if(Object.keys(newData).length){
				setData(newData);
			}
			else{
				setNotfound(true);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(notfound){
		return (<NotFound/>);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>

				<Form>

					<Row>
						<Col sm={6}>
							<p><big>発注内容</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'発注番号'} name={'order_no'} defaultValue={order_no} readOnly={true} />
					<FormText label={'発注日'} name={'date_order'} defaultValue={dateShapingDate(data.date_order)} type={'date'} readOnly={true} />
					<FormText label={'発注アカウント'} name={'customer_no'} defaultValue={data.customer_no + '　' + data.customer_name} readOnly={true} />
					<hr/>
					<FormText label={'発注対象番号'} name={'target_no'} defaultValue={data.target_no} readOnly={true} />
					<FormText label={'発注対象'} name={'title'} defaultValue={data.title} readOnly={true} />
					<FormText label={'月額費用'} name={'i_total_price_notax'} defaultValue={data.i_total_price_notax.toLocaleString() + '円'} readOnly={true} />
					<FormText label={'月額費用'} name={'m_total_price_notax'} defaultValue={data.m_total_price_notax.toLocaleString() + '円'} readOnly={true} />
					<hr/>
					<FormTextArea label={'連絡事項'} name={'biko_text'} defaultValue={data.biko_text}  readOnly={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};


export default withRouter(Default);
