import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../../Objects/Table';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../../Objects/Modal';
import { v4 as uuidv4 } from 'uuid';


const Default = () => {
	
	const history = useHistory();
	const { ns53_no, domain_name } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [showDelete, setShowDelete] = useState([false]);
	const [name, setName] = useState('');
	const [type, setType] = useState('');

	useEffect(() => {
	
		const getData = async () => {
			
			//件数の取得
			const ref = await API.NS53.RecordGetAll(domain_name);
			//console.log(ref);
			
			const newData = [];
			
			for(const item of ref.items){
				item.uuid = uuidv4();
				newData.push(item);
			}
			
			//データセット
			setData(newData);
			setView(newData);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleDelete = async (event) => {
	
		setShowDelete([false]);
		
		try {
			
			if(domain_name && name && type){
			
				if(name === domain_name && type === 'NS'){
					throw new Error('SOAレコード、NSレコードは削除できません。');
				}
				else if(name === domain_name && type === 'SOA'){
					throw new Error('SOAレコード、NSレコードは削除できません。');
				}
				
				//ゾーン削除
				const ref = await API.NS53.RecordDelete(name, type);
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const handleDeleteShow = (name, type) => {
		setShowDelete([true]);
		setName(name);
		setType(type);
	};
	
	const handleClose = () => {
		setAlert([]);
		setShowDelete([false]);
		handleReload();
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};

	const formatButton1 = (cell, row) => {
		return (
			<div>
				<Button variant="dark" type="button" onClick={() => history.push('/ns53/' + ns53_no + '/' + domain_name + '/' + row.name + '/' + row.type + '/')}>
					編集
				</Button>
			</div>
		);
	};

	const formatButton2 = (cell, row) => {
		return (
			<div>
				<Button variant="danger" type="button" onClick={() => handleDeleteShow(row.name, row.type)} disabled={(row.name === domain_name && (row.type === 'NS' || row.type === 'SOA')) ? true : false}>
					削除
				</Button>
			</div>
		);
	};

	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'uuid',
			isDummyField: true,
			text: '編集',
			sort: false,
			formatter: formatButton1,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'name',
			text: 'レコード名',
			sort: true,
		},
		{
			dataField: 'type',
			text: 'タイプ',
			sort: true,
		},
		{
			dataField: 'content',
			text: '値',
			sort: true,
		},
		{
			dataField: 'ttl',
			text: 'TTL',
			sort: true,
		},
		{
			dataField: 'date_add',
			text: '登録日',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'dummy2',
			isDummyField: true,
			text: '削除',
			sort: false,
			formatter: formatButton2,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalDelete domain_name={domain_name} name={name} type={type} show={showDelete} onClick={handleDelete} onHide={handleClose} />
				<Row>
					<Col sm={12}>
						<h4>NS53（{domain_name}） - レコード一覧</h4>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1}>
									<Button variant="danger" type="button" block onClick={() => history.push('./new')} >新規登録</Button>
								</Col>
								<Col sm={1}>
								        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} >もどる</Button>
								</Col>
							</Row>
							
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'uuid'} data={view} columns={columns} checkbox={false} sort={ {field: 'type', order: 'asc'} } />
					</Col>
				</Row>
			</Container>
		);
	}
};

const ModalDelete = (props) => {

	const [flag, setFlag] = useState(true);

	useEffect(() => {
		
		if(props.name === props.domain_name && props.type === 'NS'){
			setFlag(true);
		}
		else if(props.name === props.domain_name && props.type === 'SOA'){
			setFlag(true);
		}
		else{
			setFlag(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	const handleClose = () => {
		setFlag(true);
		props.onHide();
	};
	
	return (
		<Modal show={props.show[0]} onHide={handleClose} size="lg" centered>
			<Modal.Body>
				<Form>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
							レコードを削除しますか？
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} disabled={flag} >削除する</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={handleClose} >キャンセル</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(Default);
