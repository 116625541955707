import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [selectIDs, setSelectIDs] = useState([]);
	const [selectMount, setSelectMount] = useState(undefined);
	const [invoiceFlag, setInvoiceFlag] = useState('F');
	const [alert, setAlert] = useState([]);
	const [flag, setFlag] = useState({});

	useEffect(() => {
	
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const getData = async () => {
		
		//日付指定がない場合は、前月を指定
		const default_date  = new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1);
		default_date.setMonth(default_date.getMonth() - 1);
		const default_year  = default_date.getFullYear().toString();
		const default_month = ('00' + (default_date.getMonth()+1).toString()).slice(-2);
		const year          = selectMount ? selectMount.split(':')[0] : default_year;
		const month         = selectMount ? selectMount.split(':')[1] : default_month;
		setSelectMount(year + ':' + month);
		//console.log(default_year,default_month, year,month);
		
		const dateObj      = new Date(year, month - 1, 1);
		const start_date = Math.floor(dateObj.getTime() / 1000);
		dateObj.setMonth(dateObj.getMonth() + 1);
		dateObj.setDate(0);
		const end_date = Math.floor(dateObj.getTime() / 1000);

		//Flag呼び出し
		const flag_text = sessionStorage.getItem('invoice_flag');
		const flag_data = flag_text ? JSON.parse(flag_text) : {};
		//console.log(flag_data);

		//Flag選択
		const updateData = async (data, flag) => {
			let newData = JSON.parse(JSON.stringify(data));
			for(const name of Object.keys(flag)){
				const value = flag[name];
				if(value !== ''){
					newData = newData.filter(item => item[name] === value);
				}
			}
			return(newData);
		};

		//console.log(start_date);
		//console.log(end_date);
		
		//件数の取得
		const ref = await API.Invoice.Query(invoiceFlag, start_date, end_date);
		//console.log(invoiceFlag);
		//console.log(start_date);
		//console.log(end_date);
		//console.log(ref);

		const newData = await updateData(ref.items, flag_data);

		//データセット
		setData(ref.items);
		setView(newData);
		setFlag(flag_data);


		//表示開始
		setLoad(false);
	};
	
	const generateMonth = () => {
		
		const newMonth = [];
		
		for(const i of [...Array(60).keys()]){
			const dateObj      = new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1);
			dateObj.setMonth(dateObj.getMonth() - i);
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			newMonth.push({
				year  : year,
				month : month,
			});
		}
		return(newMonth);
	};
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);
		setFlag({});

	};
	
	const handleInvoiceFlagOnChange = (event) => {
		setInvoiceFlag(event.target.value);
		handleReload();
	};
	
	const handleSelectMountOnChange = (event) => {
		setSelectMount(event.target.value);
		handleReload();
	};
	
	const handleFlagChange = async (event) => {
		const invoice_no = event.target.name.split(':')[0];
		const flag_name = event.target.name.split(':')[1];
		const flag_value = event.target.checked;
		
		try {
			//オブジェクト
			const data = { invoice_no : invoice_no };
			
			//代入
			data[flag_name] = flag_value;
		
			//更新
			const ref = await API.Invoice.Update(data);
			
			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。' + ref.error);
			}
		}
		catch(err){
			setAlert([err.message]);
		}
	};
	
	const handleViewChange = async (event) => {
		
		const updateData = async (flag) => {
			let newData = JSON.parse(JSON.stringify(data));
			for(const name of Object.keys(flag)){
				const value = flag[name];
				if(value !== ''){
					newData = newData.filter(item => item[name] === value);
				}
			
			}
			setView(newData);
		};
		
		const name = event.target.name;
		const value = event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value;
		
		const newFlag = flag;
		newFlag[name] = value;
		setFlag(newFlag);

		//セッション保存
		sessionStorage.setItem('invoice_flag', JSON.stringify(newFlag));

		await updateData(newFlag);
	};
	
	const handleClick1 = async (event) => {
		sessionStorage.setItem('invoice_nos', JSON.stringify(selectIDs));
		window.location.href = '/print/invoice/select/';
	};
	
	const handleClick2 = async (event) => {
		try {
			setLoad(true);
			for(const invoice_no of selectIDs){
				//データ確認
				const object = data.filter(item => item.invoice_no === invoice_no)[0];
				//更新
				if(object.cutoff_flag === false){
					const ref = await API.Invoice.Update({ invoice_no : invoice_no, cutoff_flag : true });
					//エラー確認
					if(ref.error){
						throw new Error('入力エラーがありました。' + ref.error);
					}
				}
			}
			//throw new Error('更新しました');
		}
		catch(err){
			setLoad(false);
			setAlert([err.message]);
		}
		handleReload();
	};
	
	const handleClick3 = async (event) => {
		try {
			setLoad(true);
			for(const invoice_no of selectIDs){
				//データ確認
				const object = data.filter(item => item.invoice_no === invoice_no)[0];
				//更新
				if(object.cutoff_flag === true){
					const ref = await API.Invoice.Update({ invoice_no : invoice_no, cutoff_flag : false });
					//エラー確認
					if(ref.error){
						throw new Error('入力エラーがありました。' + ref.error);
					}
				}
			}
			//throw new Error('更新しました');
		}
		catch(err){
			setLoad(false);
			setAlert([err.message]);
		}
		handleReload();
	};
	
	const handleClick4 = async (event) => {
		try {
			setLoad(true);
			for(const invoice_no of selectIDs){
				//データ確認
				const object = data.filter(item => item.invoice_no === invoice_no)[0];
				//更新
				if(object.cutoff_flag === false){
					const ref = await API.Invoice.Delete(invoice_no);
					//エラー確認
					if(ref.error){
						throw new Error('入力エラーがありました。' + ref.error);
					}
				}
			}
			//throw new Error('更新しました');
		}
		catch(err){
			setLoad(false);
			setAlert([err.message]);
		}
		handleReload();
	};
	
	const getSelectIDs = (ids) => {
		setSelectIDs(ids);
	};
	
	const formatButton1 = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/invoice/' + row.invoice_no)}>
					詳細
				</Button>
			</div>
		);
	};

	const formatButton2 = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/print/invoice/' + row.invoice_no + '/')}>
					請求書
				</Button>
			</div>
		);
	};
	
	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'dummy1',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButton1,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'dummy2',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButton2,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'invoice_no',
			text: '見積番号',
			sort: true,
		},
		{
			dataField: 'date_invoice',
			text: '請求日',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'cutoff_flag',
			text: '締め',
			sort: true,
			formatter: (cell) => cell ? '済' : '未',
		},
		{
			dataField: 'flag_1',
			text: '確認',
			sort: true,
			formatter: (cell, row) => <input type="checkbox" name={row.invoice_no + ':flag_1'} defaultChecked={cell ? true : false} onChange={handleFlagChange} />,
		},
		{
			dataField: 'flag_2',
			text: '送信',
			sort: true,
			formatter: (cell, row) => <input type="checkbox" name={row.invoice_no + ':flag_2'} defaultChecked={cell ? true : false} onChange={handleFlagChange} />,
		},
		{
			dataField: 'flag_3',
			text: '公開',
			sort: true,
			formatter: (cell, row) => <input type="checkbox" name={row.invoice_no + ':flag_3'} defaultChecked={cell ? true : false} onChange={handleFlagChange} />,
		},
		{
			dataField: 'customer_name',
			text: '顧客名',
			sort: true,
		},
		{
			dataField: 'total_price_notax',
			text: '小計',
			sort: true,
			formatter: (cell) => cell.toLocaleString() + ' 円',
			classes: 'text-right',
		},
		{
			dataField: 'total_tax',
			text: '消費税',
			sort: true,
			formatter: (cell) => cell.toLocaleString() + ' 円',
			classes: 'text-right',
		},
		{
			dataField: 'total_price',
			text: '請求金額',
			sort: true,
			formatter: (cell) => cell.toLocaleString() + ' 円',
			classes: 'text-right',
		},
	];
	
	const options = {
		sizePerPageList: invoiceFlag === 'F' ?
			[{ text: 'All', value: data.length}]
		:
			[
				{ text: '10', value: 10},
				{ text: '50', value: 50},
				{ text: '100', value: 100},
				{ text: 'All', value: data === null ? 0 : data.length},
			],
	};
	
	const rowStyle = (row, rowIndex) => {
		if(row.cutoff_flag){
			return { backgroundColor: 'silver' };
		}
	};
	
	const handleClose = () => {
		setAlert([]);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
				<Row>
					<Col sm={12}>
						<h1>請求書</h1>
					</Col>
					<Col sm={12}>
						<Form onSubmit={handleSearch} name="form">
							<Row style={{ marginTop: 20 }}>
								<Col sm={1}>
									<Button variant="danger" type="button" block onClick={() => history.push('/invoice/new')} >新規登録</Button>
								</Col>
							</Row>
							
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
									</Badge>　
								</Col>
								<Col sm={1}>
									<Form.Group controlId="invoice_flag">
									        <Form.Control name="invoice_flag" as="select" onChange={handleInvoiceFlagOnChange} value={invoiceFlag}>
									        <option value="F">課金</option>
									        <option value="L">都度</option>
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="select_month">
									        <Form.Control name="select_month" as="select" onChange={handleSelectMountOnChange} value={selectMount}>
									        {generateMonth().map(item => {
										        return(<option key={item.year + item.month} value={item.year + ':' + item.month}>{item.year}年{item.month}月</option>);
										})}
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
									        <Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block >Search</Button>
								</Col>
								<Col sm={3} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
							<Row>
								<Col sm={1} className="text-center">
									　
								</Col>
								<Col sm={1}>
									<Form.Group controlId="cutoff_flag">
									        <Form.Control name="cutoff_flag" as="select" onChange={handleViewChange} value={flag.cutoff_flag === undefined ? '' : flag.cutoff_flag}>
									        <option value="">----</option>
									        <option value="true">締切済</option>
									        <option value="false">未締切</option>
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Form.Group controlId="flag_1">
									        <Form.Control name="flag_1" as="select" onChange={handleViewChange} value={flag.flag_1 === undefined ? '' : flag.flag_1}>
									        <option value="">----</option>
									        <option value="true">確認済</option>
									        <option value="false">未確認</option>
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Form.Group controlId="flag_2">
									        <Form.Control name="flag_2" as="select" onChange={handleViewChange} value={flag.flag_2 === undefined ? '' : flag.flag_2}>
									        <option value="">----</option>
									        <option value="true">送信済</option>
									        <option value="false">未送信</option>
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Form.Group controlId="flag_3">
									        <Form.Control name="flag_3" as="select" onChange={handleViewChange} value={flag.flag_3 === undefined ? '' : flag.flag_3}>
									        <option value="">----</option>
									        <option value="true">公開済</option>
									        <option value="false">未公開</option>
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Form.Group controlId="aws_flag">
									        <Form.Control name="aws_flag" as="select" onChange={handleViewChange} value={flag.aws_flag === undefined ? '' : flag.aws_flag}>
									        <option value="">----</option>
									        <option value="true">AWSのみ</option>
									        <option value="false">AWS以外</option>
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Form.Group controlId="mail_flag">
									        <Form.Control name="mail_flag" as="select" onChange={handleViewChange} value={flag.mail_flag === undefined ? '' : flag.mail_flag}>
									        <option value="">----</option>
									        <option value="true">MAILのみ</option>
									        <option value="false">MAIL以外</option>
									        </Form.Control>
									</Form.Group>
								</Col>
								<Col sm={2}>
									<Form.Group controlId="pay_kbn">
									        <Form.Control name="pay_kbn" as="select" onChange={handleViewChange} value={flag.pay_kbn === undefined ? '' : flag.pay_kbn}>
									        <option value="">----</option>
									        <option value="請求書">請求書</option>
									        <option value="クレジットカード決済">クレジットカード決済</option>
									        <option value="銀行自動振替">銀行自動振替</option>
									        </Form.Control>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Table keyField={'invoice_no'} data={view} columns={columns} checkbox={true} getSelectIDs={getSelectIDs} sort={ {field: invoiceFlag === 'F' ? 'total_price' : 'date_invoice', order: 'desc'} } options={options} rowStyle={rowStyle} />
					</Col>
				</Row>

				<Button variant="dark" onClick={handleClick1} >
					請求書出力
				</Button>
				　
				<Button variant="dark" onClick={handleClick2} >
					締切処理
				</Button>
				　
				<Button variant="dark" onClick={handleClick3} >
					締切解除
				</Button>
				　
				<Button variant="dark" onClick={handleClick4} >
					削除
				</Button>

			</Container>
		);
	}
};

export default withRouter(Default);
