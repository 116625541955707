
const covertTime = (date_value) => {
	const dateObj = new Date(date_value);
	dateObj.setHours(0);
	dateObj.setMinutes(0);
	dateObj.setSeconds(0);
	dateObj.setMilliseconds(0);
	//console.log(dateObj.getTime());
	return(date_value ? Math.floor(dateObj.getTime() / 1000) : null);
};

const dateShapingDate = (datetime) => {
	if(datetime){
		const dateObj      = new Date(Number(datetime * 1000));
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		return(year + '-' + month + '-' + day);
	}
	else{
		return('');
	}
};

const dateShapingMonth = (datetime) => {
	if(datetime){
		const dateObj      = new Date(Number(datetime * 1000));
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		return(year + '-' + month);
	}
	else{
		return('');
	}
};

const isNumber = (value) => {
	try{
		return ((typeof Number(value) === 'number') && (isFinite(Number(value))));
	}
	catch(err){
		return(false);
	}
};


export { covertTime, dateShapingDate, dateShapingMonth, isNumber };

