import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';
import { v4 as uuidv4 } from 'uuid';
import Decimal from 'decimal.js';


const Default = () => {

	const history = useHistory();
	const { invoice_no } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState([]);
	const [customer, setCustomer] = useState([]);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			//console.log(invoice_no);

			if(invoice_no){

				//データ取得
				const item = (await API.Invoice.Get(invoice_no)).item;

				//ソート
				item.details.sort(function(a,b){
					if(a.sort < b.sort) return -1;
					if(a.sort > b.sort) return 1;
					return 0;
				});

				item.tax_subs = JSON.parse(item.tax_subs??'[]');

				/*
				const newDetails = [];
				for(const object of item.details){
					object.uuid = uuidv4();
					newDetails.push(object);
				}
				item.details = newDetails;
				*/
				
				setData(item);
			}
			else{
				const newData = {};
				
				const dateObj = new Date();
				
				//来月末
				dateObj.setMonth(dateObj.getMonth() + 2);
				dateObj.setDate(0);
				dateObj.setHours(0);
				dateObj.setMinutes(0);
				dateObj.setSeconds(0);
				dateObj.setMilliseconds(0);
				newData.date_payment = covertTime(dateObj);

				//当月末
				dateObj.setMonth(dateObj.getMonth() - 1);
				dateObj.setDate(0);
				newData.date_add = covertTime(dateObj);
				newData.date_invoice = covertTime(dateObj);
				newData.invoice_flag = 'L';
				//console.log(newData.date_invoice);
				
				newData.total_price = 0;
				newData.total_price_notax = 0;
				newData.total_tax = 0;

				//インボイス制度対応
				newData.eligible_invoice = 'T912000112750';
				newData.invoice_version = '2023-10-23';
				newData.tax_subs = [];
				
				newData.details=[{
					uuid:uuidv4(),
					sort:1,
					details:'',
					piece:0,
					piece_type:'件',
					tax_per: 0.1,
					price_notax:0,
					sub_price_notax:0,
					tax:0,
				}];
				
				setData(newData);
			
			}
			
			setCustomer((await API.Customer.GetAll()).items);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleSubmit = async (event) => {
	
		//顧客番号の生成
		const generate = async (invoice_flag) => {
		
			try{
			
				//チェックデジットの計算
				const checkDigit = async (jan) => {
				
					// 逆順にする
					const getReverse = async (req) => {
						return(req.split("").reverse().join(""));
					};

					// 偶数列、奇数列をそれぞれ足し合わせて返す
					const getTotal = async (req) => {
						const arr = req.split("");
						const evenList = [];
						const oddList = [];
						const len = arr.length;
						
						let lim = 0;
						for (let i = 0; i < len; i++) {
							lim++;
							const num = arr[i];
							if (i % 2 !== 0) {
								evenList.push(Number(num));
							}
							else {
								oddList.push(Number(num));
							}
							if (lim === len) {
								const eve = evenList.reduce(function(a, b){ return a + b });
								const odd = oddList.reduce(function(a, b){ return a + b });
								return([eve, odd * 3]);
							}
						}
					};

					// 偶数の合計と奇数の合計を足した末尾を使ってチェックデジットを返す
					const getDigit = async (sumEven, sumOdd) =>{
						const sum = sumEven + sumOdd;
						const end = Number(String(sum).slice(-1));
						if (end === 0) {
							return('0');
						}
						else {
							const req = 10 - end;
							return(req.toString());
						}
					};
					
					const req = await getReverse(jan);
					
					const [sumEven, sumOdd] = await getTotal(req);
					
					const digit = await getDigit(sumEven, sumOdd);
					
					return(jan + digit);

				};

				//月初め
				const dateObj = new Date();
				dateObj.setDate(1);
				dateObj.setHours(0);
				dateObj.setMinutes(0);
				dateObj.setSeconds(0);
				dateObj.setMilliseconds(0);
				const start = covertTime(dateObj);
				const year  = ('00' + dateObj.getFullYear().toString()).slice(-2);
				const month = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
				
				//月末
				dateObj.setMonth(dateObj.getMonth() + 1);
				dateObj.setDate(dateObj.getDate() - 1);
				const end = covertTime(dateObj);
				
				//件数の取得
				const ref = await API.Invoice.Query(invoice_flag, start, end);
				
				//データのソート
				ref.items.sort(
					(a, b) => {
						if (a.date_invoice > b.date_invoice) return -1;
						if (a.date_invoice < b.date_invoice) return 1;
						return 0;
					}
				);
				
				//範囲の抽出
				const newDate = [];
				for(const item of ref.items){
					if(item.date_invoice >= start && item.date_invoice <= end){
						newDate.push(item);
					}
				}
				
				//シリアルの作成
				const serial = ('0000' + (Number(newDate.length) + 1).toString()).slice(-4);
				
				//見積番号
				const number = year.toString() + month.toString() + serial.toString();

				const di = await checkDigit(number);
				
				const invoice_no = invoice_flag + di;
				
				const checkno = ref.items.filter(item => item.invoice_no === invoice_no).length;
				
				//console.log(checkno);
				
				if(checkno === 0){
					return(invoice_no);
				}
				else {
					return('');
				}
			}
			catch(err){
				console.log(err);
			}
		
		};
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			
			if(invoice_no){
			
				//更新
				const ref = await API.Invoice.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{

				const invoice_flag = data.invoice_flag;

				//console.log(invoice_flag);

				//請求書番号の取得
				data.invoice_no =  await generate(invoice_flag);
				
				//更新
				const ref = await API.Invoice.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
		
		console.log(reload);
		
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			//削除
			const ref = await API.Invoice.Delete(invoice_no);
			//console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('エラーがありました。' + ref.error);
			}
			
			throw new Error('削除しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};

	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};
	
	const updateBankPayText = (bank_pay_kbn) => {
		return(bank_pay_kbn === '大阪信用金庫' ? '大阪信用金庫　上福島支店　普通0121991' : (bank_pay_kbn === '永和信用金庫' ? '永和信用金庫　本町支店　普通0007158' : (bank_pay_kbn === '紀陽銀行' ? '紀陽銀行　大阪中央支店　普通1018028' : '三井住友銀行　大阪中央支店　普通8239217')));
	};
	
	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		for(const item of customer){
			if(item.customer_no === customer_no){
				newData.zip = item.invoice_zip;
				newData.addr_1 = item.invoice_addr_1;
				newData.addr_2 = item.invoice_addr_2;
				newData.addr_3 = item.invoice_addr_3;
				newData.section = item.invoice_section;
				newData.name = item.invoice_name;
				newData.name_furi = item.invoice_name_furi;
				newData.pay_kbn = item.invoice_pay_kbn;
				newData.bank_pay_kbn = item.invoice_bank_pay_kbn;
				newData.bank_pay_text = updateBankPayText(item.invoice_bank_pay_kbn);
				newData.aws_flag = item.aws_flag;
				newData.mail = item.invoice_mail;
				newData.mail_cc_1 = item.invoice_mail_cc_1;
				newData.mail_cc_2 = item.invoice_mail_cc_2;
				newData.mail_cc_3 = item.invoice_mail_cc_3;
				newData.mail_cc_4 = item.invoice_mail_cc_4;
				newData.mail_cc_5 = item.invoice_mail_cc_5;
				newData.mail_flag = item.mail_flag;
				newData.seal_flag = item.seal_flag;
			}
		}
		setData(newData);
		setRefresh([true]);
		setShowCustomer([false]);
	};
	
	const handleSortClick = (event) => {
		const uuid = event.target.name;
		const value = event.target.value;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.details){
			if(object.uuid === uuid){
				if(value === '↑'){
					object.sort = object.sort - 2;
				}
				else{
					object.sort = object.sort + 2;
				}
			}
			newDetails.push(object);
		}
		newData.details = newDetails;
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleAddDetailsClick = (sort) => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.details.push({
			uuid:uuidv4(),
			sort: sort ? sort : newData.details.length+1,
			details:'',
			piece:0,
			piece_type:'件',
			tax_per: 0.1,
			price_notax:0,
			sub_price_notax:0,
			tax:0,
		});
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleDropDetailsClick = (event) => {
		const name = event.target.name;
		const newData = JSON.parse(JSON.stringify(data));
		const newDetails = [];
		for(const object of newData.details){
			if(object.uuid !== name){
				newDetails.push(object);
			}
		}
		newData.details = newDetails;
		const updateData = dataUpdate(newData);
		setData(updateData);
		setRefresh([true]);
	};
	
	const handleCutoffFlagClick = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			//cutoff_flag反転
			data.cutoff_flag = data.cutoff_flag ? false : true;
			
			//cutoff_flagがfalseならflag_1もfalseへ
			data.flag_1 =  data.cutoff_flag ? data.flag_1 : false;
			
			//更新
			const ref = await API.Invoice.Update(data);
			//console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('エラーがありました。' + ref.error);
			}
			
			throw new Error('更新しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleFlag1Click = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			//cutoff_flag反転
			data.flag_1 = data.flag_1 ? false : true;
			
			//更新
			const ref = await API.Invoice.Update(data);
			//console.log(ref);
			
			//エラー確認
			if(ref.error){
				throw new Error('エラーがありました。' + ref.error);
			}
			
			throw new Error('更新しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = JSON.parse(JSON.stringify(data));
		
		//対象が明細行かどうか
		if(name.match(/:/)){
			const uuid = name.split(":")[0];
			const target = name.split(":")[1];
			const newDetails = [];
			for(const object of newData.details){
				if(object.uuid === uuid){
					object[target] = value;
				}
				newDetails.push(object);
			}
			newData.details = newDetails;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(name === 'bank_pay_kbn'){
			newData[name] = value;
			newData.bank_pay_text = updateBankPayText(value);
		}
		else{
			newData[name] = value;
		}
		
		const updateData = dataUpdate(newData);
		
		//更新
		setData(updateData);
		setRefresh([true]);
	};

	const dataUpdate = (data) => {
		const newCalc = dataCalc(data);
		const newSort = dataSort(newCalc);
		return(newSort);
	};
	
	const dataCalc = (data) => {
		const isNumber = (value) => {
			try{
				return ((typeof Number(value) === 'number') && (isFinite(Number(value))));
			}
			catch(err){
				return(false);
			}
		};
		//金額の計算
		const newDetails = [];
		for(const object of data.details){
			
			//計算（計算対象が数値でなければ0とする）
			const piece = isNumber(object.piece) ? Number(object.piece) : 0;
			const price_notax = isNumber(object.price_notax) ? Number(object.price_notax) : 0;
			const tax_per = isNumber(object.tax_per) ? Number(object.tax_per) : 0;
			
			//計算（計算対象が0なら結果も0とする）
			//object.sub_price_notax = (piece || price_notax) ? Number(piece * price_notax) : 0;
			object.sub_price_notax = (piece || price_notax) ? new Decimal(piece).mul(price_notax).toNumber() : 0;
			
			//計算（計算対象が0なら結果も0とする）
			//object.tax = object.sub_price_notax ? Number(object.sub_price_notax * tax_per) : 0;
			object.tax = object.sub_price_notax ? new Decimal(object.sub_price_notax).mul(tax_per).toNumber() : 0;
						
			newDetails.push(object);
		}
		data.details = newDetails;

		//消費税率別に金額を計算
		const tax_subs = {};
		for(const item of data.details){
			const tax_per = Number(item.tax_per);
			const tax = Number(item.tax);
			const price = Number(item.sub_price_notax);
			if(tax_per && tax){
				if(!tax_subs[tax_per]){
					tax_subs[tax_per] = {
						tax: 0,
						price: 0,
					};
				}
				//tax_subs[tax_per].tax += tax;
				tax_subs[tax_per].tax = new Decimal(tax_subs[tax_per].tax).plus(tax).toNumber();
				//tax_subs[tax_per].price += price;
				tax_subs[tax_per].price = new Decimal(tax_subs[tax_per].price).plus(price).toNumber();
			}
		}

		//配列に変換する
		const tax_subs_array_temp = Object.keys(tax_subs).map(key => ({
			rate: key,
			tax: tax_subs[key].tax,
			price: tax_subs[key].price,
		})).sort((a, b) => parseFloat(a.rate) - parseFloat(b.rate));

		//消費税の切り捨てと小計を計算
		const tax_subs_array = [];
		let total_tax = 0;
		let total_price_notax = 0;
		for(const item of tax_subs_array_temp){
			//消費税を切り捨て計算
			const tax = new Decimal(item.tax).floor().toNumber();
			//消費税の合計
			total_tax = new Decimal(total_tax).plus(tax).toNumber();
			//税抜き金額の合計
			total_price_notax = new Decimal(total_price_notax).plus(item.price).toNumber();
			//税率別の消費税を記録
			tax_subs_array.push({
				rate: item.rate,
				tax: tax,
				price: item.price,
			});
		}

		//税別の内訳
		data.tax_subs = tax_subs_array.length ? tax_subs_array : [];

		//税抜き小計
		data.total_price_notax = total_price_notax;

		//消費税の合計
		data.total_tax = total_tax;
		
		//請求金額
		data.total_price = new Decimal(data.total_price_notax).plus(data.total_tax).toNumber();

		return(data);
	};
	
	const dataSort = (data) => {
		//ソート
		data.details.sort(function(a,b){
			if(a.sort < b.sort) return -1;
			if(a.sort > b.sort) return 1;
			return 0;
		});
		
		//ソート番号振り直し
		let i = 1;
		for(const object of data.details){
			object.sort=i++;
		}
		return(data);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='登録しました。'){
			window.location.href = '/invoice/' + data.invoice_no;
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }} fluid>
			        <link rel="stylesheet" type="text/css" media="screen" href="/css/invoice/report_screen.css" />
			        <link rel="stylesheet" type="text/css" media="print" href="/css/invoice/report_print.css" />
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={11} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<Row className="justify-content-md-center">
						<Col md="auto">
							<div className="content" style={{width: '300mm'}}>
								<div>
									<Button variant={data.cutoff_flag ? 'secondary' : 'success'} onClick={ handleCutoffFlagClick } disabled={invoice_no ? false : true} >
										{data.cutoff_flag ? '締切済' : '未締切'}
									</Button>
									　
									<Button variant={data.flag_1 ? 'success' : 'secondary'} onClick={ handleFlag1Click } disabled={invoice_no ? (data.cutoff_flag ? false : true) : true} >
										{data.flag_1 ? '公開済' : '未公開'}
									</Button>
									　
									<Button variant="info" onClick={ ()=>{window.location.href = '/print/invoice/' + invoice_no + '/'} } disabled={invoice_no ? false : true} >
										請求書
									</Button>
									　
									<Button variant="secondary" onClick={()=>{ history.goBack(); }} >
										もどる
									</Button>
								</div>
								<div className="page" style={{ marginTop: 20 }}>
							                

							                <table className="invoice_separate">
							                    <tr>
							                        <td className="cell">
											
											<p className="address">
											<input type="button" defaultValue={data.customer_no ? data.customer_no : '選択'} onClick={handleCustomerShow} style={{width: '300px', marginBottom: 10}}/><br/>
											〒<input type="text" name="zip" value={data.zip} style={{width: '100px'}} onChange={handleOnChange} required={true} placeholder="zip" /><br/>
											<input type="text" name="addr_1" value={data.addr_1} style={{width: '300px'}} onChange={handleOnChange} required={true} placeholder="addr_1" /><br/>
											<input type="text" name="addr_2" value={data.addr_2} style={{width: '300px'}} onChange={handleOnChange} required={true} placeholder="addr_2" /><br/>
											<input type="text" name="addr_3" value={data.addr_3} style={{width: '300px'}} onChange={handleOnChange} placeholder="addr_3" /><br/>
											<input type="text" name="customer_name" value={data.customer_name} style={{width: '300px'}} onChange={handleOnChange} required={true} placeholder="customer_name" /><br/>
											<input type="text" name="section" value={data.section} style={{width: '300px'}} onChange={handleOnChange} placeholder="section" /><br/>
											<input type="text" name="name" value={data.name} style={{width: '300px'}} onChange={handleOnChange} placeholder="name" /><br/>
											</p>
							                        </td>
							                        <td className="space"></td>
							                        <td className="cell">
											<p className="text-right">
												様式Ver.{data.invoice_version ?? '2007-01-01'}<br/>
												発行日{dateShapingDate(data.date_add).replace(/-/,'年').replace(/-/,'月')+'日'}<br/>
												（No.{invoice_no ? invoice_no : '[未発行]'}）<br/>
											</p>
											<p className="invoice_head">ご利用代金請求明細書</p>
											<p className={data.seal_flag ? 'publisher_seal' : 'publisher'}>
												<img src="/img/logo.png" alt="株式会社オノコム" /><br/>
												{data.eligible_invoice && <><span>登録番号 : {data.eligible_invoice}</span><br/></>}
												〒530-0005<br/>
												大阪市北区中之島4 丁目3 番25 号<br/>
												フジヒサFJ 中之島ビル11 階<br/>
												TEL : 06-6441-6030 FAX : 06-6441-6031<br/>
												株式会社オノコム<br/>
											</p>
							                        </td>
							                    </tr>
							                </table>
							                
									<h2>ご請求情報</h2>
									
										<table className="invoice_separate">
											<tr>
												<td className="cell">
													<table className="invoice_info_left">
													<tr>
														<th>請求書区分</th>
														<td className="text-left">
															<select name="invoice_flag" value={data.invoice_flag} onChange={handleOnChange} required={invoice_no ? false : true} disabled={invoice_no ? true : false}>
															<option value="L">都度請求</option>
															<option value="F">課金請求</option>
															</select>
														</td>
													</tr>
													<tr>
														<th>支払い区分</th>
														<td className="text-left">
															<select name="pay_kbn" value={data.pay_kbn} onChange={handleOnChange} required={true}>
															<option value="請求書">請求書</option>
															<option value="銀行自動振替">銀行自動振替</option>
															<option value="クレジットカード決済">クレジットカード決済</option>
															</select>
														</td>
													</tr>
													<tr>
														<th>発行日</th>
														<td className="text-left"><input type="date" name="date_add" value={dateShapingDate(data.date_add)} onChange={handleOnChange} required={true} /></td>
													</tr>
													<tr>
														<th>ご請求締日</th>
														<td className="text-left"><input type="date" name="date_invoice" value={dateShapingDate(data.date_invoice)} onChange={handleOnChange} required={true} /></td>
													</tr>
														<tr>
															<th>{data.pay_kbn === 'クレジットカード決済' ?'ご決済予定日' : data.pay_kbn === '銀行自動振替' ? 'お振替予定日' : 'お支払い期限'}</th>
															<td className="text-left"><input type="date" name="date_payment" value={dateShapingDate(data.date_payment)} onChange={handleOnChange} required={true} /></td>
														</tr>
														</table>
												</td>
												<td className="space"></td>
												<td className="cell">
													<table className="invoice_info_right">
													<tr>
														<td>
														＜お支払いについて＞<br/>
														{
															data.pay_kbn === 'クレジットカード決済' ? 'ご利用金額につきまして、ご指定のクレジットカード会社より請求させていただきます。' :
															data.pay_kbn === '銀行自動振替' ? 'ご請求金額につきまして、次回振替予定日に指定口座より口座振替させていただきます。' :
															<div>
																下記の銀行口座までお振込み下さい。<br/>
																<select name="bank_pay_kbn" value={data.bank_pay_kbn} onChange={handleOnChange} required={true}>
																<option value="三井住友銀行">三井住友銀行</option>
																<option value="永和信用金庫">永和信用金庫</option>
																<option value="大阪信用金庫">大阪信用金庫</option>
																<option value="紀陽銀行">紀陽銀行</option>
																</select><br/>
																{data.bank_pay_text}<br/>
																口座名義　株式会社オノコム<br/>※振込手数料はお客様負担でお願い致します。</div>
														}
										</td>
													</tr>
													</table>
												</td>
											</tr>
										</table>
										
										<h2>ご請求明細</h2>
										
										<table className="t1" cellspacing="0">
											<col width="8%" />
											<col width="40%" />
											<col width="12%" />
											<col width="6%" />
											<col width="17%" />
											<col width="17%" />
											<thead>
												<tr>
													<th>順番</th>
													<th>項目</th>
													<th>数量</th>
													<th>消費税</th>
													<th>単価</th>
													<th>金額</th>
												</tr>
											</thead>
											<tbody>
												{data.details.map(item => {
												
													return(
														<tr key={item.uuid}>
															<td className="text-center">
																<input type="button"name={item.uuid} defaultValue={'↑'} style={{width:'25px'}} onClick={handleSortClick} />　
																<input type="button"name={item.uuid} defaultValue={'↓'} style={{width:'25px'}} onClick={handleSortClick} />
															</td>
															<td className="text-left">
																<input type="text" name={item.uuid + ':details'} value={item.details} style={{width:'310px'}} onChange={handleOnChange} required={true} />　
																<input type="button" name={item.uuid} defaultValue={'X'} style={{width:'20px'}} onClick={handleDropDetailsClick} />　
																<input type="button" name={item.uuid} defaultValue={'+'} style={{width:'20px'}} onClick={()=>{handleAddDetailsClick(item.sort)}} />
															</td>
															<td className="text-right">
																<input type="number" name={item.uuid + ':piece'} value={item.piece} style={{width:'60px'}} onChange={handleOnChange} />　
																<input type="text" name={item.uuid + ':piece_type'} value={item.piece_type} style={{width:'30px'}} onChange={handleOnChange} />
															</td>
															<td className="text-right"><input type="number" name={item.uuid + ':tax_per'} value={item.tax_per} style={{width:'60px'}} onChange={handleOnChange} /></td>
															<td className="text-right"><input type="number" name={item.uuid + ':price_notax'} value={item.price_notax} style={{width:'180px'}} onChange={handleOnChange} /></td>
															<td className="text-right"><input type="number" name={item.uuid + ':sub_price_notax'} value={item.sub_price_notax} style={{width:'180px'}} readOnly={true} /></td>
														</tr>
													);
												
												})}
												<tr>
													<td className="text-left"></td>
													<td className="text-center">　<input type="button" defaultValue={'行追加'} style={{width:'310px'}} onClick={handleAddDetailsClick} /></td>
													<td colSpan="6"></td>
												</tr>
											</tbody>
										</table>
										
										{
											data.invoice_version === '2023-10-23'
												?
											<table className="t2" cellSpacing="0" style={{display: data.page === data.max_page ? '' : 'none' }}>
												<col width="34%" />
												<col width="22%" />
												<col width="22%" />
												<col width="22%" />
												<tbody>
												<tr>
													<td className="space">　</td>
													<td className="price">小計　{data.total_price_notax.toLocaleString()} 円 </td>
													<td className="price">消費税　{data.total_tax.toLocaleString()} 円 </td>
													<td className="price">合計　{data.total_price.toLocaleString()} 円 </td>
												</tr>
												{
													data.tax_subs.length !== 0
														&&
													<tr>
														<td className="space">　</td>
														<td>（内訳）</td>
													</tr>
												}
												{
													data.tax_subs.map(item => {
														return(
															<tr>
																<td className="space">　</td>
																<td className="price">{Number(item.rate)*100}%対象　{item.price.toLocaleString()} 円 </td>
																<td className="price">消費税　{item.tax.toLocaleString()} 円 </td>
																<td className="price"></td>
															</tr>
														);
													})
												}
												</tbody>
											</table>
												:
												<table className="t2" cellSpacing="0" style={{display: data.page === data.max_page ? '' : 'none' }}>
												<col width="60%" />
												<col width="9%" />
												<col width="6%" />
												<col width="15%" />
												<col width="10%" />
												<tbody>
													<tr>
														<td rowSpan="3" className="memo">
															＜ご案内＞<br/>
															本請求書は、オノコムポータルサイト（https://portal.onocomm.jp/）からもご覧いただけます。
															利用方法についてご不明な場合は、弊社までお問い合わせください。
														</td>
														<td rowSpan="3" className="space">　</td>
														<td className="sum">小計</td>
														<td className="price">{data.total_price_notax.toLocaleString()} 円 </td>
														<td></td>
													</tr>
													<tr>
														<td className="sum">消費税</td>
														<td className="price">{data.total_tax.toLocaleString()} 円 </td>
														<td></td>
													</tr>
													<tr>
														<td className="sum">合計</td>
														<td className="price">{data.total_price.toLocaleString()} 円 </td>
														<td></td>
													</tr>
												</tbody>
											</table>
										}
							    </div>

							</div>
						</Col>
					</Row>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock || data.cutoff_flag} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>


				<Form onSubmit={handleDelete}>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="danger" type="submit" block disabled={data.cutoff_flag ? true : invoice_no ? (lock ? true : false) : true}>Delete</Button>
						</Col>
					</Row>
				</Form>

			</Container>
		);
	}
};

export default withRouter(Default);
