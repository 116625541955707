import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import { covertTime, dateShapingDate, dateShapingMonth } from '../../Objects/Function';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect, FormButton } from '../../Objects/Form';
import { ModalAlert, ModalCustomer } from '../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const { domain_name } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState({});
	const [tld, setTld] = useState([]);
	const [customer, setCustomer] = useState([]);
	const [showCustomer, setShowCustomer] = useState([false]);
	const [showRegistrant, setShowRegistrant] = useState([false]);
	const [showContact, setShowContact] = useState([false]);
	const [showNameServer, setShowNameServer] = useState([false]);
	const [refresh, setRefresh] = useState([true]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			let newData = {};

			//データ取得
			if(domain_name){
				newData = (await API.Domain.Get(domain_name)).item;
			}
			else{
				newData.customer_no = '';
				newData.customer_name = '';
				newData.status = '新規取得';
				newData.regist_kbn = 'オノコム';
				newData.expiration = Math.floor(((new Date()).getTime() / 1000) + (60 * 60 * 24 *365));
				newData.contract_years = '1';
				newData.bill_start= covertTime('2007-01');
				newData.nameserver = [];
				newData.nameserver.push('');
				newData.nameserver.push('');
				newData.nameserver.push('');
				newData.nameserver.push('');
				newData.nameserver.push('');
				newData.nameserver.push('');
				newData.nameserver.push('');
				newData.nameserver.push('');
				newData.nameserver.push('');
				newData.nameserver.push('');
			}
			
			setData(newData);
			setTld((await API.Domain.GetTLDMaster()).items);
			setCustomer((await API.Customer.GetAll()).items);

			//表示開始
			setLoad(false);
			
			console.log(refresh);
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('入力エラーがありました。');
			}
			
			if(data.customer_no === '' || data.customer_name === ''){
				throw new Error('顧客が選択されていません。');
			}
			if(domain_name){
				//更新
				const ref = await API.Domain.Update(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('更新しました。');
			}
			else{
				//更新
				const ref = await API.Domain.Create(data);
				
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。' + ref.error);
				}
				
				throw new Error('登録しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			const formObj = event.currentTarget;
			
			if(formObj.checkValidity() === false){
				throw new Error('エラーがありました。');
			}
			
			if(domain_name){
				//削除
				const ref = await API.Domain.Delete(domain_name);
				//console.log(ref);
				
				//エラー確認
				if(ref.error){
					throw new Error('エラーがありました。' + ref.error);
				}
				
				throw new Error('削除しました。');
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};

	const handleCustomerShow = () => {
		setShowCustomer([true]);
	};
	
	const handleRegistrantShow = () => {
		setShowRegistrant([true]);
	};
	
	const handleContactShow = () => {
		setShowContact([true]);
	};
	
	const handleNameServerShow = () => {
		setShowNameServer([true]);
	};
	
	const handleCustomerClick = (customer_no, customer_name) => {
		const newData = { ...data };
		newData.customer_no = customer_no;
		newData.customer_name = customer_name;
		setData(newData);
		setShowCustomer([false]);
		setRefresh([true]);
	};
	
	const handlePriceUpdate = (datetime) => {
		const newData = { ...data };
		if(data.tld_kbn){
			const tld_kbn = data.tld_kbn;
			const item = (tld.filter(x => x.tld_name === tld_kbn))[0];
			newData.init_price_notax = item.init_price_notax;
			newData.price_notax = item.price_notax;
		}
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleDateUpdate = () => {
		const newData = { ...data };
		const dateObj = new Date(data.expiration * 1000);
		newData.billing_date = Math.floor(new Date( dateObj.getFullYear(), dateObj.getMonth() - (dateObj.getDate < 25 ? 3 : 2), 1) / 1000);
		setData(newData);
		setRefresh([true]);
	};
	
	const handleOnChange = (event) => {
		
		const name = event.target.name;
		const value = event.target.value;
		const type = event.target.type;
		
		const newData = { ...data };
		
		//NameServer
		if(name.match(/:/)){
			const object = name.split(":")[0];
			const index = name.split(":")[1];
			newData[object][index] = value;
		}
		//対象が日付かどうか
		else if(type === 'date' || type === 'month'){
			const newValue = covertTime(value);
			newData[name] = newValue;
		}
		else if(type === 'checkbox'){
			newData[name] = event.target.checked ? true : false;
		}
		else{
			newData[name] = value;
		}
		
		//更新
		setData(newData);
		setRefresh([true]);
	};
	
	const handleClose = () => {
		setAlert([]);
		setShowRegistrant([false]);
		setShowContact([false]);
		setShowNameServer([false]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/domain/';
		}
		else if(alert[0] ==='登録しました。'){
			window.location.href = '/domain/' + data.domain_name;
		}
	};
	
	const handleMoveBill = () => {
		sessionStorage.setItem('domain_details', JSON.stringify(data));
		window.location.href='/bill-once/new/';
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />
				<ModalCustomer data={customer} show={showCustomer} lock={lock} onSelect={handleCustomerClick} />
				<ModalRegistrant data={data} show={showRegistrant} onClick={handleClose} onChange={handleOnChange} />
				<ModalContact data={data} show={showContact}  onClick={handleClose} onChange={handleOnChange} />
				<ModalNameServer data={data} show={showNameServer}  onClick={handleClose} onChange={handleOnChange} />

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'ドメイン名'} name={'domain_name'} value={data.domain_name} onChange={handleOnChange} readOnly={domain_name ? true : false} required={domain_name ? false : true} />
					<hr/>
					<FormButton label={'対象顧客'} name={'customer_no'} value={data.customer_no ? (data.customer_no + '　' + data.customer_name) : '未選択'} onClick={handleCustomerShow} required={true} />
					<FormSelect label={'登録区分'} name={'status'} value={data.status} data={ [ '移管手続き中', '対象外', '更新対象' ] } onChange={handleOnChange} required={true} />
					<FormSelect label={'区分'} name={'domain_kbn'} value={data.domain_kbn} data={ [ '属性JP', '汎用JP', 'gTLD' ] } onChange={handleOnChange} required={true} />
					<FormSelect label={'種別'} name={'tld_kbn'} value={data.tld_kbn} data={ tld.map(item => item.tld_name).sort() } onChange={handleOnChange} required={true} />
					<FormSelect label={'指定事業者'} name={'regist_kbn'} value={data.regist_kbn} data={ [ 'オノコム', 'ドメイン２１', 'お名前ドットコム' ] } onChange={handleOnChange} required={true} />
					<hr/>
					<FormText label={'初期価格'} name={'init_price_notax'} value={data.init_price_notax} type={'number'} onChange={handleOnChange} required={true} />
					<FormText label={'更新価格'} name={'price_notax'} value={data.price_notax} type={'number'} onChange={handleOnChange} required={true} />
					<FormButton label={''} name={''} value={'価格取得'} onClick={handlePriceUpdate} />
					<hr/>
					<FormText label={'有効期限'} name={'expiration'} value={dateShapingDate(data.expiration)} type={'date'} onChange={handleOnChange} required={true} />
					<FormText label={'契約年数'} name={'contract_years'} value={data.contract_years} type={'number'} onChange={handleOnChange} required={true} />
					<FormText label={'請求月'} name={'billing_date'} value={dateShapingMonth(data.billing_date)} type={'month'} onChange={handleOnChange} required={true} />
					<FormButton label={''} name={''} value={'日付設定'} onClick={handleDateUpdate} />
					<FormButton label={'公開連絡窓口'} name={'contact_id'} value={data.contact_id ? data.contact_id : '（Empty）'} onClick={handleContactShow} />
					<FormButton label={'登録者情報'} name={'registrant_id'} value={data.registrant_id ? data.registrant_id : '（Empty）'} onClick={handleRegistrantShow} />
					<FormButton label={'ネームサーバー'} value={'ネームサーバー'} onClick={handleNameServerShow} />
					<FormText label={'AUTH CODE'} name={'auth_code'} value={data.auth_code} onChange={handleOnChange} />
					<hr/>
					<FormText label={'課金開始月'} name={'bill_start'} value={dateShapingMonth(data.bill_start)} type={'month'} onChange={handleOnChange} required={true} />
					<FormText label={'課金停止月'} name={'bill_end'} value={dateShapingMonth(data.bill_end)} type={'month'} onChange={handleOnChange} />
					<hr/>
					<FormButton label={'課金登録'} name={'details'} value={'初期費用登録へ移動'} onClick={handleMoveBill} disabled={domain_name ? false : true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block disabled={lock} >OK</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="secondary" type="button" block onClick={()=>{ history.goBack(); }} disabled={lock} >Back</Button>
						</Col>
					</Row>
				</Form>

				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block disabled={lock || domain_name ? false : true} >Delete</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};

const ModalRegistrant = (props) => {

	const [show, setShow] = useState(false);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);

	return (
		<Modal show={show} onHide={props.onClick} size="lg">
			<Modal.Body>
				<Form>

					<FormText label={'RegistrantID'} name={'registrant_id'} value={props.data.registrant_id} onChange={props.onChange} />
					<FormText label={'登録者名(日本語)'} name={'registrant_name'} value={props.data.registrant_name} onChange={props.onChange} />
					<FormText label={'登録者名(英語)'} name={'registrant_name_en'} value={props.data.registrant_name_en} onChange={props.onChange} />
					<FormText label={'登録担当者'} name={'registrant_representative_name'} value={props.data.registrant_representative_name} onChange={props.onChange} />
					<FormText label={'部署名'} name={'registrant_representative_division'} value={props.data.registrant_representative_division} onChange={props.onChange} />
					<FormText label={'E-mail'} name={'registrant_email_address'} value={props.data.registrant_email_address} type={'email'} onChange={props.onChange} />
					<FormText label={'電話番号'} name={'registrant_phone_number'} value={props.data.registrant_phone_number} type={'tel'} onChange={props.onChange} />
					<FormText label={'FAX番号'} name={'registrant_fax_number'} value={props.data.registrant_fax_number} type={'tel'} onChange={props.onChange} />
					<FormText label={'郵便番号'} name={'registrant_postal_code'} value={props.data.registrant_postal_code} type={'zip'} onChange={props.onChange} />
					<FormText label={'都道府県'} name={'registrant_prefecture'} value={props.data.registrant_prefecture} onChange={props.onChange} />
					<FormText label={'住所(日本語)１'} name={'registrant_postal_address1'} value={props.data.registrant_postal_address1} onChange={props.onChange} />
					<FormText label={'住所(日本語)２'} name={'registrant_postal_address2'} value={props.data.registrant_postal_address2} onChange={props.onChange} />
					<FormText label={'住所(日本語)３'} name={'registrant_postal_address3'} value={props.data.registrant_postal_address3} onChange={props.onChange} />
					<FormText label={'住所(英語)１'} name={'registrant_postal_address_en1'} value={props.data.registrant_postal_address_en1} onChange={props.onChange} />
					<FormText label={'住所(英語)２'} name={'registrant_postal_address_en2'} value={props.data.registrant_postal_address_en2} onChange={props.onChange} />
					<FormText label={'住所(英語)３'} name={'registrant_postal_address_en3'} value={props.data.registrant_postal_address_en3} onChange={props.onChange} />
					<FormText label={'CountryCode'} name={'registrant_country_code'} value={props.data.registrant_country_code} onChange={props.onChange} />
					<FormSelect label={'CEDコンタクト種別'} name={'registrant_ced_entity_type'} defaultValue={props.data.registrant_ced_entity_type} data={ [ '法人/企業', '協同組合', '合資会社/合名会社', '政府機関/地方自治体', '政党/労働組合', '企業合同/協会/結社', '公共機関/公共団体', '個人', 'その他' ] } readOnly={true} />
					<FormText label={'その他の場合'} name={'registrant_ced_ident_form'} defaultValue={props.data.registrant_ced_ident_form} readOnly={true} />
					<FormSelect label={'CEDの証明方法'} name={'registrant_ced_otherentity_type'} defaultValue={props.data.registrant_ced_otherentity_type} data={ [ '法人登記、会社設立許可書等', '法令・判決・法律等による設立許可書', '団体の登記簿等', '政治団体の証明書', 'パスポートまたは健康保険書番号', 'その他' ] } readOnly={true} />
					<FormText label={'その他の場合'} name={'registrant_ced_other_ident_form'} defaultValue={props.data.registrant_ced_other_ident_form} readOnly={true} />
					<FormText label={'証明番号'} name={'registrant_ced_ident_number'} defaultValue={props.data.registrant_ced_ident_number} readOnly={true} />
					<FormText label={'証明方法が発行された国'} name={'registrant_ced_country_code'} defaultValue={props.data.registrant_ced_country_code} readOnly={true} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} >OK</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const ModalContact = (props) => {

	const [show, setShow] = useState(false);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	return (
		<Modal show={show} onHide={props.onClick} size="lg">
			<Modal.Body>
				<Form>
					<FormText label={'ContactID'} name={'contact_id'} value={props.data.contact_id} onChange={props.onChange} />
					<FormText label={'登録者名(日本語)'} name={'contact_name'} value={props.data.contact_name} onChange={props.onChange} />
					<FormText label={'登録者名(英語)'} name={'contact_name_en'} value={props.data.contact_name_en} onChange={props.onChange} />
					<FormText label={'E-mail'} name={'contact_email_address'} value={props.data.contact_email_address} type={'email'} onChange={props.onChange} />
					<FormText label={'電話番号'} name={'contact_phone_number'} value={props.data.contact_phone_number} type={'tel'} onChange={props.onChange} />
					<FormText label={'FAX番号'} name={'contact_fax_number'} value={props.data.contact_fax_number} type={'tel'} onChange={props.onChange} />
					<FormText label={'郵便番号'} name={'contact_postal_code'} value={props.data.contact_postal_code} type={'zip'} onChange={props.onChange} />
					<FormText label={'都道府県'} name={'contact_prefecture'} value={props.data.contact_prefecture} onChange={props.onChange} />
					<FormText label={'住所(日本語)１'} name={'contact_postal_address1'} value={props.data.contact_postal_address1} onChange={props.onChange} />
					<FormText label={'住所(日本語)２'} name={'contact_postal_address2'} value={props.data.contact_postal_address2} onChange={props.onChange} />
					<FormText label={'住所(日本語)３'} name={'contact_postal_address3'} value={props.data.contact_postal_address3} onChange={props.onChange} />
					<FormText label={'住所(英語)１'} name={'contact_postal_address_en1'} value={props.data.contact_postal_address_en1} onChange={props.onChange} />
					<FormText label={'住所(英語)２'} name={'contact_postal_address_en2'} value={props.data.contact_postal_address_en2} onChange={props.onChange} />
					<FormText label={'住所(英語)３'} name={'contact_postal_address_en3'} value={props.data.contact_postal_address_en3} onChange={props.onChange} />
					<FormText label={'WebPage'} name={'contact_webpage'} value={props.data.contact_webpage} type={'url'} onChange={props.onChange} />
					<FormText label={'CountryCode'} name={'contact_country_code'} value={props.data.contact_country_code} onChange={props.onChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} >OK</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const ModalNameServer = (props) => {

	const [show, setShow] = useState(false);

	useEffect(() => {

		//表示開始
		if(props.show[0]){
			setShow(true);
		}
		else{
			setShow(false);
		}
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.show]);
	
	return (
		<Modal show={show} onHide={props.onClick} size="lg">
			<Modal.Body>
				<Form>
					<FormText label={'ネームサーバー'} name={'nameserver:0'} value={props.data.nameserver[0]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:1'} value={props.data.nameserver[1]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:2'} value={props.data.nameserver[2]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:3'} value={props.data.nameserver[3]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:4'} value={props.data.nameserver[4]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:5'} value={props.data.nameserver[5]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:6'} value={props.data.nameserver[6]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:7'} value={props.data.nameserver[7]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:8'} value={props.data.nameserver[8]} onChange={props.onChange} />
					<FormText label={'ネームサーバー'} name={'nameserver:9'} value={props.data.nameserver[9]} onChange={props.onChange} />
					<hr/>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="button" block onClick={props.onClick} >OK</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default withRouter(Default);
