import { useState  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
	Modal,
} from 'react-bootstrap';
import Table from '../../Objects/Table';
import { FormText } from '../../Objects/Form';
import { withRouter } from 'react-router-dom';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';

const Default = () => {

	const [load, setLoad] = useState(false);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [detail, setDetail] = useState({});
	const [showModal, setShowModal] = useState(false);
	
	const handleGetData = async (event) => {
	
		const dateTimeToUnix = (dateTime) => {
			const date = new Date(dateTime);
			return date.getTime() / 1000;
		};

		//Submitをキャンセル
		event?.preventDefault();
		event?.stopPropagation();
		
		//表示開始
		setLoad(true);

		//Formオブジェクトの確認
		const formObj = event?.currentTarget;
		
		//Formから直接取得する更新項目
		const start_time = dateTimeToUnix(formObj?.start_time?.value);
		const end_time = dateTimeToUnix(formObj?.end_time?.value);
		const from_address = formObj?.from_address?.value;

		let newData = null;

		if(start_time && end_time && from_address){
			const ref = await API.MailLog.Query({from_address, start_time, end_time});
			newData = JSON.parse(JSON.stringify(ref.items));
		}
		else{
			const ref = await API.MailLog.GetAll();
			newData = JSON.parse(JSON.stringify(ref.items));
		}
				
		//データセット
		setData(newData);
		setView(newData);

		//表示開始
		setLoad(false);
		
	};

	const setToday = () => {
		const today = new Date();
		const today_year = today.getFullYear();
		const today_month = ('00' + (today.getMonth()+1).toString()).slice(-2);
		const today_day = ('00' + today.getDate().toString()).slice(-2);
		const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate()+1);
		const tomorrow_year = tomorrow.getFullYear();
		const tomorrow_month = ('00' + (tomorrow.getMonth()+1).toString()).slice(-2);
		const tomorrow_day = ('00' + tomorrow.getDate().toString()).slice(-2);
		window.getdata.start_time.value=`${today_year}-${today_month}-${today_day}T00:00`;
		window.getdata.end_time.value=`${tomorrow_year}-${tomorrow_month}-${tomorrow_day}T00:00`;
	};

	const setClear = () => {
		window.getdata.start_time.value='';
		window.getdata.end_time.value='';
		window.getdata.from_address.value='';
	};

	const setModal = (log_id) => {
		const newDetail = data.find(item => item.log_id === log_id);
		setDetail(newDetail);
		setShowModal(true);
	};

	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => setModal(row.log_id)}>
					詳細
				</Button>
			</div>
		);
	};

	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const second       = ('00' + dateObj.getSeconds().toString()).slice(-2);
			const dateText     = (year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second);
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	
	const columns = [
		{
			dataField: 'log_id',
			isDummyField: true,
			text: 'Button',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'start_time',
			text: '日時',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'from_address',
			text: '送信元',
			sort: true,
			formatter: (cell) => { return(cell.substring(0, 30)); },
			filter: textFilter(),
		},
		{
			dataField: 'to_address',
			text: '送信先',
			sort: true,
			formatter: (cell) => { return(cell.substring(0, 30)); },
			filter: textFilter(),
		},
		{
			dataField: 'result',
			text: '成功/失敗',
			sort: true,
			formatter: (cell) => { return(cell ? '成功' : '失敗'); },
			filter: selectFilter({
			  options: {true:'成功', false:'失敗'},
			  placeholder : '全て',
			}),
		},
		{
			dataField: 'local',
			text: 'ローカル配信',
			sort: true,
			formatter: (cell) => { return(cell ? '○' : '×'); },
			filter: selectFilter({
				options: {true:'○', false:'×'},
				placeholder : '全て',
			}),
		  },
	];
	
	return (
		<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
			<Row>
				<Col sm={12}>
					<h1>メールログ</h1>
				</Col>
				<Col sm={12}>
					<Form onSubmit={handleGetData} name="getdata">
						<Row style={{ marginTop: 20 }}>
							<Col sm={1} className="text-center">
								<Badge pill variant="secondary">
									<h6>　{ view.length ? view.length.toLocaleString() : '0' }　</h6>
								</Badge>
							</Col>
						</Row>

						<Form.Group as={Row} style={{ marginTop: 20 }} controlId="formHorizontalEmail">
							<Form.Label column sm={1}>
								送信日時
							</Form.Label>
							<Col sm={2}>
								<Form.Control type="datetime-local" name="start_time" required/>
							</Col>
							<Col sm={2}>
								<Form.Control type="datetime-local" name="end_time"  required/>
							</Col>
							<Col sm={1}>
								<Button variant="secondary" onClick={setToday}>本日</Button>
							</Col>
							<Col sm={1}>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formHorizontalEmail">
							<Form.Label column sm={1}>
								FROM
							</Form.Label>
							<Col sm={2}>
								<Form.Control type="text" name="from_address"  required/>
							</Col>
							<Button variant="dark" type="submit">データ取得</Button>　
							<Button variant="dark" onClick={handleGetData}>全件取得</Button>　
							<Button variant="secondary" onClick={setClear}>リセット</Button>
						</Form.Group>
					</Form>
				</Col>
			</Row>
			<Row style={{ marginTop: 20 }}>
				<Col sm={12}>
				{(()=>{
					if(load){
						return (
							<Row style={{ marginTop: 100, marginBottom: 100 }}>
								<Col sm={12} className="text-center">
									<Image src="/img/loading.gif" />
								</Col>
							</Row>
						);
					}
					else{
						return(
							<Table keyField={'log_id'} data={view} columns={columns} checkbox={false} sort={ {field: 'start_time', order: 'desc'} } filter={ filterFactory() } filterPosition="top" />
						);
					}
				})()}
				</Col>
			</Row>

			<Modal
				show={showModal}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={()=>setShowModal(false)}
			>
				<Modal.Body>
					<FormText label={'ログID'} name={'log_id'} defaultValue={detail.log_id} readOnly={true}/>
					<FormText label={'メッセージID'} name={'message_id'} defaultValue={detail.message_id} readOnly={true}/>
					<FormText label={'デリバリID'} name={'delivery_id'} defaultValue={detail.delivery_id} readOnly={true}/>
					<FormText label={'開始日時'} name={'start_time'} defaultValue={detail.start_time_jst} readOnly={true}/>
					<FormText label={'終了日時'} name={'end_time'} defaultValue={detail.end_time_jst} readOnly={true}/>
					<FormText label={'送信元'} name={'from_address'} defaultValue={detail.from_address} readOnly={true}/>
					<FormText label={'送信先'} name={'to_address'} defaultValue={detail.to_address} readOnly={true}/>
					<FormText label={'ステータス'} name={'status'} defaultValue={detail.status} readOnly={true}/>
					<FormText label={'ローカル配信'} name={'local'} defaultValue={detail.local} readOnly={true}/>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>メッセージ</Form.Label>
						<Form.Control as="textarea" name={'message_text'} rows={5} defaultValue={detail.message_text} readOnly={true} />
					</Form.Group>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
								<Button variant="secondary" type="button" block onClick={()=>setShowModal(false)}>閉じる</Button>
						</Col>
					</Row>
					</Modal.Body>
			</Modal>

		</Container>
	);
};

export default withRouter(Default);
